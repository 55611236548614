import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  BoxProps,
  Button,
  Td,
  Text,
  Tr,
  useDisclosure,
  useMediaQuery,
  useToast,
} from "@chakra-ui/react";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { deleteBsr, getBsrHistory } from "../../DataAccess/bsr";
import { bsrEntry } from "../../types/bsr";
import { ucFirst } from "../../utils/coreHelper";
import { formatDateLong } from "../../utils/dateHelper";
import { createToast } from "../../utils/toastHelper";
import { CreateSupportTicket } from "../ui/CreateSupportTicket";
import { CustomToast } from "../ui/CustomToast";

interface BsrListItemProps extends BoxProps {
  bsrEntry: bsrEntry;
  setBsrHistory: any;
  blurred?: boolean;
}

const BsrListItem: React.FC<BsrListItemProps> = ({
  bsrEntry,
  setBsrHistory,
  blurred,
}) => {
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const deleteRef = useRef(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [hamburgerMode] = useMediaQuery("(max-width: 768px)");
  const toast = useToast();
  const navigate = useNavigate();

  const deleteBsrHandler = async () => {
    try {
      setIsDeleting(true);
      await deleteBsr(bsrEntry._id);
      const bsrHistory = await getBsrHistory();
      setBsrHistory(
        bsrHistory.map((e: any) => {
          const date = new Date(e.date);
          e.dateN = date.getTime();
          return e;
        })
      );
      createToast(toast, (props: any) => {
        return (
          <CustomToast
            title={"Delete BSR Entry"}
            status={"Success"}
            toast={toast}
            toastId={props.id}
          >
            <Text>BSR Entry deleted.</Text>
          </CustomToast>
        );
      });
      setIsDeleting(false);
      onClose();
    } catch (error) {
      createToast(toast, (props: any) => {
        return (
          <CustomToast
            title={"Delete BSR Entry"}
            status={"Error"}
            toast={toast}
            toastId={props.id}
          >
            <Text>Unable to delete BSR entry, please contact support.</Text>
            <CreateSupportTicket />
          </CustomToast>
        );
      });
      setIsDeleting(false);
      onClose();
    }
  };

  if (hamburgerMode) {
    return blurred ? (
      <Tr className={"blurred"} key={bsrEntry._id}>
        <Td>{formatDateLong(new Date(bsrEntry.date))}</Td>
        <Td isNumeric>We</Td>
        <Td isNumeric>
          <Button
            size={"sm"}
            variant={"success"}
            onClick={() => {
              navigate("/account/subscription");
            }}
          >
            Upgrade for Full BSR Tools & History
          </Button>
        </Td>
      </Tr>
    ) : (
      <Tr key={bsrEntry._id}>
        <Td>{formatDateLong(new Date(bsrEntry.date))}</Td>
        <Td isNumeric>{bsrEntry.spm}</Td>
        <Td>{bsrEntry.type ? ucFirst(bsrEntry.type) : "Manual"}</Td>
        <Td isNumeric>
          <>
            <Button
              variant="warning"
              isLoading={isDeleting}
              onClick={onOpen}
              size="sm"
            >
              Delete
            </Button>
            <AlertDialog
              isOpen={isOpen}
              leastDestructiveRef={deleteRef}
              onClose={onClose}
            >
              <AlertDialogOverlay>
                <AlertDialogContent>
                  <AlertDialogHeader fontSize="lg" fontWeight="bold">
                    Delete BSR Entry
                  </AlertDialogHeader>
                  <AlertDialogBody>
                    Are you sure? You can't undo this action afterwards.
                  </AlertDialogBody>
                  <AlertDialogFooter>
                    <Button ref={deleteRef} onClick={onClose}>
                      Back
                    </Button>
                    <Button
                      variant="warning"
                      px={20}
                      isLoading={isDeleting}
                      onClick={deleteBsrHandler}
                      ml={3}
                    >
                      Delete BSR Entry
                    </Button>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialogOverlay>
            </AlertDialog>
          </>
        </Td>
      </Tr>
    );
  }

  return blurred ? (
    <Tr className={"blurred"} key={bsrEntry._id}>
      <Td>{formatDateLong(new Date(bsrEntry.date))}</Td>
      <Td isNumeric>We really</Td>
      <Td>did</Td>
      <Td isNumeric>
        <Button
          size={"sm"}
          variant={"success"}
          onClick={() => {
            navigate("/account/subscription");
          }}
        >
          Upgrade for Full BSR Tools & History
        </Button>
      </Td>
    </Tr>
  ) : (
    <Tr key={bsrEntry._id}>
      <Td>{formatDateLong(new Date(bsrEntry.date))}</Td>
      <Td isNumeric>{bsrEntry.spm}</Td>
      <Td>{bsrEntry.type ? ucFirst(bsrEntry.type) : "Manual"}</Td>
      <Td isNumeric>
        <>
          <Button
            variant="warning"
            isLoading={isDeleting}
            onClick={onOpen}
            size="sm"
          >
            Delete
          </Button>
          <AlertDialog
            isOpen={isOpen}
            leastDestructiveRef={deleteRef}
            onClose={onClose}
          >
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader fontSize="lg" fontWeight="bold">
                  Delete BSR Entry
                </AlertDialogHeader>
                <AlertDialogBody>
                  Are you sure? You can't undo this action afterwards.
                </AlertDialogBody>
                <AlertDialogFooter>
                  <Button ref={deleteRef} onClick={onClose}>
                    Back
                  </Button>
                  <Button
                    variant="warning"
                    px={20}
                    isLoading={isDeleting}
                    onClick={deleteBsrHandler}
                    ml={3}
                  >
                    Delete BSR Entry
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>
        </>
      </Td>
    </Tr>
  );
};

export { BsrListItem };
