import { Button, ButtonGroup, Input, InputGroup, InputRightElement } from "@chakra-ui/react";
import * as React from "react";
import { useNavigate } from "react-router-dom";

const PasswordInput = (props: any) => {
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);

  return (
    <InputGroup size="md">
      <Input {...props} pr="4.5rem" type={show ? "text" : "password"} placeholder="" autoComplete={props.autoComplete} />
      <InputRightElement width="4.5rem">
        <Button colorScheme={props.colorScheme} h="1.75rem" size="sm" onClick={handleClick}>
          {show ? "Hide" : "Show"}
        </Button>
      </InputRightElement>
    </InputGroup>
  );
};

interface ApplyInputProps {
  clickHandler: React.MouseEventHandler<HTMLButtonElement>;
  isLoading: boolean;
  placeholder: string;
}

const ApplyInput = ({ clickHandler, isLoading, placeholder, ...restProps }: ApplyInputProps & React.HTMLAttributes<HTMLInputElement>) => {
  return (
    <InputGroup size="md">
      <Input {...restProps} pr="4.5rem" placeholder={placeholder} />
      <InputRightElement width="4.5rem">
        <Button h="1.75rem" isLoading={isLoading} size="sm" onClick={clickHandler}>
          Apply
        </Button>
      </InputRightElement>
    </InputGroup>
  );
};

const ButtonNavigate = (props: any, ...rest: any) => {
  const navigate = useNavigate();
  const clickHandler = () => {
    navigate(props.route);
  };
  return (
    <Button onClick={clickHandler} colorScheme={props.colorScheme} w={props.w} variant={props.variant}>
      {props.children}
    </Button>
  );
};

const ActivityLocationGroup = (props: any) => {
  const onChange = props.onChange;
  const name = props.name;
  const [value, setValue] = React.useState<string>(props.value);

  const dummyChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    return;
  };

  const handleClick = (clicked: string) => {
    setValue(clicked);
    onChange(clicked);
  };

  return (
    <ButtonGroup spacing="0" isAttached variant="outline" w="full">
      <Input name={name} value={value} onChange={dummyChangeHandler} type="hidden" />
      <Button
        isActive={value === "Pool"}
        onClick={() => {
          handleClick("Pool");
        }}
        borderRightRadius={0}
        width="33%"
      >
        Pool
      </Button>
      <Button
        isActive={value === "Open"}
        onClick={() => {
          handleClick("Open");
        }}
        borderRadius={0}
        width="34%"
      >
        Open Water
      </Button>
      <Button
        isActive={value === "Endless"}
        onClick={() => {
          handleClick("Endless");
        }}
        borderLeftRadius={0}
        width="33%"
      >
        Endless Pool
      </Button>
    </ButtonGroup>
  );
};

const YesNoGroup = (props: any, ...rest: any) => {
  return (
    <ButtonGroup spacing="0">
      <Button borderRightRadius={0} width={115} bg="green.500">
        Yes
      </Button>
      <Button borderLeftRadius={0} width={115} bg="red.500">
        No
      </Button>
    </ButtonGroup>
  );
};

const YesNoMaybeGroup = (props: any, ...rest: any) => {
  return (
    <ButtonGroup spacing="0">
      <Button borderRightRadius={0} width={65} bg="green.500">
        Yes
      </Button>
      <Button borderRadius={0} width={90} bg="grey">
        Maybe
      </Button>
      <Button borderLeftRadius={0} width={65} bg="red.500">
        No
      </Button>
    </ButtonGroup>
  );
};

const YesNoUnknownGroup = (props: any, ...rest: any) => {
  return (
    <ButtonGroup spacing="0">
      <Button borderRightRadius={0} width={65} bg="green.500">
        Yes
      </Button>
      <Button borderRadius={0} width={100} bg="grey">
        Unknown
      </Button>
      <Button borderLeftRadius={0} width={65} bg="red.500">
        No
      </Button>
    </ButtonGroup>
  );
};

export { PasswordInput, ApplyInput, ButtonNavigate, YesNoGroup, YesNoMaybeGroup, YesNoUnknownGroup, ActivityLocationGroup };
