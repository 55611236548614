import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { fitnessSummary } from "../../types/fitness";

// Define the state of the slice as an object
export interface FitnessState {
  lastUpdated: Date;
  fitness: fitnessSummary | null;
  view: string;
}

export interface UpdateFitnessState {
  fitness: fitnessSummary | null;
  view: string;
}

// Define an initial state
const initialState: FitnessState = {
  lastUpdated: new Date("1970-01-01T00:00:00.000"),
  fitness: null,
  view: "lastYear",
};

// Create a slice containing the configuration of the state
// and the reducers functions
const fitnessSlice = createSlice({
  name: "fitnessState",
  initialState,
  reducers: {
    updateFitnessState(
      state,
      action: PayloadAction<UpdateFitnessState | null>
    ) {
      if (action.payload) {
        state.lastUpdated = new Date();
        state.fitness = action.payload.fitness;
        state.view = action.payload.view;
      } else {
        state.lastUpdated = initialState.lastUpdated;
        state.fitness = initialState.fitness;
        state.view = initialState.view;
      }
    },
    updateViewFitnessState(state, action: PayloadAction<string>) {
      if (action.payload) {
        state.view = action.payload;
      } else {
        state.view = initialState.view;
      }
    },
  },
});

// Export each reducers function defined in createSlice
export const { updateFitnessState } = fitnessSlice.actions;
export const { updateViewFitnessState } = fitnessSlice.actions;

export const selectFitnessState = (state: RootState): FitnessState => {
  return state.fitnessState;
};

// Export default the slice reducer
export default fitnessSlice.reducer;
