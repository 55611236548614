import { Heading, HStack, useMediaQuery, VStack } from "@chakra-ui/react";
import * as contentful from "contentful";
import { useContext, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { YourCoach } from "../../components/coaches/YourCoach";
import {
  BreadcrumbContext,
  breadcrumbContextType,
} from "../../components/context/BreadcrumbContext";
import { SessionRoulette } from "../../components/sessions/SessionRoulette";
import { SessionTypes } from "../../components/sessions/SessionTypes";
import Loading from "../../components/ui/Loading";
import { WorldsBiggestSwimSquadWidget } from "../../components/wbss/WorldsBiggestSwimSquadWidget";
import {
  selectSessionTypesState,
  updateSessionTypesState,
} from "../../features/session/sessionTypesSlice";
import { selectUserState } from "../../features/user/userSlice";

const client = contentful.createClient({
  space: "50b15ahactsg",
  accessToken: "rYtEmKRrWoeFQM4pR8Ot8SZV7uC8OERTel461AQ3kvk",
});

const SessionTypesPage = () => {
  const { setBreadcrumbLinks } =
    useContext<breadcrumbContextType>(BreadcrumbContext);

  const { user } = useAppSelector(selectUserState);
  const { sessionTypes, lastUpdated: lastUpdatedSessionTypes } = useAppSelector(
    selectSessionTypesState
  );
  const [loading, setLoadingSessionTypes] = useState<boolean>(true);
  const [hamburgerMode] = useMediaQuery("(max-width: 768px)");
  const dispatch = useAppDispatch();

  useEffect(() => {
    const breadcrumbLinks = [{ href: "/sessions", title: "Sessions" }];
    setBreadcrumbLinks(breadcrumbLinks);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getEntriesLocal = async () => {
      const entries = await client.getEntries({
        content_type: "sessionType",
      });
      dispatch(
        updateSessionTypesState({
          sessionTypes: entries.items
            .map((a: any) => {
              return {
                code: a.fields.code,
                title: a.fields.title,
                description: a.fields.description,
                image: a.fields.image.fields.file.url,
                vimeoId: a.fields.vimeoId,
                levels: a.fields.levels,
                order: a.fields.order,
              };
            })
            .sort((a: any, b: any) => {
              return a.order - b.order;
            }),
        })
      );
      setLoadingSessionTypes(false);
    };
    if (lastUpdatedSessionTypes) {
      const test = new Date(lastUpdatedSessionTypes).getTime();
      const anHourAgo = new Date().getTime() - 1000 * 60 * 60;
      if (!sessionTypes || sessionTypes.length === 0 || anHourAgo > test) {
        getEntriesLocal();
      } else {
        setLoadingSessionTypes(false);
      }
    } else {
      getEntriesLocal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!user) {
    return <Loading message="Error Loading User" />;
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <VStack w="full">
      <Heading as="h2" size="xl" mb={4}>
        Sessions
      </Heading>
      {!hamburgerMode && (
        <HStack w="full" alignItems="flex-start">
          <SessionTypes />
          <VStack w="50%">
            <YourCoach />
            <SessionRoulette />
            <WorldsBiggestSwimSquadWidget />
          </VStack>
        </HStack>
      )}
      {hamburgerMode && <SessionTypes />}
    </VStack>
  );
};

export { SessionTypesPage };
