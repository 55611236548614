import {
  Box,
  Button,
  Heading,
  HStack,
  Icon,
  Image,
  ListItem,
  OrderedList,
  SimpleGrid,
  Text,
  Tooltip,
  UnorderedList,
  useColorModeValue,
  useToast,
  VStack,
} from "@chakra-ui/react";
import * as contentful from "contentful";
import { useEffect, useState } from "react";
import { FaLock } from "react-icons/fa";
import { GiPathDistance } from "react-icons/gi";
import { IoFlame, IoTimerOutline } from "react-icons/io5";
import { MdModelTraining } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { getSessionById } from "../../DataAccess/sessions";
import { selectAuthState } from "../../features/auth/authSlice";
import { session } from "../../types/session";
import { checkLevelInArray, getLevelInSentence } from "../../utils/authHelper";
import { createToast } from "../../utils/toastHelper";
import { CustomToast } from "./CustomToast";
import Loading from "./Loading";
import Vimeo from "./Vimeo";
import VimeoContainer from "./VimeoContainer";

const client = contentful.createClient({
  space: "50b15ahactsg",
  accessToken: "rYtEmKRrWoeFQM4pR8Ot8SZV7uC8OERTel461AQ3kvk",
});

interface ContentfulProps {
  entity: any;
}

const Contentful: React.FC<ContentfulProps> = ({ entity }) => {
  if (entity.sys.contentType.sys.id === "product") {
    return (
      <VStack w="full" alignItems="flex-start">
        <Text pb={4}>{entity.fields.shortDescription}</Text>
        {entity.fields.productManufacturer && (
          <Text>
            <b>Manufacturer</b>: {entity.fields.productManufacturer}
          </Text>
        )}
        <HStack w="full" alignItems="flex-start">
          {entity.fields.body && (
            <VStack alignItems="flex-start">
              {entity.fields.body.content.map(
                (c: any, contentIndex: number) => {
                  return (
                    <ContentfulBodyProcessor
                      key={contentIndex}
                      content={c}
                      contentIndex={contentIndex}
                    />
                  );
                }
              )}
            </VStack>
          )}
          {entity.fields.productImages && (
            <VStack alignItems="flex-start">
              {entity.fields.productImages.map((i: any, ImageIndex: number) => {
                return <Image src={i.fields.file.url} />;
              })}
            </VStack>
          )}
        </HStack>
        {entity.fields.productVideo && (
          <SimpleGrid columns={3} spacing={10} mt={10}>
            {entity.fields.productVideo.map((v: any, videoIndex: number) => {
              return (
                <Box h={225} w={400}>
                  <ContentfulLinkedVideo videoId={v.sys.id} />
                </Box>
              );
            })}
          </SimpleGrid>
        )}
      </VStack>
    );
  } else if (entity.sys.contentType.sys.id === "series") {
    return <ContentfulSeries entity={entity} />;
  }
  return (
    <VStack w="full" alignItems="flex-start">
      {entity.fields.shortDescription && (
        <Text pb={4}>{entity.fields.shortDescription}</Text>
      )}
      {entity.fields.vimeoId && (
        <Box w="full">
          <Vimeo
            video={{
              id: entity.fields.vimeoId,
              title: "",
              duration: entity.fields.length,
              thumbnail: "",
            }}
          />
        </Box>
      )}
      {entity.fields.productManufacturer && (
        <Text>
          <b>Manufacturer</b>: {entity.fields.productManufacturer}
        </Text>
      )}
      {entity.fields.longDescription &&
        entity.fields.longDescription.content.map(
          (c: any, contentIndex: number) => {
            return (
              <ContentfulBodyProcessor
                key={contentIndex}
                content={c}
                contentIndex={contentIndex}
              />
            );
          }
        )}
      {entity.fields.body &&
        entity.fields.body.content.map((c: any, contentIndex: number) => {
          return (
            <ContentfulBodyProcessor
              key={contentIndex}
              content={c}
              contentIndex={contentIndex}
            />
          );
        })}
      {entity.fields.productImages &&
        entity.fields.productImages.map((i: any, ImageIndex: number) => {
          return <Image src={i.fields.file.url} />;
        })}
      {entity.fields.sessions && (
        <ContentfulSessions sessions={entity.fields.sessions} />
      )}
    </VStack>
  );
};

interface ContentfulBodyProcessorProps {
  content: any;
  contentIndex: number;
}

const ContentfulBodyProcessor: React.FC<ContentfulBodyProcessorProps> = ({
  content,
  contentIndex,
}) => {
  const navigate = useNavigate();
  if (content.nodeType === "document") {
    return content.content.map((cc: any, paragraphIndex: number) => {
      return (
        <ContentfulBodyProcessor
          key={paragraphIndex}
          content={cc}
          contentIndex={paragraphIndex}
        />
      );
    });
  } else if (content.nodeType === "paragraph") {
    return (
      <Text mb={2} key={contentIndex}>
        {content.content.map((cc: any, paragraphIndex: number) => {
          return (
            <ContentfulBodyProcessor
              key={paragraphIndex}
              content={cc}
              contentIndex={paragraphIndex}
            />
          );
        })}
      </Text>
    );
  } else if (content.nodeType === "heading-1") {
    return (
      <Heading as="h1" size="small" key={contentIndex}>
        {content.content.map((cc: any, paragraphIndex: number) => {
          return (
            <ContentfulBodyProcessor
              key={paragraphIndex}
              content={cc}
              contentIndex={paragraphIndex}
            />
          );
        })}
      </Heading>
    );
  } else if (content.nodeType === "heading-2") {
    return (
      <Heading as="h2" size="small" key={contentIndex}>
        {content.content.map((cc: any, paragraphIndex: number) => {
          return (
            <ContentfulBodyProcessor
              key={paragraphIndex}
              content={cc}
              contentIndex={paragraphIndex}
            />
          );
        })}
      </Heading>
    );
  } else if (content.nodeType === "heading-3") {
    return (
      <Heading as="h3" size="small" key={contentIndex}>
        {content.content.map((cc: any, paragraphIndex: number) => {
          return (
            <ContentfulBodyProcessor
              key={paragraphIndex}
              content={cc}
              contentIndex={paragraphIndex}
            />
          );
        })}
      </Heading>
    );
  } else if (content.nodeType === "heading-4") {
    return (
      <Heading as="h4" size="small" key={contentIndex}>
        {content.content.map((cc: any, paragraphIndex: number) => {
          return (
            <ContentfulBodyProcessor
              key={paragraphIndex}
              content={cc}
              contentIndex={paragraphIndex}
            />
          );
        })}
      </Heading>
    );
  } else if (content.nodeType === "embedded-asset-block") {
    return (
      <Image
        src={content.data.target.fields.file.url}
        h={content.data.target.fields.file.details.height}
        w={content.data.target.fields.file.details.width}
      />
    );
  } else if (content.nodeType === "embedded-entry-block") {
    if (content.data.target.sys.contentType) {
      if (content.data.target.sys.contentType.sys.id === "video") {
        return (
          <VimeoContainer
            video={{
              id: content.data.target.fields.vimeoId,
              title: content.data.target.fields.title,
              duration: content.data.target.fields.length,
              thumbnail: "",
            }}
            title={content.data.target.fields.title}
            description={content.data.target.fields.shortDescription}
            videoWidth={400}
          />
        );
      } else if (content.data.target.sys.contentType.sys.id === "step") {
        // Link to it
        return (
          <Button
            key={contentIndex}
            onClick={() => {
              navigate("/library/direct/" + content.data.target.sys.id, {
                replace: false,
              });
            }}
            variant="link"
          >
            {content.data.target.fields.title}
          </Button>
        );
      } else if (content.data.target.sys.contentType.sys.id === "article") {
        // Link to it
        return (
          <Button
            key={contentIndex}
            onClick={() => {
              navigate("/library/direct/" + content.data.target.sys.id, {
                replace: false,
              });
            }}
            variant="link"
          >
            {content.data.target.fields.title}
          </Button>
        );
      }
      return null;
      // return (
      //   <Text color="orange">{content.data.target.sys.contentType.sys.id}</Text>
      // );
    } else if (content.data.target.sys.type) {
      if (content.data.target.sys.type === "Link") {
        // Link to it
        return <ContentfulLinkedBlock blockId={content.data.target.sys.id} />;
      }
      return <Text color="green">{content}</Text>;
    }
    return <Text color="pink">{JSON.stringify(content.data.target)}</Text>;
  } else if (content.nodeType === "unordered-list") {
    return (
      <UnorderedList key={contentIndex}>
        {content.content.map((cc: any, paragraphIndex: number) => {
          return (
            <ContentfulBodyProcessor
              key={paragraphIndex}
              content={cc}
              contentIndex={paragraphIndex}
            />
          );
        })}
      </UnorderedList>
    );
  } else if (content.nodeType === "ordered-list") {
    return (
      <OrderedList key={contentIndex}>
        {content.content.map((cc: any, paragraphIndex: number) => {
          return (
            <ContentfulBodyProcessor
              key={paragraphIndex}
              content={cc}
              contentIndex={paragraphIndex}
            />
          );
        })}
      </OrderedList>
    );
  } else if (content.nodeType === "text") {
    if (
      content.marks &&
      content.marks.length === 1 &&
      content.marks[0].type === "bold"
    ) {
      return <b key={contentIndex}>{content.value}</b>;
    } else if (
      content.marks &&
      content.marks.length === 1 &&
      content.marks[0].type === "italic"
    ) {
      return <i key={contentIndex}>{content.value}</i>;
    }
    return content.value;
  } else if (content.nodeType === "paragraph") {
    return content.content.map((cc: any, paragraphIndex: number) => {
      return (
        <ContentfulBodyProcessor
          key={paragraphIndex}
          content={cc}
          contentIndex={paragraphIndex}
        />
      );
    });
  } else if (content.nodeType === "asset-hyperlink") {
    return content.content.map((cc: any, paragraphIndex: number) => {
      return (
        <Button
          key={paragraphIndex}
          onClick={() => {
            window
              .open(
                content.data.target.fields.file.url,
                "_blank",
                "noopener,noreferrer"
              )
              ?.focus();
          }}
          variant="link"
        >
          {content.content.map((cc: any, paragraphIndex: number) => {
            return (
              <ContentfulBodyProcessor
                key={paragraphIndex}
                content={cc}
                contentIndex={paragraphIndex}
              />
            );
          })}
        </Button>
      );
    });
  } else if (content.nodeType === "list-item") {
    return content.content.map((cc: any, paragraphIndex: number) => {
      return (
        <ListItem key={paragraphIndex} ml={10}>
          <ContentfulBodyProcessor
            key={paragraphIndex}
            content={cc}
            contentIndex={paragraphIndex}
          />
        </ListItem>
      );
    });
  } else if (content.nodeType === "hyperlink") {
    return (
      <Button
        key={contentIndex}
        onClick={() => {
          if (content.data.uri.indexOf("session:") > -1) {
            navigate(
              "/sessions/id/" + content.data.uri.replace("session:", ""),
              { replace: false }
            );
          } else {
            window.open(content.data.uri)?.focus();
          }
        }}
        variant="link"
      >
        {content.content.map((cc: any, paragraphIndex: number) => {
          return (
            <ContentfulBodyProcessor
              key={paragraphIndex}
              content={cc}
              contentIndex={paragraphIndex}
            />
          );
        })}
      </Button>
    );
  } else if (content.nodeType === "blockquote") {
    return (
      <HStack key={contentIndex} w="full" alignItems="flex-start">
        <Box bg="ssNeonOrangeDark">&nbsp;</Box>
        {content.content.map((cc: any, paragraphIndex: number) => {
          return (
            <ContentfulBodyProcessor
              key={paragraphIndex}
              content={cc}
              contentIndex={paragraphIndex}
            />
          );
        })}
      </HStack>
    );
  }
  return (
    <Text color="red" key={contentIndex}>
      {content.nodeType}
    </Text>
  );
};

interface ContentfulLinkedVideoProps {
  videoId: string;
}

const ContentfulLinkedVideo: React.FC<ContentfulLinkedVideoProps> = ({
  videoId,
}) => {
  const [linkedVideo, setLinkedVideo] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const getEntityLocal = async () => {
      const linkedResult = await client.getEntry(videoId);
      setLinkedVideo(linkedResult);
      setLoading(false);
    };
    getEntityLocal();
  }, [videoId]);

  if (loading) {
    return <Loading />;
  }

  if (!linkedVideo) {
    return null;
  }

  return (
    <Box>
      <Vimeo
        video={{
          id: linkedVideo.fields.vimeoId,
          title: linkedVideo.fields.title,
          duration: linkedVideo.fields.length,
          thumbnail: "",
        }}
      />
    </Box>
  );
};

interface ContentfulLinkedBlockProps {
  blockId: string;
}

const ContentfulLinkedBlock: React.FC<ContentfulLinkedBlockProps> = ({
  blockId,
}) => {
  const [linkedBlock, setLinkedBlock] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const getEntityLocal = async () => {
      const linkedResult = await client.getEntry(blockId);
      setLinkedBlock(linkedResult);
      setLoading(false);
    };
    getEntityLocal();
  }, [blockId]);

  if (loading) {
    return <Loading />;
  }

  if (!linkedBlock) {
    return null;
  }

  return (
    <Button
      onClick={() => {
        window
          .open(
            "/library/direct/" + linkedBlock.sys.id,
            "contentLinky",
            "noopener,noreferrer"
          )
          ?.focus();
      }}
      variant="link"
    >
      {linkedBlock.fields.title}
    </Button>
  );
};

interface ContentfulSeriesProps {
  entity: any;
}

const ContentfulSeries: React.FC<ContentfulSeriesProps> = ({ entity }) => {
  const [step, setStep] = useState<number>(0);
  const { access: accessToken } = useAppSelector(selectAuthState);
  const intensityColorMode = useColorModeValue(
    "ssNeonOrangeDark",
    "ssNeonOrangeLight"
  );
  const navigate = useNavigate();
  const toast = useToast();
  return (
    <VStack w="full" alignItems="flex-start">
      <Text>{entity.fields.shortDescription}</Text>
      <SimpleGrid columns={[1, 1, 2, 2]} gap={5}>
        <VStack alignItems="flex-start" w="full">
          {entity.fields.steps.map((s: any, stepIndex: number) => {
            return (
              <Button
                variant="link"
                onClick={() => {
                  if (checkLevelInArray(accessToken, s.fields.levels)) {
                    setStep(stepIndex);
                  } else {
                    createToast(toast, (props: any) => {
                      return (
                        <CustomToast
                          title={"Upgrade Required"}
                          status={"Warning"}
                          toast={toast}
                          toastId={props.id}
                        >
                          {s.fields.levels && (
                            <>
                              <Text>
                                '{s.fields.menuTitle}' requires one of the
                                following subscriptions,
                              </Text>
                              <UnorderedList>
                                {s.fields.levels.map(
                                  (l: string, index: number) => {
                                    return <ListItem key={index}>{l}</ListItem>;
                                  }
                                )}
                              </UnorderedList>
                              <Text>
                                You currently have{" "}
                                {getLevelInSentence(accessToken)}.
                              </Text>
                            </>
                          )}
                          <Button
                            w={"full"}
                            mt={2}
                            onClick={() => {
                              navigate("/account/subscription", {
                                replace: false,
                              });
                              toast.closeAll();
                            }}
                          >
                            Upgrade Now
                          </Button>
                        </CustomToast>
                      );
                    });
                  }
                }}
                key={stepIndex}
              >
                {checkLevelInArray(accessToken, s.fields.levels) ? (
                  s.fields.menuTitle
                ) : (
                  <>
                    <Icon
                      h={15}
                      w={15}
                      mr={2}
                      as={FaLock}
                      color={intensityColorMode}
                    />
                    {s.fields.menuTitle}
                  </>
                )}
              </Button>
            );
          })}
        </VStack>
        <VStack w="full" alignItems="flex-start">
          {typeof step !== "undefined" && (
            <Contentful entity={entity.fields.steps[step]} />
          )}
          ;
        </VStack>
      </SimpleGrid>
    </VStack>
  );
};

interface ContentfulSessionsProps {
  sessions: string[];
}

const ContentfulSessions: React.FC<ContentfulSessionsProps> = ({
  sessions,
}) => {
  return (
    <VStack w="full" alignItems="flex-start">
      <Heading as="h2" size="small">
        Sessions to Swim
      </Heading>
      <SimpleGrid columns={2} gap={4}>
        {sessions.map((sessionId: string) => {
          return <ContentfulSession key={sessionId} sessionId={sessionId} />;
        })}
      </SimpleGrid>
    </VStack>
  );
};

interface ContentfulSessionProps {
  sessionId: string;
}

const ContentfulSession: React.FC<ContentfulSessionProps> = ({ sessionId }) => {
  const [session, setSession] = useState<session | null>();
  const intensityColorMode = useColorModeValue(
    "ssNeonOrangeDark",
    "ssNeonOrangeLight"
  );
  const boxColorMode = useColorModeValue(
    "ssBoxBackgroundLight",
    "ssBoxBackgroundDark"
  );
  const navigate = useNavigate();

  useEffect(() => {
    const getSessionLocal = async () => {
      if (sessionId) {
        const response = await getSessionById(sessionId);
        setSession(response);
      }
    };
    getSessionLocal();
  }, [sessionId]);

  if (!session) {
    return null;
  }

  return (
    <Box
      cursor="pointer"
      bg={boxColorMode}
      borderRadius={10}
      onClick={() => {
        navigate(
          "/sessions/id/" + session.id + "/" + session.data.scaled.distance,
          { replace: false }
        );
      }}
    >
      <HStack alignItems="flex-start">
        {session.data.images.has && (
          <Image
            src={session.data.images.data.preview}
            borderLeftRadius={10}
            h={150}
            w={150}
            fit="cover"
            fallbackSrc="https://images.ctfassets.net/50b15ahactsg/Al70qWgtjQvAjRifFlBIq/35448e67bbf63873261153bf268d3764/Paul_Squad.jpg"
          />
        )}
        {!session.data.images.has && (
          <Image
            borderLeftRadius={10}
            h={150}
            w={150}
            fit="cover"
            src="https://images.ctfassets.net/50b15ahactsg/Al70qWgtjQvAjRifFlBIq/35448e67bbf63873261153bf268d3764/Paul_Squad.jpg"
          />
        )}
        <VStack p={2} alignItems="flex-start" w="full">
          <Heading size="md" as="h3">
            {session.data.name}
          </Heading>
          <SimpleGrid columns={2} w="full" pt={2}>
            <Tooltip hasArrow label="Distance">
              <span>
                <Icon
                  h={6}
                  w={6}
                  color="grey"
                  viewBox="0 0 100 100"
                  mr={2}
                  as={GiPathDistance}
                />
                <Box display="inline-block" position="relative" top="-6px">
                  {session.data.scaled.distance}
                </Box>
              </span>
            </Tooltip>
            <Tooltip hasArrow label="Expected Time">
              <span>
                <Icon h={6} w={6} color="grey" mr={2} as={IoTimerOutline} />
                <Box display="inline-block" position="relative" top="-6px">
                  {session.data.scaled.time}
                </Box>
              </span>
            </Tooltip>
            <Tooltip hasArrow label="Intensity">
              <span>
                <Icon
                  h={5}
                  w={5}
                  color="grey"
                  as={IoFlame}
                  fill={
                    session.data.scaled.intensity > 0
                      ? intensityColorMode
                      : "grey"
                  }
                />
                <Icon
                  h={5}
                  w={5}
                  color="grey"
                  as={IoFlame}
                  fill={
                    session.data.scaled.intensity > 1
                      ? intensityColorMode
                      : "grey"
                  }
                />
                <Icon
                  h={5}
                  w={5}
                  color="grey"
                  as={IoFlame}
                  fill={
                    session.data.scaled.intensity > 2
                      ? intensityColorMode
                      : "grey"
                  }
                />
                <Icon
                  h={5}
                  w={5}
                  color="grey"
                  as={IoFlame}
                  fill={
                    session.data.scaled.intensity > 3
                      ? intensityColorMode
                      : "grey"
                  }
                />
                <Icon
                  h={5}
                  w={5}
                  color="grey"
                  as={IoFlame}
                  fill={
                    session.data.scaled.intensity > 4
                      ? intensityColorMode
                      : "grey"
                  }
                />
              </span>
            </Tooltip>
            <Tooltip hasArrow label="sTSS">
              <span>
                <Icon h={6} w={6} color="grey" mr={2} as={MdModelTraining} />
                <Box display="inline-block" position="relative" top="-6px">
                  {session.data.scaled.stss
                    ? session.data.scaled.stss.toFixed(2)
                    : 0}
                </Box>
              </span>
            </Tooltip>
          </SimpleGrid>
        </VStack>
      </HStack>
    </Box>
  );
};

export { Contentful, ContentfulBodyProcessor };
