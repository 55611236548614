import {
  Box,
  Button,
  HStack,
  Heading,
  Spacer,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  acceptAffiliation,
  getAffiliateByIdExtended,
} from "../../DataAccess/affiliates";
import { useAppSelector } from "../../app/hooks";
import { AffiliateTerms } from "../../components/auth/AffiliateTerms";
import { CoachBox } from "../../components/coaches/CoachBox";
import {
  BreadcrumbContext,
  breadcrumbContextType,
} from "../../components/context/BreadcrumbContext";
import { MessageDisplay } from "../../components/generic/MessageDisplay";
import { CreateSupportTicket } from "../../components/ui/CreateSupportTicket";
import { CustomToast } from "../../components/ui/CustomToast";
import Loading from "../../components/ui/Loading";
import { selectUserState } from "../../features/user/userSlice";
import { affiliate } from "../../types/affiliate";
import { breadcrumbLink } from "../../types/breadcrumb";
import { forceRefreshToken } from "../../utils/authHelper";
import { createToast } from "../../utils/toastHelper";

const AffiliateAcceptPage = () => {
  const { affiliateId } = useParams<{ affiliateId: string }>();
  const { user } = useAppSelector(selectUserState);
  const [loadingAffiliate, setLoadingAffiliate] = useState<boolean>(true);
  const [affiliate, setAffiliate] = useState<affiliate | null>(null);
  const [isAccepting, setIsAccepting] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();

  const { setBreadcrumbLinks } =
    useContext<breadcrumbContextType>(BreadcrumbContext);

  useEffect(() => {
    const breadcrumbLinks: breadcrumbLink[] = [];
    breadcrumbLinks.push({ href: "/relationships", title: "Relationships" });
    setBreadcrumbLinks(breadcrumbLinks);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getAffiliateByIdLocal = async () => {
      if (affiliateId) {
        try {
          const affiliateResult = await getAffiliateByIdExtended(affiliateId);
          if (affiliateResult) {
            setAffiliate(affiliateResult);
            setLoadingAffiliate(false);
          }
        } catch (error) {
          console.log(error);
          setLoadingAffiliate(false);
        }
      } else {
        setLoadingAffiliate(false);
      }
    };
    getAffiliateByIdLocal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [affiliateId]);

  if (loadingAffiliate) {
    return <Loading message={"Loading affiliate details"} />;
  }

  if (!user || !affiliate) {
    return <Loading message={"Unable to find user or affiliate"} />;
  }

  if (affiliate && affiliate?.data.widgetOnly) {
    return (
      <VStack w="full">
        <Heading as="h2" size="xl" mb={4}>
          Friends of Swim Smooth Program
        </Heading>
        <MessageDisplay status="error" title="Not Found">
          <Text>
            {affiliate.data.affiliateName} doesn't allow interactive signups.
            You'll need to use the Swim Smooth widget on their site.
          </Text>
        </MessageDisplay>
      </VStack>
    );
  }

  const acceptAffiliateHandler = async () => {
    try {
      setIsAccepting(true);
      await acceptAffiliation(affiliateId as string);
      await forceRefreshToken(dispatch);
      navigate("/progress", { replace: false });
      setIsAccepting(false);
    } catch (error) {
      const realError = error as Error;
      createToast(toast, (props: any) => {
        return (
          <CustomToast
            title={"Accept Affilitation"}
            status={"Error"}
            toast={toast}
            toastId={props.id}
          >
            <Text>{realError.message}</Text>
            <CreateSupportTicket />
          </CustomToast>
        );
      });
      setIsAccepting(false);
    }
  };

  return (
    <VStack w="full">
      <Heading as="h2" size="xl" mb={4}>
        Accept Affiliate
      </Heading>
      <HStack w={"full"} alignItems={"flex-start"}>
        <CoachBox coachData={affiliate.data.coach!} w={400} mr={10} />
        <Box w={"full"}>
          <Text as={"span"}>
            {user.data.basic.firstName}, you've been invited to join{" "}
            <em>{affiliate.data.affiliateName}</em> as an affiliate member.
          </Text>
          {affiliate.data.trial && (
            <Text as="span">
              If you accept you'll be linked to{" "}
              <em>{affiliate.data.affiliateName}</em>.
            </Text>
          )}
          <AffiliateTerms
            parentName={affiliate.data.affiliateName}
            program={"Friends of Swim Smooth Program"}
          />
          <HStack w={"full"} mt={5}>
            <Button
              variant={"success"}
              isLoading={isAccepting}
              onClick={acceptAffiliateHandler}
            >
              Accept
            </Button>
            <Spacer />
          </HStack>
        </Box>
      </HStack>
    </VStack>
  );
};

export { AffiliateAcceptPage };
