import { Heading, VStack } from "@chakra-ui/react";

const SessionEditorDetailPage = () => {
  return (
    <VStack w="full">
      <Heading as="h2" size="xl" mb={4}>
        Session Editor Detail
      </Heading>
    </VStack>
  );
};

export { SessionEditorDetailPage };
