import { Box, BoxProps } from "@chakra-ui/react";
import { useColorModeValue } from "@chakra-ui/system";

const BoxWrapper: React.FC<BoxProps> = ({ children }) => {
  const boxColorMode = useColorModeValue(
    "ssBoxBackgroundLight",
    "ssBoxBackgroundDark"
  );
  return (
    <Box bg={boxColorMode} p={3} borderRadius={10} height="100%" w="full">
      {children}
    </Box>
  );
};

export { BoxWrapper };
