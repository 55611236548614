import {
  BoxProps,
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderThumb,
  SliderTrack,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { getSessionById } from "../../DataAccess/sessions";
import { useAppSelector } from "../../app/hooks";
import { selectAuthState } from "../../features/auth/authSlice";
import { updateSessionState } from "../../features/session/sessionSlice";
import { session } from "../../types/session";
import { user } from "../../types/user";
import { checkRole } from "../../utils/authHelper";
import { createToast } from "../../utils/toastHelper";
import { CustomToast } from "../ui/CustomToast";

interface SessionScalerProps extends BoxProps {
  sessionId: string;
  session: session;
  user: user;
  disabled: boolean;
  td: number | null;
  setTd: any;
  setLoadingSession: any;
}

const SessionScaler: React.FC<SessionScalerProps> = ({
  sessionId,
  session,
  user,
  disabled,
  td,
  setTd,
  setLoadingSession,
}) => {
  const { access: accessToken } = useAppSelector(selectAuthState);

  const scalerColorMode = useColorModeValue("ssPrimaryDark", "ssPrimaryLight");

  const toast = useToast();
  const dispatch = useDispatch();

  const scaleMe = async (val: number) => {
    toast.closeAll();
    setLoadingSession(true);
    if (checkRole(accessToken, "scale", "session") && sessionId && val) {
      const sessionResult = await getSessionById(sessionId, val);
      if (val !== sessionResult.data.scaled.distance) {
        createToast(toast, (props: any) => {
          return (
            <CustomToast
              title={"Session Scaling"}
              status={"Info"}
              toast={toast}
              toastId={props.id}
            >
              <Text>
                Unable to scale to {val}
                {user?.data.basic.defaultPool.lapUnit}, nearest possible scaling{" "}
                {sessionResult.data.scaled.distance}
                {user?.data.basic.defaultPool.lapUnit}.
              </Text>
            </CustomToast>
          );
        });
      }
      setTd(sessionResult.data.scaled.distance);
      dispatch(
        updateSessionState({
          session: sessionResult,
          targetDistance: sessionResult.data.scaled.distance,
          requestedDistance: val,
        })
      );
      window.history.replaceState(
        null,
        "",
        "/sessions/id/" +
          sessionId +
          "/" +
          sessionResult.data.scaled.distance +
          "/" +
          val
      );
    } else if (sessionId) {
      const sessionResult = await getSessionById(sessionId);
      dispatch(
        updateSessionState({
          session: sessionResult,
          targetDistance: sessionResult.data.scaled.distance,
          requestedDistance: sessionResult.data.scaled.distance,
        })
      );
      window.history.replaceState(
        null,
        "",
        "/sessions/id/" +
          sessionId +
          "/" +
          sessionResult.data.scaled.distance +
          "/" +
          sessionResult.data.scaled.distance
      );
    }
    setLoadingSession(false);
  };

  return (
    <Slider
      isDisabled={disabled}
      onChange={(val) => {
        setTd(val);
      }}
      onChangeEnd={(val) => scaleMe(val)}
      defaultValue={session.data.scaled.distance}
      min={100}
      max={
        session.data.base.distance <= 10000 ? 10000 : session.data.base.distance
      }
      step={100}
    >
      <SliderMark
        value={td ? td : session.data.scaled.distance}
        textAlign="center"
        color={scalerColorMode}
        mt="-10"
        ml="-3"
        fontSize="sm"
        fontWeight={"bold"}
      >
        {td ? td / 1000 : session.data.scaled.distance / 1000}k
        {user.data.basic.defaultPool.lapUnit}
      </SliderMark>
      {session.data.base.distance <= 10000 && (
        <SliderMark
          value={session.data.base.distance}
          mt="3"
          ml="-3"
          fontSize="sm"
        >
          {session.data.base.distance / 1000}k
          {user.data.basic.defaultPool.lapUnit}
        </SliderMark>
      )}
      {session.data.base.distance >= 2000 && (
        <SliderMark value={0} mt="3" ml="-3" fontSize="sm">
          100
          {user.data.basic.defaultPool.lapUnit}
        </SliderMark>
      )}
      {session.data.base.distance <= 10000 && (
        <SliderMark value={10000} mt="3" ml="-3" fontSize="sm">
          10k
          {user.data.basic.defaultPool.lapUnit}
        </SliderMark>
      )}
      {session.data.base.distance > 10000 && (
        <SliderMark
          value={session.data.base.distance}
          mt="3"
          ml="-3"
          fontSize="sm"
        >
          {session.data.base.distance / 1000}k
          {user.data.basic.defaultPool.lapUnit}
        </SliderMark>
      )}
      <SliderTrack>
        <SliderFilledTrack bg={scalerColorMode} />
      </SliderTrack>
      <SliderThumb />
    </Slider>
  );
};

export { SessionScaler };
