import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

// Define the state of the slice as an object
export interface AreasState {
  areas: any[];
  lastUpdated: Date;
}

export interface AreasStateUpdate {
  areas: any[];
}

// Define an initial state
const initialState: AreasState = {
  areas: [],
  lastUpdated: new Date('1970-01-01T00:00:00.000'),
};

// Create a slice containing the configuration of the state
// and the reducers functions
const areasSlice = createSlice({
  name: 'areasState',
  initialState,
  reducers: {
    updateAreasState(state, action: PayloadAction<AreasStateUpdate | null>) {
      if (action.payload) {
        state.areas = action.payload.areas;
        state.lastUpdated = new Date();
      } else {
        state.areas = initialState.areas;
        state.lastUpdated = initialState.lastUpdated;
      }
    },
  },
});

// Export each reducers function defined in createSlice
export const { updateAreasState } = areasSlice.actions;

export const selectAreasState = (state: RootState): AreasState => {
  return state.areasState;
};

// Export default the slice reducer
export default areasSlice.reducer;
