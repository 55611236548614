import { LockIcon } from "@chakra-ui/icons";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  BoxProps,
  Button,
  Td,
  Text,
  Tr,
  useDisclosure,
  useMediaQuery,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import fileDownload from "js-file-download";
import { useCallback, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { deleteCss, getCssHistory } from "../../DataAccess/css";
import { useAppSelector } from "../../app/hooks";
import { selectAuthState } from "../../features/auth/authSlice";
import { cssEntry } from "../../types/css";
import { user } from "../../types/user";
import { checkRole } from "../../utils/authHelper";
import { ucFirst } from "../../utils/coreHelper";
import { formatDateLong, formatPace } from "../../utils/dateHelper";
import { createToast } from "../../utils/toastHelper";
import { CreateSupportTicket } from "../ui/CreateSupportTicket";
import { CustomToast } from "../ui/CustomToast";

interface CssListItemProps extends BoxProps {
  user: user;
  cssEntry: cssEntry;
  setCssHistory: any;
  blurred?: boolean;
}

const CssListItem: React.FC<CssListItemProps> = ({
  user,
  cssEntry,
  setCssHistory,
  blurred,
}) => {
  const { access: accessToken } = useAppSelector(selectAuthState);
  const [gettingPdf, setGettingPdf] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const deleteRef = useRef(null);
  const [hamburgerMode] = useMediaQuery("(max-width: 768px)");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const navigate = useNavigate();

  const deleteCssHandler = async () => {
    try {
      setIsDeleting(true);
      await deleteCss(cssEntry._id);
      const cssHistory = await getCssHistory();
      setCssHistory(
        cssHistory.map((e: any) => {
          const date = new Date(e.date);
          e.dateN = date.getTime();
          return e;
        })
      );
      createToast(toast, (props: any) => {
        return (
          <CustomToast
            title={"Delete CSS Entry"}
            status={"Success"}
            toast={toast}
            toastId={props.id}
          >
            <Text>CSS entry deleted</Text>
            <CreateSupportTicket />
          </CustomToast>
        );
      });
      setIsDeleting(false);
      onClose();
    } catch (error) {
      createToast(toast, (props: any) => {
        return (
          <CustomToast
            title={"Delete CSS Entry"}
            status={"Error"}
            toast={toast}
            toastId={props.id}
          >
            <Text>Unable to delete CSS entry, please contact support.</Text>
            <CreateSupportTicket />
          </CustomToast>
        );
      });
      setIsDeleting(false);
      onClose();
    }
  };

  const premiumOnly = () => {
    toast.closeAll();
    createToast(toast, (props: any) => {
      return (
        <CustomToast
          title={"Save CSS"}
          status={"Error"}
          toast={toast}
          toastId={props.id}
        >
          <Text>
            PDF export features require you to be logged in or to have a higher
            level of subscription than you currently have. Check out the pricing
            page for more details.
          </Text>
        </CustomToast>
      );
    });
  };

  const viewCss = useCallback(() => {
    if (cssEntry) {
      navigate("/css/" + cssEntry._id, { replace: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cssEntry]);

  const getCssPdf = useCallback(async () => {
    if (user && cssEntry) {
      setGettingPdf(true);
      try {
        let url =
          process.env.REACT_APP_API_ROOT_URL +
          "settings/css/" +
          user._id +
          "/" +
          cssEntry._id +
          "/m/print";
        url += "?t=" + new Date().getTime();
        const res = await axios.get(url, {
          headers: {
            "Content-Type": "application/pdf",
          },
          responseType: "blob",
        });
        const pdfData = new Blob([res.data], { type: "application/pdf" });
        fileDownload(pdfData, "SwimSmoothCSSEntry_" + cssEntry.date + ".pdf");
        setGettingPdf(false);
      } catch (error: any) {
        console.log(error);
        setGettingPdf(false);
        createToast(toast, (props: any) => {
          return (
            <CustomToast
              title={"Get CSS Entry PDF"}
              status={"Error"}
              toast={toast}
              toastId={props.id}
            >
              <Text>Unable to get CSS entry PDF, please contact support.</Text>
              <CreateSupportTicket />
            </CustomToast>
          );
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, cssEntry]);

  if (hamburgerMode) {
    return blurred ? (
      <Tr key={cssEntry._id}>
        <Td>{formatDateLong(new Date(cssEntry.date))}</Td>
        <Td className={"blurred"} isNumeric>
          We really
        </Td>
        <Td isNumeric>
          <Button
            size={"sm"}
            variant={"success"}
            onClick={() => {
              navigate("/account/subscription");
            }}
          >
            Upgrade for Full CSS Tools & History
          </Button>
        </Td>
      </Tr>
    ) : (
      <Tr key={cssEntry._id}>
        <Td>{formatDateLong(new Date(cssEntry.date))}</Td>
        <Td isNumeric>
          {user.data.basic.defaultPool.lapUnit === "y"
            ? formatPace(cssEntry.y.secondsPer100)
            : formatPace(cssEntry.m.secondsPer100)}
        </Td>
        <Td isNumeric>
          {cssEntry.dataFormatVersion && cssEntry.dataFormatVersion > 1 && (
            <>
              <Button isLoading={gettingPdf} onClick={viewCss} size="sm" mr={2}>
                View
              </Button>
              {!checkRole(accessToken, "pdf", "css") && (
                <Button
                  isLoading={gettingPdf}
                  leftIcon={<LockIcon />}
                  onClick={premiumOnly}
                  size="sm"
                  mr={2}
                >
                  Get PDF
                </Button>
              )}
              {checkRole(accessToken, "pdf", "css") && (
                <Button
                  isLoading={gettingPdf}
                  onClick={getCssPdf}
                  size="sm"
                  mr={2}
                >
                  Get PDF
                </Button>
              )}
            </>
          )}
          <>
            <Button
              variant="warning"
              isLoading={isDeleting}
              onClick={onOpen}
              size="sm"
            >
              Delete
            </Button>
            <AlertDialog
              isOpen={isOpen}
              leastDestructiveRef={deleteRef}
              onClose={onClose}
            >
              <AlertDialogOverlay>
                <AlertDialogContent>
                  <AlertDialogHeader fontSize="lg" fontWeight="bold">
                    Delete CSS Entry
                  </AlertDialogHeader>
                  <AlertDialogBody>
                    Are you sure? You can't undo this action afterwards.
                  </AlertDialogBody>
                  <AlertDialogFooter>
                    <Button ref={deleteRef} onClick={onClose}>
                      Back
                    </Button>
                    <Button
                      variant="warning"
                      px={20}
                      isLoading={isDeleting}
                      onClick={deleteCssHandler}
                      ml={3}
                    >
                      Delete CSS Entry
                    </Button>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialogOverlay>
            </AlertDialog>
          </>
        </Td>
      </Tr>
    );
  }

  return blurred ? (
    <Tr key={cssEntry._id}>
      <Td>{formatDateLong(new Date(cssEntry.date))}</Td>
      <Td className={"blurred"} isNumeric>
        We really
      </Td>
      <Td className={"blurred"}>did</Td>
      <Td className={"blurred"}>think</Td>
      <Td className={"blurred"}>about</Td>
      <Td className={"blurred"}>this list!</Td>
      <Td isNumeric>
        <Button
          size={"sm"}
          variant={"success"}
          onClick={() => {
            navigate("/account/subscription");
          }}
        >
          Upgrade for Full CSS Tools & History
        </Button>
      </Td>
    </Tr>
  ) : (
    <Tr key={cssEntry._id}>
      <Td>{formatDateLong(new Date(cssEntry.date))}</Td>
      <Td isNumeric>
        {user.data.basic.defaultPool.lapUnit === "y"
          ? formatPace(cssEntry.y.secondsPer100)
          : formatPace(cssEntry.m.secondsPer100)}
      </Td>
      <Td>{ucFirst(cssEntry.type)}</Td>
      <Td>
        {cssEntry.pool.lapLength}
        {cssEntry.pool.lapUnit}
      </Td>
      <Td>{cssEntry.test ? cssEntry.test.engine.result : " - "}</Td>
      <Td>{cssEntry.test ? cssEntry.test.pacing.result : " - "}</Td>
      <Td isNumeric>
        {cssEntry.dataFormatVersion && cssEntry.dataFormatVersion > 1 && (
          <>
            <Button isLoading={gettingPdf} onClick={viewCss} size="sm" mr={2}>
              View
            </Button>
            {!checkRole(accessToken, "pdf", "css") && (
              <Button
                isLoading={gettingPdf}
                leftIcon={<LockIcon />}
                onClick={premiumOnly}
                size="sm"
                mr={2}
              >
                Get PDF
              </Button>
            )}
            {checkRole(accessToken, "pdf", "css") && (
              <Button
                isLoading={gettingPdf}
                onClick={getCssPdf}
                size="sm"
                mr={2}
              >
                Get PDF
              </Button>
            )}
          </>
        )}
        <>
          <Button
            variant="warning"
            isLoading={isDeleting}
            onClick={onOpen}
            size="sm"
          >
            Delete
          </Button>
          <AlertDialog
            isOpen={isOpen}
            leastDestructiveRef={deleteRef}
            onClose={onClose}
          >
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader fontSize="lg" fontWeight="bold">
                  Delete CSS Entry
                </AlertDialogHeader>
                <AlertDialogBody>
                  Are you sure? You can't undo this action afterwards.
                </AlertDialogBody>
                <AlertDialogFooter>
                  <Button ref={deleteRef} onClick={onClose}>
                    Back
                  </Button>
                  <Button
                    variant="warning"
                    px={20}
                    isLoading={isDeleting}
                    onClick={deleteCssHandler}
                    ml={3}
                  >
                    Delete CSS Entry
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>
        </>
      </Td>
    </Tr>
  );
};

export { CssListItem };
