import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Icon,
  Spacer,
  Text,
  useMediaQuery,
  VStack,
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { IoMdRefresh } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { ActivityList } from "../../components/activities/ActivityList";
import { YourCoach } from "../../components/coaches/YourCoach";
import {
  BreadcrumbContext,
  breadcrumbContextType,
} from "../../components/context/BreadcrumbContext";
import { NotAuthorised } from "../../components/generic/NotAuthorised";
import { TrainingSummary } from "../../components/training/TrainingSummary";
import Loading from "../../components/ui/Loading";
import { getActivities } from "../../DataAccess/activities";
import {
  selectActivitiesState,
  updateActivitiesState,
} from "../../features/activity/activitiesSlice";
import { updateActivityState } from "../../features/activity/activitySlice";
import { selectAuthState } from "../../features/auth/authSlice";
import { selectUserState } from "../../features/user/userSlice";
import { checkRole } from "../../utils/authHelper";
import { formatDateTime, formatDateTimeShort } from "../../utils/dateHelper";

const ActivitiesPage = () => {
  const { access: accessToken } = useAppSelector(selectAuthState);
  const { setBreadcrumbLinks } =
    useContext<breadcrumbContextType>(BreadcrumbContext);
  const [refreshing, setRefreshing] = useState<boolean>(false);

  const { user } = useAppSelector(selectUserState);
  const { lastUpdated: lastUpdatedActivities } = useAppSelector(
    selectActivitiesState
  );
  const [hamburgerMode] = useMediaQuery("(max-width: 768px)");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const breadcrumbLinks = [{ href: "/activities", title: "Activities" }];
    setBreadcrumbLinks(breadcrumbLinks);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refresh = async () => {
    setRefreshing(true);
    dispatch(updateActivityState(null));
    const { activities, last } = await getActivities();
    dispatch(
      updateActivitiesState({
        lastActivity: last,
        lastLoad: activities.length,
        activities: activities,
      })
    );
    setRefreshing(false);
  };

  if (!user) {
    return <Loading message="Error Loading User" />;
  }

  if (!checkRole(accessToken, "read", "activity")) {
    return <NotAuthorised size={"full"} functionText={"activities"} />;
  }

  return (
    <VStack w="full">
      <Heading as="h2" size="xl">
        Activities
      </Heading>
      {!hamburgerMode && (
        <Flex fontSize="xs" mb={4} w="full">
          <Box pt={3}>
            <Text>
              <b>Activities Last Updated:</b>{" "}
              {formatDateTime(lastUpdatedActivities)}
            </Text>
          </Box>
          <Spacer />
          <HStack>
            <Button variant="success" isLoading={refreshing} onClick={refresh}>
              <Icon as={IoMdRefresh} />
            </Button>
            {checkRole(accessToken, "create", "activity") && (
              <Button
                onClick={() => {
                  navigate("/activities/create", { replace: false });
                }}
              >
                Create Activity
              </Button>
            )}
            {checkRole(accessToken, "create", "ttl") && (
              <Button
                onClick={() => {
                  navigate("/training", { replace: false });
                }}
              >
                Create Training Plan
              </Button>
            )}
          </HStack>
        </Flex>
      )}
      {hamburgerMode && (
        <VStack w="full">
          <HStack>
            <Button variant="success" isLoading={refreshing} onClick={refresh}>
              <Icon as={IoMdRefresh} />
            </Button>
            <Button
              onClick={() => {
                navigate("/activities/create", { replace: false });
              }}
            >
              Create Activity
            </Button>
          </HStack>
          <Text>
            <b>Last Updated:</b> {formatDateTimeShort(lastUpdatedActivities)}
          </Text>
        </VStack>
      )}
      {hamburgerMode && <ActivityList />}
      {!hamburgerMode && (
        <HStack w="full" alignItems="flex-start">
          <ActivityList />
          <VStack w="50%">
            <TrainingSummary />
            <YourCoach />
          </VStack>
        </HStack>
      )}
    </VStack>
  );
};

export { ActivitiesPage };
