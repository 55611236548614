import {
  Button,
  HStack,
  Spacer,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
  useMediaQuery,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { getBsrHistory } from "../../DataAccess/bsr";
import { selectAuthState } from "../../features/auth/authSlice";
import { selectUserState } from "../../features/user/userSlice";
import { bsrEntry } from "../../types/bsr";
import { checkRole } from "../../utils/authHelper";
import { NotAuthorised } from "../generic/NotAuthorised";
import Loading from "../ui/Loading";
import { BsrListItem } from "./BsrListItem";

const BsrHistory = () => {
  const [bsrHistory, setBsrHistory] = useState<any[]>([]);
  const [loadingBsr, setLoadingBsr] = useState<boolean>(true);

  const { user } = useAppSelector(selectUserState);
  const { access: accessToken } = useAppSelector(selectAuthState);

  const [hamburgerMode] = useMediaQuery("(max-width: 768px)");
  const navigate = useNavigate();

  useEffect(() => {
    const getBsrHistoryLocal = async () => {
      const bsrHistory = await getBsrHistory();
      setBsrHistory(
        bsrHistory.map((e: any) => {
          const date = new Date(e.date);
          e.dateN = date.getTime();
          return e;
        })
      );
      setLoadingBsr(false);
    };
    getBsrHistoryLocal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!user) {
    return <Loading message="Error Loading User" />;
  }

  if (
    !checkRole(accessToken, "latest", "bsr") &&
    !checkRole(accessToken, "history", "bsr")
  ) {
    return (
      <NotAuthorised functionText={"Full BSR Tools & History"} size={"full"} />
    );
  }

  if (loadingBsr) {
    return <Loading message="Loading BSR History" />;
  }

  if (hamburgerMode) {
    return (
      <VStack w="full" alignItems="flex-start">
        <TableContainer w="full">
          <Table variant="simple" w="full" size="sm">
            <Thead>
              <Tr>
                <Th>Date</Th>
                <Th isNumeric>BSR</Th>
                <Th isNumeric>Actions</Th>
              </Tr>
            </Thead>
            {bsrHistory.length === 0 && (
              <Tr>
                <Td colSpan={3}>No BSR Entried Found</Td>
              </Tr>
            )}
            {checkRole(accessToken, "history", "bsr") && (
              <Tbody>
                {bsrHistory.length === 0 && (
                  <Tr>
                    <Td textAlign={"center"} colSpan={4}>
                      No BSR Entried Found
                    </Td>
                  </Tr>
                )}
                {bsrHistory.map((bsrEntry: bsrEntry) => {
                  return (
                    <BsrListItem
                      key={bsrEntry._id}
                      bsrEntry={bsrEntry}
                      setBsrHistory={setBsrHistory}
                    />
                  );
                })}
              </Tbody>
            )}
            {!checkRole(accessToken, "history", "bsr") &&
              checkRole(accessToken, "latest", "bsr") && (
                <Tbody>
                  {bsrHistory.length === 0 && (
                    <Tr>
                      <Td textAlign={"center"} colSpan={4}>
                        No BSR Entried Found
                      </Td>
                    </Tr>
                  )}
                  {bsrHistory.map((bsrEntry: bsrEntry, index: number) => {
                    if (index === 0) {
                      return (
                        <BsrListItem
                          key={bsrEntry._id}
                          bsrEntry={bsrEntry}
                          setBsrHistory={setBsrHistory}
                        />
                      );
                    } else {
                      return (
                        <BsrListItem
                          key={bsrEntry._id}
                          bsrEntry={bsrEntry}
                          setBsrHistory={setBsrHistory}
                          blurred={true}
                        />
                      );
                    }
                  })}
                </Tbody>
              )}
            <Tfoot>
              <Tr>
                <Th>Date</Th>
                <Th isNumeric>BSR</Th>
                <Th isNumeric>Actions</Th>
              </Tr>
            </Tfoot>
          </Table>
        </TableContainer>
        <HStack w="full" alignItems="flex-start" pt={2}>
          <Spacer />
          <Button
            onClick={() => {
              navigate("/bsr/entersingle", { replace: false });
            }}
          >
            Add Manual BSR
          </Button>
        </HStack>
      </VStack>
    );
  }

  return (
    <VStack w="full" alignItems="flex-start">
      <TableContainer w="full">
        <Table variant="simple" size="sm">
          <Thead>
            <Tr>
              <Th>Date</Th>
              <Th isNumeric>BSR (spm)</Th>
              <Th>type</Th>
              <Th isNumeric>Actions</Th>
            </Tr>
          </Thead>
          {checkRole(accessToken, "history", "bsr") && (
            <Tbody>
              {bsrHistory.length === 0 && (
                <Tr>
                  <Td textAlign={"center"} colSpan={4}>
                    No BSR Entried Found
                  </Td>
                </Tr>
              )}
              {bsrHistory.map((bsrEntry: bsrEntry) => {
                return (
                  <BsrListItem
                    key={bsrEntry._id}
                    bsrEntry={bsrEntry}
                    setBsrHistory={setBsrHistory}
                  />
                );
              })}
            </Tbody>
          )}
          {!checkRole(accessToken, "history", "bsr") &&
            checkRole(accessToken, "latest", "bsr") && (
              <Tbody>
                {bsrHistory.length === 0 && (
                  <Tr>
                    <Td textAlign={"center"} colSpan={4}>
                      No BSR Entried Found
                    </Td>
                  </Tr>
                )}
                {bsrHistory.map((bsrEntry: bsrEntry, index: number) => {
                  if (index === 0) {
                    return (
                      <BsrListItem
                        key={bsrEntry._id}
                        bsrEntry={bsrEntry}
                        setBsrHistory={setBsrHistory}
                      />
                    );
                  } else {
                    return (
                      <BsrListItem
                        key={bsrEntry._id}
                        bsrEntry={bsrEntry}
                        setBsrHistory={setBsrHistory}
                        blurred={true}
                      />
                    );
                  }
                })}
              </Tbody>
            )}
          <Tfoot>
            <Tr>
              <Th>Date</Th>
              <Th isNumeric>BSR (spm)</Th>
              <Th>type</Th>
              <Th isNumeric>Actions</Th>
            </Tr>
          </Tfoot>
        </Table>
      </TableContainer>
      <HStack w="full" alignItems="flex-start" pt={2}>
        <Spacer />
        <Button
          onClick={() => {
            navigate("/bsr/entersingle", { replace: false });
          }}
        >
          Add Manual BSR
        </Button>
      </HStack>
    </VStack>
  );
};

export { BsrHistory };
