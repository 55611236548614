import { configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/es/storage";
import thunk from "redux-thunk";
import { axiosMiddleware } from "../axiosSettings";
import activitiesForLinkingSlice from "../features/activity/activitiesForLinkingSlice";
import activitiesSlice from "../features/activity/activitiesSlice";
import activitySlice from "../features/activity/activitySlice";
import affiliateSlice from "../features/affiliate/affiliateSlice";
import authSlice from "../features/auth/authSlice";
import coachesSlice from "../features/coach/coachesSlice";
import invitationsSlice from "../features/invitations/invitationsSlice";
import areasSlice from "../features/library/areasSlice";
import countrySlice from "../features/location/countrySlice";
import locationSlice from "../features/location/locationSlice";
import relationshipsSlice from "../features/relationships/relationshipsSlice";
import sessionRouletteSlice from "../features/session/sessionRouletteSlice";
import sessionSlice from "../features/session/sessionSlice";
import sessionTypesSlice from "../features/session/sessionTypesSlice";
import sessionsSlice from "../features/session/sessionsSlice";
import virtualSquadSlice from "../features/session/virtualSquadSlice";
import wbssWeeksSlice from "../features/session/wbssWeeksSlice";
import fitnessSlice from "../features/training/fitnessSlice";
import trainingTimelineSlice from "../features/trainingTimeline/trainingTimelineSlice";
import userSlice from "../features/user/userSlice";

const persistedAuthSlice = persistReducer(
  {
    key: "authState",
    storage,
  },
  authSlice
);
const persistedUserSlice = persistReducer(
  {
    key: "userState",
    storage,
  },
  userSlice
);
const persistedActivitiesSlice = persistReducer(
  {
    key: "activitiesState",
    storage,
  },
  activitiesSlice
);
const persistedActivitiesForLinkingSlice = persistReducer(
  {
    key: "activitiesForLinkingState",
    storage,
  },
  activitiesForLinkingSlice
);
const persistedActivitySlice = persistReducer(
  {
    key: "activityState",
    storage,
  },
  activitySlice
);
const persistedSessionTypesSlice = persistReducer(
  {
    key: "sessionTypesState",
    storage,
  },
  sessionTypesSlice
);
const persistedSessionsSlice = persistReducer(
  {
    key: "sessionsState",
    storage,
  },
  sessionsSlice
);
const persistedWbssWeeksSlice = persistReducer(
  {
    key: "wbssWeeksState",
    storage,
  },
  wbssWeeksSlice
);
const persistedSessionSlice = persistReducer(
  {
    key: "sessionState",
    storage,
  },
  sessionSlice
);
const persistedSessionRouletteSlice = persistReducer(
  {
    key: "sessionRouletteState",
    storage,
  },
  sessionRouletteSlice
);
const persistedAreasSlice = persistReducer(
  {
    key: "areasState",
    storage,
  },
  areasSlice
);
const persistedTrainingTimelineSlice = persistReducer(
  {
    key: "trainingTimelineState",
    storage,
  },
  trainingTimelineSlice
);
const persistedLocationSlice = persistReducer(
  {
    key: "locationState",
    storage,
  },
  locationSlice
);
const persistedCountrySlice = persistReducer(
  {
    key: "countryState",
    storage,
  },
  countrySlice
);
const persistedCoachesSlice = persistReducer(
  {
    key: "coachesState",
    storage,
  },
  coachesSlice
);
const persistedVirtualSquadSlice = persistReducer(
  {
    key: "virtualSquadState",
    storage,
  },
  virtualSquadSlice
);
const persistedFitnessSlice = persistReducer(
  {
    key: "fitnessState",
    storage,
  },
  fitnessSlice
);
const persistedRelationshipsSlice = persistReducer(
  {
    key: "relationshipsState",
    storage,
  },
  relationshipsSlice
);
const persistedInvitationsSlice = persistReducer(
  {
    key: "invitationsState",
    storage,
  },
  invitationsSlice
);
const persistedAffiliateSlice = persistReducer(
  {
    key: "affiliateState",
    storage,
  },
  affiliateSlice
);

// Create the store, adding the slicea to it
export const store = configureStore({
  reducer: {
    authState: persistedAuthSlice,
    userState: persistedUserSlice,
    activitiesState: persistedActivitiesSlice,
    activitiesForLinkingState: persistedActivitiesForLinkingSlice,
    activityState: persistedActivitySlice,
    sessionTypesState: persistedSessionTypesSlice,
    sessionsState: persistedSessionsSlice,
    wbssWeeksState: persistedWbssWeeksSlice,
    sessionState: persistedSessionSlice,
    sessionRouletteState: persistedSessionRouletteSlice,
    areasState: persistedAreasSlice,
    trainingTimelineState: persistedTrainingTimelineSlice,
    locationState: persistedLocationSlice,
    countryState: persistedCountrySlice,
    coachesState: persistedCoachesSlice,
    virtualSquadState: persistedVirtualSquadSlice,
    fitnessState: persistedFitnessSlice,
    relationshipsState: persistedRelationshipsSlice,
    invitationsState: persistedInvitationsSlice,
    affiliateState: persistedAffiliateSlice,
  },
  devTools: process.env.NODE_ENV !== "production",
  middleware: [thunk, axiosMiddleware],
});
export const persistor = persistStore(store);

// Export some helper types used to improve type-checking
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
