import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

// Define the state of the slice as an object
export interface LocationState {
  latitude: number | null;
  longitude: number | null;
  lastUpdated: Date;
}

export interface LocationStateUpdate {
  latitude: number | null;
  longitude: number | null;
}

// Define an initial state
const initialState: LocationState = {
  latitude: null,
  longitude: null,
  lastUpdated: new Date("1970-01-01T00:00:00.000"),
};

// Create a slice containing the configuration of the state
// and the reducers functions
const locationSlice = createSlice({
  name: "locationState",
  initialState,
  reducers: {
    updateLocationState(state, action: PayloadAction<LocationStateUpdate | null>) {
      if (action.payload) {
        state.latitude = action.payload.latitude;
        state.longitude = action.payload.longitude;
        state.lastUpdated = new Date();
      } else {
        state.latitude = initialState.latitude;
        state.longitude = initialState.longitude;
        state.lastUpdated = initialState.lastUpdated;
      }
    },
  },
});

// Export each reducers function defined in createSlice
export const { updateLocationState } = locationSlice.actions;

export const selectLocationState = (state: RootState): LocationState => {
  return state.locationState;
};

// Export default the slice reducer
export default locationSlice.reducer;
