import { Center, Grid, GridItem, Heading, VStack } from "@chakra-ui/react";
import { useContext, useEffect } from "react";
import { useAppSelector } from "../../app/hooks";
import { BsrTweaker } from "../../components/bsr/BsrTweaker";
import { YourCoach } from "../../components/coaches/YourCoach";
import {
  BreadcrumbContext,
  breadcrumbContextType,
} from "../../components/context/BreadcrumbContext";
import { CssTweaker } from "../../components/css/CssTweaker";
import { SessionRoulette } from "../../components/sessions/SessionRoulette";
import { FitnessAndFatigue } from "../../components/training/FitnessAndFatigue";
import { TrainingBalance } from "../../components/training/TrainingBalance";
import { TrainingSummary } from "../../components/training/TrainingSummary";
import { BoxWrapper } from "../../components/ui/BoxWrapper";
import Loading from "../../components/ui/Loading";
import { selectAuthState } from "../../features/auth/authSlice";
import { selectUserState } from "../../features/user/userSlice";
import { checkRole } from "../../utils/authHelper";
import { CssBsrSummary } from "../training/CssBsrSummary";
import { WorldsBiggestSwimSquadWidget } from "../wbss/WorldsBiggestSwimSquadWidget";

const Routine = () => {
  const { access: accessToken } = useAppSelector(selectAuthState);
  const { setBreadcrumbLinks } =
    useContext<breadcrumbContextType>(BreadcrumbContext);
  const { user } = useAppSelector(selectUserState);

  useEffect(() => {
    const breadcrumbLinks = [{ href: "/progress", title: "Progress" }];
    setBreadcrumbLinks(breadcrumbLinks);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!user) {
    return <Loading message="Loading User" />;
  }

  return (
    <Grid
      templateColumns={[
        "repeat(1, 1fr)",
        "repeat(1, 1fr)",
        "repeat(6, 1fr)",
        "repeat(6, 1fr)",
      ]}
      gap={2}
    >
      <GridItem colSpan={[1, 1, 3, 3]}>
        <TrainingBalance />
      </GridItem>
      <GridItem colSpan={[1, 1, 3, 3]}>
        <VStack>
          <CssBsrSummary />
          <TrainingSummary />
        </VStack>
      </GridItem>
      <GridItem colSpan={[1, 1, 2, 2]}>
        <SessionRoulette />
      </GridItem>
      <GridItem colSpan={[1, 1, 2, 2]}>
        <YourCoach />
      </GridItem>
      <GridItem colSpan={[1, 1, 2, 2]}>
        <WorldsBiggestSwimSquadWidget />
      </GridItem>
      <GridItem colSpan={[1, 1, 3, 3]}>
        <BoxWrapper>
          <Center>
            <VStack>
              <Heading mb={1} size="md">
                The (Mini) CSS Tweaker
              </Heading>
              <CssTweaker small />
            </VStack>
          </Center>
        </BoxWrapper>
      </GridItem>
      <GridItem colSpan={[1, 1, 3, 3]}>
        <BoxWrapper>
          <Center>
            <VStack>
              <Heading mb={1} size="md">
                The (Mini) BSR Tweaker
              </Heading>
              <BsrTweaker small />
            </VStack>
          </Center>
        </BoxWrapper>
      </GridItem>
      <GridItem colSpan={[1, 1, 6, 6]}>
        <FitnessAndFatigue
          subscriber={checkRole(accessToken, "read", "fitnessAndFatigue")}
        />
      </GridItem>
    </Grid>
  );
};

export { Routine };
