import {
  Box,
  BoxProps,
  Button,
  Center,
  HStack,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getBsrCurrent, tweakBsr } from "../../DataAccess/bsr";
import { useAppSelector } from "../../app/hooks";
import { selectAuthState } from "../../features/auth/authSlice";
import { selectUserState } from "../../features/user/userSlice";
import { bsrEntry } from "../../types/bsr";
import { user } from "../../types/user";
import { checkRole } from "../../utils/authHelper";
import { NotAuthorised } from "../generic/NotAuthorised";
import { CustomModal } from "../ui/CustomModal";
import Loading from "../ui/Loading";
import { SingleValueDisplay } from "../ui/SingleValueDisplay";
import { SingleValueWithUnitDisplay } from "../ui/SingleValueWithUnitDisplay";

interface BsrTweakerProps extends BoxProps {
  small?: boolean;
}

const BsrTweaker: React.FC<BsrTweakerProps> = ({ small }) => {
  const { access: accessToken } = useAppSelector(selectAuthState);
  const { user } = useAppSelector(selectUserState);
  const [bsrEntry, setBsrEntry] = useState<bsrEntry | null>();

  const [hamburgerMode] = useMediaQuery("(max-width: 768px)");
  const navigate = useNavigate();

  useEffect(() => {
    const getBsrCurrentLocal = async () => {
      setBsrEntry(await getBsrCurrent());
    };
    getBsrCurrentLocal();
  }, []);

  interface tweakLevel {
    name: string;
    shortName: string;
    value: number;
    colour: string;
    width: number;
  }

  const improvements: tweakLevel[] = [
    {
      name: "Slower",
      shortName: "-",
      value: -1,
      colour: "darkseagreen",
      width: 110,
    },
    {
      name: "Sloower",
      shortName: "--",
      value: -2,
      colour: "lightgreen",
      width: 130,
    },
    {
      name: "Sloooower",
      shortName: "---",
      value: -3,
      colour: "lawngreen",
      width: 170,
    },
  ];

  const regressions: tweakLevel[] = [
    {
      name: "Faaaaster",
      shortName: "+++",
      value: 3,
      colour: "lawngreen",
      width: 170,
    },
    {
      name: "Faaaster",
      shortName: "++",
      value: 2,
      colour: "lightgreen",
      width: 130,
    },
    {
      name: "Faster",
      shortName: "+",
      value: 1,
      colour: "darkseagreen",
      width: 110,
    },
  ];

  if (!user) {
    return <Loading message="Loading user failed." />;
  }

  if (!checkRole(accessToken, "tweak", "bsr")) {
    return (
      <NotAuthorised functionText={"Full BSR Tools & History"} size={"full"} />
    );
  }

  if (!bsrEntry) {
    return (
      <>
        <Text>
          You need to enter a base stroke rate before you can tweak it!
        </Text>
        <Button
          onClick={() => navigate("/bsr/entersingle", { replace: false })}
        >
          Enter BSR
        </Button>
      </>
    );
  }

  if (hamburgerMode || small) {
    return (
      <Center>
        <HStack>
          {regressions.map((r) => {
            return (
              <BsrTweakerModal
                key={r.shortName}
                user={user}
                bsrEntry={bsrEntry}
                amount={r.value}
                label={r.shortName}
                name={r.name}
                colour={r.colour}
                w={8}
                setBsrEntry={setBsrEntry}
              />
            );
          })}
          <SingleValueDisplay
            label="spm"
            value={bsrEntry ? bsrEntry.spm : "Not Set"}
          />
          {improvements.map((r) => {
            return (
              <BsrTweakerModal
                key={r.shortName}
                user={user}
                bsrEntry={bsrEntry}
                amount={r.value}
                label={r.shortName}
                name={r.name}
                colour={r.colour}
                w={8}
                setBsrEntry={setBsrEntry}
              />
            );
          })}
        </HStack>
      </Center>
    );
  }

  return (
    <Center>
      <HStack mt={2}>
        {regressions.map((r) => {
          return (
            <BsrTweakerModal
              key={r.shortName}
              user={user}
              bsrEntry={bsrEntry}
              amount={r.value}
              label={r.name}
              name={r.name}
              colour={r.colour}
              w={r.width}
              setBsrEntry={setBsrEntry}
            />
          );
        })}
        <Box px={5}>
          <SingleValueWithUnitDisplay
            label="BSR Pace"
            unit={"spm"}
            value={bsrEntry ? bsrEntry.spm : "Not Set"}
          />
        </Box>
        {improvements.map((r) => {
          return (
            <BsrTweakerModal
              key={r.shortName}
              user={user}
              bsrEntry={bsrEntry}
              amount={r.value}
              label={r.name}
              name={r.name}
              colour={r.colour}
              w={r.width}
              setBsrEntry={setBsrEntry}
            />
          );
        })}
      </HStack>
    </Center>
  );
};

interface BsrTweakerModalProps extends BoxProps {
  user: user;
  bsrEntry: bsrEntry;
  amount: number;
  label: string;
  name: string;
  colour: string;
  setBsrEntry: any;
}

const BsrTweakerModal: React.FC<BsrTweakerModalProps> = ({
  user,
  bsrEntry,
  amount,
  label,
  name,
  colour,
  setBsrEntry,
  ...rest
}) => {
  const current = bsrEntry.spm;
  const tweaked = current + amount;
  return (
    <CustomModal
      showButtonText={label}
      showButtonColour={colour}
      confirmButtonText="Tweak It!"
      cancelButtonText="No, thanks"
      header={"Tweak BSR (" + name + ")"}
      confirmFunction={async () => {
        await tweakBsr(tweaked);
        setBsrEntry(await getBsrCurrent());
      }}
      {...rest}
    >
      Are you sure you want to tweak your base stroke rate (BSR) from {current}
      spm to {tweaked}spm
    </CustomModal>
  );
};

export { BsrTweaker };
