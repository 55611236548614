import {
  Box,
  Button,
  Heading,
  HStack,
  InputGroup,
  InputRightElement,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { createBsrManual, getBsrCurrent } from "../../DataAccess/bsr";
import { getUserById } from "../../DataAccess/users";
import { selectAuthState } from "../../features/auth/authSlice";
import {
  selectUserState,
  updateUserState,
} from "../../features/user/userSlice";
import { bsrEntry } from "../../types/bsr";
import { checkRole } from "../../utils/authHelper";
import { createToast } from "../../utils/toastHelper";
import { NotAuthorised } from "../generic/NotAuthorised";
import { CustomToast } from "../ui/CustomToast";

const BsrManualCapture = (props: any) => {
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const { access: accessToken } = useAppSelector(selectAuthState);
  const { user } = useAppSelector(selectUserState);
  const [bsrEntry, setBsrEntry] = useState<bsrEntry | null>();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const toast = useToast();

  useEffect(() => {
    const getBsrCurrentLocal = async () => {
      setBsrEntry(await getBsrCurrent());
    };
    getBsrCurrentLocal();
  }, []);

  const {
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      value: 0,
    },
  });

  useEffect(() => {
    if (bsrEntry && bsrEntry.spm) {
      setValue("value", bsrEntry.spm);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const saveBsr = async (data: any) => {
    setIsSaving(true);
    await createBsrManual(data.value);
    if (user) {
      const userResponse = await getUserById(user._id);
      if (userResponse) {
        dispatch(updateUserState(userResponse));
      }
    }
    createToast(toast, (props: any) => {
      return (
        <CustomToast
          title={"Create BSR entry"}
          status={"Success"}
          toast={toast}
          toastId={props.id}
        >
          <Text>BSR entry created.</Text>
        </CustomToast>
      );
    });
    navigate("/bsr/history", { replace: false });
    setIsSaving(false);
  };

  if (!checkRole(accessToken, "manual", "bsr")) {
    return <NotAuthorised functionText={"Manual BSR Entry"} size={"full"} />;
  }

  return (
    <VStack
      as="form"
      onSubmit={handleSubmit(saveBsr)}
      p={0}
      spacing={3}
      align="left"
      width="full"
    >
      <VStack p={0} spacing={0} align="left">
        <Heading as="h3" size="md" pt={5} pb={2}>
          Your Base Stroke Rate
        </Heading>
        <Text pb={4}>
          Perform a 400m time trial as part of a CSS test and get a friend or
          coach to count the number of strokes you take (counting left arm and
          right arm) for 15 seconds in the 3rd 100m of the 400m time trial.
          Multiply this number by 4 and use this as your BSR. Failing this, if
          you're using a wearable and the Swim Smooth app on an Apple Watch when
          you're swimming a harder session, take note of your average stroke
          rate in an interval of 200 to 500m and use this figure instead.
        </Text>
        <Text>
          If you're using a Garmin, remember to double the stroke rate value the
          watch gives you as Garmin monitors stroke cycles per minute (i.e. left
          arm and right arm = 1) as opposed to strokes per minute.
        </Text>
        <VStack p={0} spacing={0}>
          <Controller
            control={control}
            name="value"
            rules={{ required: true }}
            render={({ field: { ref, ...restField } }) => (
              <InputGroup size="md">
                <InputRightElement color="gray" mr={8}>
                  spm
                </InputRightElement>
                <NumberInput {...restField} min={20} max={160} width="100%">
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
              </InputGroup>
            )}
          />
        </VStack>
      </VStack>
      <HStack width="full" align="flex-start">
        <Box width="full">&nbsp;</Box>
        <Button
          variant="success"
          colorScheme={props.colorScheme}
          px={10}
          isLoading={isSubmitting || isSaving}
          type="submit"
        >
          Save BSR as Current
        </Button>
      </HStack>
    </VStack>
  );
};

export { BsrManualCapture };
