import axios from "axios";
import { processSwimSmoothApiError } from "../components/generic/ErrorProcessing";
import {
  activity,
  activityAnalysis,
  createManualActivity,
  updateManualActivity,
  updateWearableActivity,
} from "../types/activity";

interface activitiesResponse {
  activities: activity[];
  last: string;
}

const createActivityManual = async (
  activity: createManualActivity
): Promise<activity> => {
  try {
    let url = process.env.REACT_APP_API_ROOT_URL + "activities/manual";
    const result = await axios.post(url, activity);
    return result.data.payload;
  } catch (error: any) {
    throw new Error(processSwimSmoothApiError(error.response.data));
  }
};

const getActivities = async (last?: string): Promise<activitiesResponse> => {
  try {
    let url = process.env.REACT_APP_API_ROOT_URL + "activities/";
    if (last) {
      url += "?last=" + last;
    }
    const result = await axios.get(url);
    if (result.data.success) {
      return {
        activities: result.data.payload,
        last: result.data.last,
      };
    }
  } catch (error: any) {
    throw new Error(processSwimSmoothApiError(error.response.data));
  }
  return {
    activities: [],
    last: "",
  };
};

const getActivitiesForLinking = async (
  last?: string
): Promise<activitiesResponse> => {
  try {
    let url = process.env.REACT_APP_API_ROOT_URL + "activities/?mode=linking";
    if (last) {
      url += "&last=" + last;
    }
    const result = await axios.get(url);
    if (result.data.success) {
      return {
        activities: result.data.payload,
        last: result.data.last,
      };
    }
  } catch (error: any) {
    throw new Error(processSwimSmoothApiError(error.response.data));
  }
  return {
    activities: [],
    last: "",
  };
};

const getActivityById = async (
  activityId: string
): Promise<activity | null> => {
  try {
    const result = await axios.get(
      process.env.REACT_APP_API_ROOT_URL + "activities/id/" + activityId
    );
    if (result.data.success) {
      return result.data.payload;
    }
  } catch (error: any) {
    throw new Error(processSwimSmoothApiError(error.response.data));
  }
  return null;
};

const getActivityAnalysisById = async (
  activityId: string
): Promise<activityAnalysis | null> => {
  try {
    const result = await axios.get(
      process.env.REACT_APP_API_ROOT_URL + "activities/analysis/" + activityId
    );
    if (result.data.success) {
      return result.data.payload;
    }
  } catch (error: any) {
    throw new Error(processSwimSmoothApiError(error.response.data));
  }
  return null;
};

const getActivityAnalysisByLast = async (): Promise<activityAnalysis> => {
  try {
    const result = await axios.get(
      process.env.REACT_APP_API_ROOT_URL + "activities/analysis"
    );
    return result.data.payload;
  } catch (error: any) {
    throw new Error(processSwimSmoothApiError(error.response.data));
  }
};

const getActivityAnalysisByUser = async (): Promise<activityAnalysis> => {
  try {
    const result = await axios.get(
      process.env.REACT_APP_API_ROOT_URL + "activities/analysis/user"
    );
    return result.data.payload;
  } catch (error: any) {
    throw new Error(processSwimSmoothApiError(error.response.data));
  }
};

const updateActivityManual = async (
  activityId: string,
  activity: updateManualActivity
): Promise<activity> => {
  try {
    const updateResponse = await axios.patch(
      process.env.REACT_APP_API_ROOT_URL + "activities/" + activityId,
      activity
    );
    return updateResponse.data.payload;
  } catch (error: any) {
    throw new Error(processSwimSmoothApiError(error.response.data));
  }
};

const updateActivityWearable = async (
  activityId: string,
  activity: updateWearableActivity
): Promise<activity> => {
  try {
    const updateResponse = await axios.patch(
      process.env.REACT_APP_API_ROOT_URL + "activities/" + activityId,
      activity
    );
    return updateResponse.data.payload;
  } catch (error: any) {
    throw new Error(processSwimSmoothApiError(error.response.data));
  }
};

const deleteActivityById = async (activityId: string): Promise<void> => {
  try {
    const result = await axios.delete(
      process.env.REACT_APP_API_ROOT_URL + "activities/" + activityId
    );
    if (result.data.success) {
      return;
    }
  } catch (error: any) {
    throw new Error(processSwimSmoothApiError(error.response.data));
  }
  return;
};

export {
  createActivityManual,
  deleteActivityById,
  getActivities,
  getActivitiesForLinking,
  getActivityAnalysisById,
  getActivityAnalysisByLast,
  getActivityAnalysisByUser,
  getActivityById,
  updateActivityManual,
  updateActivityWearable,
};
