import axios from "axios";
import { processSwimSmoothApiError } from "../components/generic/ErrorProcessing";
import { fitnessSummary } from "../types/fitness";
import { trainingResponse } from "../types/training";

const getFitness = async (): Promise<fitnessSummary> => {
  try {
    let url = process.env.REACT_APP_API_ROOT_URL + "training/fitness";
    const result = await axios.get(url);
    return result.data.payload;
  } catch (error: any) {
    throw new Error(processSwimSmoothApiError(error.response.data));
  }
};

const getTraining = async (): Promise<trainingResponse> => {
  try {
    let url = process.env.REACT_APP_API_ROOT_URL + "training/summary";
    const result = await axios.get(url);
    if (result.status === 202) {
      return {
        generating: true,
        notModified: false,
      };
    } else if (result.status === 304) {
      return {
        generating: false,
        notModified: true,
      };
    }
    return {
      generating: false,
      notModified: false,
      data: result.data.payload,
    };
  } catch (error: any) {
    throw new Error(processSwimSmoothApiError(error.response.data));
  }
};

export { getFitness, getTraining };
