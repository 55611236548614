import {
  BoxProps,
  Button,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  Text,
  useMediaQuery,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { BiRightArrowCircle } from "react-icons/bi";
import { useAppSelector } from "../../app/hooks";
import { selectAuthState } from "../../features/auth/authSlice";
import {
  activityAnalysis,
  activityAnalysisSwimtelligenceInsightGroup,
} from "../../types/activity";
import { checkRole } from "../../utils/authHelper";
import { dp } from "../../utils/coreHelper";
import { NotAuthorised } from "../generic/NotAuthorised";
import Loading from "../ui/Loading";
import Score from "../ui/Score";
import { StrokeInsight } from "./StrokeInsight";
import { StrokeInsightGroupExtra } from "./StrokeInsightExtra";

interface StrokeInsightsProps extends BoxProps {
  loading: boolean;
  activityAnalysis: activityAnalysis | null;
}

const StrokeInsights: React.FC<StrokeInsightsProps> = ({
  loading,
  activityAnalysis,
}) => {
  const { access: accessToken } = useAppSelector(selectAuthState);
  const [displayedInsightGroup, setDisplayedInsightGroup] =
    useState<activityAnalysisSwimtelligenceInsightGroup | null>(null);
  const [hamburgerMode] = useMediaQuery("(max-width: 768px)");

  useEffect(() => {
    if (hamburgerMode) {
      setDisplayedInsightGroup(null);
    } else if (activityAnalysis) {
      setDisplayedInsightGroup(
        activityAnalysis.swimtelligence.insightGroups[0]
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activityAnalysis, hamburgerMode]);

  if (!activityAnalysis) {
    return null;
  }

  if (!checkRole(accessToken, "read", "insights")) {
    return <NotAuthorised functionText={"Stroke Insights"} size={"full"} />;
  }

  if (hamburgerMode) {
    if (displayedInsightGroup) {
      return (
        <VStack w="full">
          <Button onClick={() => setDisplayedInsightGroup(null)}>
            &lt;-- All Insights
          </Button>
          <StrokeInsight side="left" insightGroup={displayedInsightGroup} />
          <StrokeInsight side="right" insightGroup={displayedInsightGroup} />
          <StrokeInsightGroupExtra
            displayedInsightGroup={displayedInsightGroup}
          />
        </VStack>
      );
    }

    return (
      <VStack w="full">
        {activityAnalysis.swimtelligence.insightGroups.map((insightGroup) => {
          if (!insightGroup.has.insight) {
            return null;
          }
          return (
            <HStack w="full" bg="#111111" borderRadius={8} pr={2}>
              <Grid
                key={insightGroup.id}
                w="full"
                templateColumns="repeat(2, 1fr)"
                gap={0}
                p={2}
                m={0}
                cursor="pointer"
                onClick={() => {
                  setDisplayedInsightGroup(insightGroup);
                }}
              >
                <GridItem colSpan={2}>
                  <Heading size="small" as="h5" color="#AFAFAF">
                    {insightGroup.title.list}
                  </Heading>
                </GridItem>
                <GridItem>
                  <Text fontSize="xs" color="#AFAFAF">
                    Left
                  </Text>
                  <Score
                    score={
                      insightGroup.left.hasData
                        ? dp(insightGroup.left.score, 2)
                        : "-"
                    }
                    max={5}
                  />
                </GridItem>
                <GridItem>
                  <Text fontSize="xs" color="#AFAFAF">
                    Right
                  </Text>
                  <Score
                    score={
                      insightGroup.right.hasData
                        ? dp(insightGroup.right.score, 2)
                        : "-"
                    }
                    max={5}
                  />
                </GridItem>
              </Grid>
              <Icon as={BiRightArrowCircle} w={25} h={25} color="grey" />
            </HStack>
          );
        })}
      </VStack>
    );
  }

  if (!displayedInsightGroup) {
    return <Loading message="Loading activity analysis" />;
  }

  return (
    <VStack w="full">
      <VStack w="full">
        <Grid templateColumns="repeat(6, 1fr)" gap={2} w="full">
          {activityAnalysis.swimtelligence.insightGroups.map((insightGroup) => {
            if (!insightGroup.has.insight) {
              return null;
            }
            const opacity =
              insightGroup.id === displayedInsightGroup.id ? 1 : 0.6;
            return (
              <Grid
                key={insightGroup.id}
                templateColumns="repeat(2, 1fr)"
                gap={2}
                mb={1}
                py={2}
                px={2}
                opacity={opacity}
                w="auto"
                cursor="pointer"
                onClick={() => {
                  setDisplayedInsightGroup(insightGroup);
                }}
                bg="#111111"
                borderRadius={10}
              >
                <GridItem colSpan={2}>
                  <Heading size="small" as="h5" color="#AFAFAF">
                    {insightGroup.title.list}
                  </Heading>
                </GridItem>
                <GridItem>
                  <Text fontSize="xs" color="#AFAFAF">
                    Left
                  </Text>
                  <Score
                    score={
                      insightGroup.left.hasData
                        ? dp(insightGroup.left.score, 2)
                        : "-"
                    }
                    max={5}
                  />
                </GridItem>
                <GridItem>
                  <Text fontSize="xs" color="#AFAFAF">
                    Right
                  </Text>
                  <Score
                    score={
                      insightGroup.right.hasData
                        ? dp(insightGroup.right.score, 2)
                        : "-"
                    }
                    max={5}
                  />
                </GridItem>
              </Grid>
            );
          })}
        </Grid>
        <HStack w="full">
          <StrokeInsight side="left" insightGroup={displayedInsightGroup} />
          <StrokeInsight side="right" insightGroup={displayedInsightGroup} />
        </HStack>
      </VStack>
      <StrokeInsightGroupExtra displayedInsightGroup={displayedInsightGroup} />
    </VStack>
  );
};

export { StrokeInsights };
