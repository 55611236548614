import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { relationship } from "../../types/relationship";

// Define the state of the slice as an object
export interface RelationshipsState {
  lastUpdated: Date;
  relationships: relationship[];
}

// Define an initial state
const initialState: RelationshipsState = {
  lastUpdated: new Date("1970-01-01T00:00:00.000"),
  relationships: [],
};

// Create a slice containing the configuration of the state
// and the reducers functions
const relationshipsSlice = createSlice({
  name: "relationshipsState",
  initialState,
  reducers: {
    updateRelationshipsState(
      state,
      action: PayloadAction<relationship[] | null>
    ) {
      if (action.payload) {
        state.lastUpdated = new Date();
        state.relationships = action.payload;
      } else {
        state.lastUpdated = initialState.lastUpdated;
        state.relationships = [];
      }
    },
  },
});

// Export each reducers function defined in createSlice
export const updateRelationshipsState =
  relationshipsSlice.actions.updateRelationshipsState;

export const selectRelationshipsState = (
  state: RootState
): RelationshipsState => {
  return state.relationshipsState;
};

// Export default the slice reducer
export default relationshipsSlice.reducer;
