import { Box, Center, Heading, VStack } from "@chakra-ui/react";
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  activityAnalysisSwimtelligenceInsightGroupSection,
  activityAnalysisSwimtelligenceInsightGroupSectionDataVideos,
} from "../../types/activity";
import Vimeo from "../ui/Vimeo";

interface StrokeInsightCarouselProps {
  videos: activityAnalysisSwimtelligenceInsightGroupSection[];
}

const StrokeInsightCarousel: React.FC<StrokeInsightCarouselProps> = ({
  videos,
}) => {
  return (
    <Box pt={5} w="full">
      <Swiper
        autoHeight={true}
        pagination={{
          type: "bullets",
        }}
        navigation={true}
        modules={[Pagination, Navigation]}
        className="setSwiper"
      >
        {videos.map(
          (video: activityAnalysisSwimtelligenceInsightGroupSection) => {
            const data: activityAnalysisSwimtelligenceInsightGroupSectionDataVideos =
              video.data as activityAnalysisSwimtelligenceInsightGroupSectionDataVideos;
            return data.videos.map((video, pageIndex) => {
              return (
                <SwiperSlide key={pageIndex}>
                  <Box mx={50} pb={35}>
                    <Center>
                      <VStack>
                        <Heading as="h5" size="xs" mb={2} noOfLines={1}>
                          {video.title}
                        </Heading>
                        <Box>
                          <Vimeo video={video} />
                        </Box>
                      </VStack>
                    </Center>
                  </Box>
                </SwiperSlide>
              );
            });
          }
        )}
      </Swiper>
    </Box>
  );
};

export { StrokeInsightCarousel };
