import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { user } from '../../types/user';

// Define the state of the slice as an object
export interface UserState {
  lastUpdated: Date;
  user: user | null;
}

// Define an initial state
const initialState: UserState = {
  lastUpdated: new Date('1970-01-01T00:00:00.000'),
  user: null,
};

// Create a slice containing the configuration of the state
// and the reducers functions
const userSlice = createSlice({
  name: 'userState',
  initialState,
  reducers: {
    updateUserState(state, action: PayloadAction<user | null>) {
      if (action.payload) {
        state.lastUpdated = new Date();
        state.user = action.payload;
      } else {
        state.lastUpdated = initialState.lastUpdated;
        state.user = initialState.user;
      }
    },
  },
});

// Export each reducers function defined in createSlice
export const { updateUserState } = userSlice.actions;

export const selectUserState = (state: RootState): UserState => {
  return state.userState;
};

// Export default the slice reducer
export default userSlice.reducer;
