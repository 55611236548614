import axios from "axios";
import { refresh } from "./DataAccess/oauth";
import { updateAccessToken } from "./features/auth/authSlice";

const excludedUrls = [
  process.env.REACT_APP_API_ROOT_URL + "oauth/token",
  process.env.REACT_APP_API_ROOT_URL + "oauth/refresh",
];

export const axiosMiddleware = (store: any) => (next: any) => (action: any) => {
  setInterceptors(store);
  return next(action);
};

export const setInterceptors = (store: any) => {
  if (!store) {
    return;
  }
  const { dispatch } = store;
  // Add a request interceptor
  axios.interceptors.request.use(function (config) {
    // Must have a url, it must not be excluded and it must be to our API
    if (
      config.url &&
      config.url.indexOf(process.env.REACT_APP_API_ROOT_URL as string) !== -1 &&
      !excludedUrls.includes(config.url)
    ) {
      const { authState } = store.getState();
      let accessToken = authState.access;
      if (accessToken) {
        config.headers.Authorization = accessToken;
      }
    }

    // Always add client id and secret headers if we are talking to our API
    if (
      config.url &&
      config.url.indexOf(process.env.REACT_APP_API_ROOT_URL as string) !== -1
    ) {
      config.headers["ss-client"] = process.env.REACT_APP_CLIENT_ID;
      config.headers["ss-client-secret"] = process.env.REACT_APP_CLIENT_SECRET;
    }

    return config;
  });

  // Response interceptor for API calls
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    async function (error) {
      const originalRequest = error.config;

      if (originalRequest.url && !excludedUrls.includes(originalRequest.url)) {
        if (error.response.status === 401 && !originalRequest._retry) {
          const { authState } = store.getState();
          let refreshToken = authState.refresh;
          if (refreshToken) {
            const refreshResult = await refresh(refreshToken);
            // Write tokens and user state
            dispatch(updateAccessToken(refreshResult.data.access_token));
            // Success - retry
            if (originalRequest.headers) {
              originalRequest.headers.Authorization =
                refreshResult.data.access_token;
            } else {
              originalRequest.headers = {
                Authorization: `Bearer ${refreshResult.data.access_token}`,
              };
            }
            originalRequest._retry = true;
            return axios(originalRequest);
          }
        }
      }
      return Promise.reject(error);
    }
  );
};
