import axios from "axios";
import { processSwimSmoothApiError } from "../components/generic/ErrorProcessing";
import { invitation } from "../types/invitation";

const getInvitationById = async (
  invitationid: string
): Promise<invitation | null> => {
  try {
    const result = await axios.get(
      process.env.REACT_APP_API_ROOT_URL + "invitations/id/" + invitationid
    );
    if (result.data.success) {
      return result.data.payload;
    }
  } catch (error: any) {
    throw new Error(processSwimSmoothApiError(error.response.data));
  }
  return null;
};

const getInvitationsForUser = async (userId: string): Promise<invitation[]> => {
  try {
    let url = process.env.REACT_APP_API_ROOT_URL + "invitations/user/" + userId;
    const invitationsResponse = await axios.get(url);
    return invitationsResponse.data.payload;
  } catch (error: any) {
    if (error.response) {
      throw new Error(processSwimSmoothApiError(error.response.data));
    } else {
      throw error;
    }
  }
};

const acceptInvitation = async (invitationId: string): Promise<void> => {
  try {
    let url =
      process.env.REACT_APP_API_ROOT_URL + "invitations/accept/" + invitationId;
    await axios.patch(url);
    return;
  } catch (error: any) {
    if (error.response) {
      throw new Error(processSwimSmoothApiError(error.response.data));
    } else {
      throw error;
    }
  }
};

const deleteInvitation = async (invitationId: string): Promise<void> => {
  try {
    let url =
      process.env.REACT_APP_API_ROOT_URL + "invitations/" + invitationId;
    await axios.delete(url);
    return;
  } catch (error: any) {
    if (error.response) {
      throw new Error(processSwimSmoothApiError(error.response.data));
    } else {
      throw error;
    }
  }
};

export {
  acceptInvitation,
  deleteInvitation,
  getInvitationById,
  getInvitationsForUser,
};
