import {
  Box,
  Center,
  Container,
  Flex,
  useColorModeValue,
  useMediaQuery,
} from "@chakra-ui/react";
import { useState } from "react";
import { breadcrumbLink } from "../../types/breadcrumb";
import { BreadcrumbContext } from "../context/BreadcrumbContext";
import SwimSmoothBreadcrumb from "../ui/SwimSmoothBreadcrumb";
import { Footer } from "./Footer";
import { HeaderHamburger } from "./HeaderHamburger";
import { HeaderMain } from "./HeaderMain";

const BaseLayout = (props: any) => {
  const [breadcrumbLinks, setBreadcrumbLinks] = useState<
    breadcrumbLink[] | null
  >([]);
  const [hamburgerMode] = useMediaQuery("(max-width: 768px)");

  if (hamburgerMode) {
    return (
      <BreadcrumbContext.Provider
        value={{
          breadcrumbLinks,
          setBreadcrumbLinks,
        }}
      >
        <VersionBanner />
        <TopBanner />
        <Container maxW={"95%"} w="95%" px={0}>
          <HeaderHamburger />
          <SwimSmoothBreadcrumb links={breadcrumbLinks} />
        </Container>
        <Container maxW={"95%"} w="95%" px={0}>
          <Flex pt={5} pb={[5, 5, 16, 16]}>
            {props.children}
          </Flex>
        </Container>
        <Footer />
      </BreadcrumbContext.Provider>
    );
  }

  return (
    <BreadcrumbContext.Provider
      value={{
        breadcrumbLinks,
        setBreadcrumbLinks,
      }}
    >
      <VersionBanner />
      <TopBanner />
      <Container
        maxW={["container.sm", "container.md", "container.lg", "container.xl"]}
        w="full"
        px={0}
      >
        <HeaderMain />
        <SwimSmoothBreadcrumb links={breadcrumbLinks} />
      </Container>
      <Container
        maxW={["container.sm", "container.md", "container.lg", "container.xl"]}
        w="full"
        px={0}
      >
        <Flex pt={5} pb={[5, 5, 16, 16]}>
          {props.children}
        </Flex>
      </Container>
      <Footer />
    </BreadcrumbContext.Provider>
  );
};

const VersionBanner = () => {
  const bannerColour = useColorModeValue("orange.400", "orange.700");
  if (process.env.REACT_APP_ENV !== "live") {
    return (
      <Box bg={bannerColour} w="full" py={2}>
        <Center>
          {process.env.REACT_APP_ENV?.toUpperCase()}: Stripe & Levels Version
        </Center>
      </Box>
    );
  }
  return null;
};

const TopBanner = () => {
  // const bannerColour = useColorModeValue("red.400", "red.700");
  // const navigate = useNavigate();
  // return (
  //   <VStack bg={bannerColour} w="full" py={2}>
  //     <Text>
  //       We are aware of a few issues still remainining after our upgrade
  //       including issues with completing sessions, linking sessions and
  //       incorrect display of subscription details.
  //     </Text>
  //     <Text>
  //       If you don't have the expected access please{" "}
  //       <Button
  //         variant={"link"}
  //         onClick={() => {
  //           navigate("/logout", { replace: false });
  //         }}
  //       >
  //         logout
  //       </Button>{" "}
  //       and back in again before reporting. Sorry for any inconvenience caused.
  //     </Text>
  //   </VStack>
  // );
  return null;
};

export { BaseLayout };
