import {
  Button,
  Center,
  Grid,
  GridItem,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { YourCoach } from "../../components/coaches/YourCoach";
import { BoxWrapper } from "../../components/ui/BoxWrapper";
import Loading from "../../components/ui/Loading";
import { selectUserState } from "../../features/user/userSlice";
import { TrialDetails } from "../account/subscription/TrialDetails";

const Guidance = () => {
  const { user } = useAppSelector(selectUserState);
  const navigate = useNavigate();

  if (!user) {
    return <Loading message="Loading User" />;
  }

  return (
    <Grid
      templateColumns={[
        "repeat(1, 1fr)",
        "repeat(1, 1fr)",
        "repeat(6, 1fr)",
        "repeat(6, 1fr)",
      ]}
      gap={2}
    >
      <GridItem colSpan={[1, 1, 2, 2]}>
        <YourCoach />
      </GridItem>
      <GridItem colSpan={[1, 1, 4, 4]}>
        <BoxWrapper>
          <VStack w={"full"} alignItems={"flex-start"} mb={2}>
            <Text>
              You are currently using our free forever 'Guidance' level.
              Upgrading to to one of our paid plans will give you access to lots
              of useful training tools, our full session and content library,
              training plans, stroke insights and much, much more.
            </Text>
            <TrialDetails trial={user.data.billing.trial} />
          </VStack>
          <Center>
            <Button
              mt={6}
              mb={10}
              size={"lg"}
              onClick={() => {
                navigate("/account/subscription/subscribe/", {
                  replace: false,
                });
              }}
            >
              Upgrade Now
            </Button>
          </Center>
          <Image src="https://images.ctfassets.net/50b15ahactsg/2CSeNxBKfFtFSPKetRCsny/ed10b018c13a4ecda95700ca6a37710b/RoutineProgressScree.png" />
        </BoxWrapper>
      </GridItem>
    </Grid>
  );
};

export { Guidance };
