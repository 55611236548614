import {
  Box,
  BoxProps,
  Center,
  Heading,
  Image,
  Text,
  useMediaQuery,
  VStack,
} from "@chakra-ui/react";
import { activityAnalysisSwimtelligenceInsightGroup } from "../../types/activity";
import FilledBar from "../ui/FilledBar";

interface StrokeInsightProps extends BoxProps {
  side: string;
  insightGroup: activityAnalysisSwimtelligenceInsightGroup;
}

const StrokeInsight: React.FC<StrokeInsightProps> = ({
  side,
  insightGroup,
}) => {
  const [hamburgerMode] = useMediaQuery("(max-width: 768px)");
  let height = 800;
  if (hamburgerMode) {
    height = 350;
  }
  const width = (height / 5) * 4;
  if (!insightGroup.has.insight) {
    return (
      <Box h={height} w={width} bg="#111111" borderRadius={10} mr={1}>
        <Center h={400}>
          <Image src="/insights/watch-on-wrist.svg" />
        </Center>
        <VStack mt={0 - height}>
          <Heading
            mt={5}
            mb={height - 170}
            size={["xs", "xs", "sm", "sm"]}
            as="h5"
            color="#AFAFAF"
          >
            {insightGroup.title.detail} (Right)
          </Heading>
          <Box px={10} pt={5}>
            <Text color="#AFAFAF" fontSize={["xs", "xs", "sm", "sm"]}>
              <b>
                To get stroke insights for this arm, try swimming with your
                Apple Watch on your right wrist. No need to change any settings,
                we'll work it out.
              </b>
            </Text>
          </Box>
        </VStack>
      </Box>
    );
  } else if (side === "left" && insightGroup.left.hasData) {
    return (
      <Box h={height} w={width} bg="#111111" borderRadius={10} mr={1}>
        <Image
          src={
            insightGroup.left.imageWeb
              ? insightGroup.left.imageWeb + "?u=" + new Date().getTime()
              : insightGroup.left.image + "?u=" + new Date().getTime()
          }
        />
        <VStack mt={0 - height + 20}>
          <Heading
            mt={5}
            mb={height - 170}
            size={["xs", "xs", "sm", "sm"]}
            as="h5"
            color="#AFAFAF"
          >
            {insightGroup.title.detail} (Left)
          </Heading>
          <VStack as="span" bg="rgba(17,17,17,0.75)" p={1} borderRadius={7}>
            <FilledBar
              score={insightGroup.left.score}
              max={5}
              width={width - 60}
              showScore={true}
            />
            <Text color="#AFAFAF" fontSize="xs">
              <b>
                {insightGroup.left.comment
                  ? insightGroup.left.comment.tiny
                  : "Hmmm"}
              </b>
            </Text>
          </VStack>
        </VStack>
      </Box>
    );
  } else if (side === "right" && insightGroup.right.hasData) {
    return (
      <Box h={height} w={width} bg="#111111" borderRadius={10} mr={1}>
        <Image
          src={
            insightGroup.right.imageWeb
              ? insightGroup.right.imageWeb + "?u=" + new Date().getTime()
              : insightGroup.right.image + "?u=" + new Date().getTime()
          }
        />
        <VStack mt={0 - height + 20}>
          <Heading
            mt={5}
            mb={height - 170}
            size={["xs", "xs", "sm", "sm"]}
            as="h5"
            color="#AFAFAF"
          >
            {insightGroup.title.detail} (Right)
          </Heading>
          <VStack as="span" bg="rgba(17,17,17,0.75)" p={1} borderRadius={7}>
            <FilledBar
              score={insightGroup.right.score}
              max={5}
              width={width - 60}
              showScore={true}
            />
            <Text color="#AFAFAF" fontSize="xs">
              <b>
                {insightGroup.right.comment
                  ? insightGroup.right.comment.tiny
                  : "Hmmm"}
              </b>
            </Text>
          </VStack>
        </VStack>
      </Box>
    );
  } else if (side === "left") {
    return (
      <Box h={height} w={width} bg="#111111" borderRadius={10} mr={1}>
        <Center h={height}>
          <Image src="/insights/watch-on-wrist.svg" />
        </Center>
        <VStack mt={0 - height}>
          <Heading
            mt={5}
            mb={[height - 170, height - 170, height - 210, height - 210]}
            size={["xs", "xs", "sm", "sm"]}
            as="h5"
            color="#AFAFAF"
          >
            {insightGroup.title.detail} (Left)
          </Heading>
          <Box px={10} pt={5}>
            <Text
              color="#AFAFAF"
              fontSize={["xx-small", "xx-small", "lg", "lg"]}
              mb={2}
            >
              To get stroke insights for your left arm, try swimming with your
              Apple Watch on your left wrist.
            </Text>
            <Text
              color="#AFAFAF"
              fontSize={["xx-small", "xx-small", "lg", "lg"]}
            >
              No need to change any settings, we'll work it out.
            </Text>
          </Box>
        </VStack>
      </Box>
    );
  } else if (side === "right") {
    return (
      <Box h={height} w={width} bg="#111111" borderRadius={10} mr={1}>
        <Center h={height}>
          <Image src="/insights/watch-on-wrist.svg" />
        </Center>
        <VStack mt={0 - height}>
          <Heading
            mt={5}
            mb={[height - 170, height - 170, height - 210, height - 210]}
            size={["xs", "xs", "sm", "sm"]}
            as="h5"
            color="#AFAFAF"
          >
            {insightGroup.title.detail} (Right)
          </Heading>
          <Box px={10} pt={5}>
            <Text
              color="#AFAFAF"
              fontSize={["xx-small", "xx-small", "lg", "lg"]}
              mb={2}
            >
              To get stroke insights for your right arm, try swimming with your
              Apple Watch on your right wrist.
            </Text>
            <Text
              color="#AFAFAF"
              fontSize={["xx-small", "xx-small", "lg", "lg"]}
            >
              No need to change any settings, we'll work it out.
            </Text>
          </Box>
        </VStack>
      </Box>
    );
  }

  return (
    <Box h={height} w={width} bg="#111111" borderRadius={10} mr={1}>
      <Center h={height}>
        <Image src="/insights/watch-on-wrist.svg" />
      </Center>
      <VStack mt={0 - height}>
        <Heading
          mt={5}
          mb={[height - 170, height - 170, height - 210, height - 210]}
          size={["xs", "xs", "sm", "sm"]}
          as="h5"
          color="#AFAFAF"
        >
          Well this is weird {side}
        </Heading>
        <Box px={10} pt={5}>
          <Text color="#AFAFAF" fontSize={["xs", "xs", "sm", "sm"]}>
            <b>How many arms do you have?</b>
          </Text>
        </Box>
      </VStack>
    </Box>
  );
};
export { StrokeInsight };
