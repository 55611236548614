import {
  Box,
  HStack,
  Heading,
  Icon,
  Image,
  SimpleGrid,
  Text,
  Tooltip,
  VStack,
  useColorModeValue,
} from "@chakra-ui/react";
import { GiPathDistance } from "react-icons/gi";
import { IoFlame, IoTimerOutline } from "react-icons/io5";
import { MdModelTraining } from "react-icons/md";
import { useAppSelector } from "../../app/hooks";
import { selectAuthState } from "../../features/auth/authSlice";
import { session } from "../../types/session";
import { user } from "../../types/user";
import { checkLevelInArray } from "../../utils/authHelper";

interface SessionListItemSmallProps {
  user: user;
  session: session;
}

const SessionListItemSmall: React.FC<SessionListItemSmallProps> = ({
  user,
  session,
}) => {
  const iconSize = 5;
  const fontSize = "small";
  const intensityColorMode = useColorModeValue(
    "ssNeonOrangeDark",
    "ssNeonOrangeLight"
  );
  const { access: accessToken } = useAppSelector(selectAuthState);

  return (
    <HStack
      opacity={!checkLevelInArray(accessToken, session.data.levels) ? 0.5 : 1}
      alignItems="flex-start"
    >
      <VStack pl={4} alignItems="flex-start" w="full">
        <Text fontSize="smaller">{session.data.typeName}</Text>
        <Heading size="md" as="h3" position="relative" top="-6px" noOfLines={1}>
          {session.data.name}
        </Heading>
        <HStack w="full">
          {session.data.images.has && (
            <Image
              src={session.data.images.data.preview}
              borderRadius={10}
              h={70}
              w={70}
              fit="cover"
              fallbackSrc="https://images.ctfassets.net/50b15ahactsg/Al70qWgtjQvAjRifFlBIq/35448e67bbf63873261153bf268d3764/Paul_Squad.jpg"
            />
          )}
          {!session.data.images.has && (
            <Image
              borderRadius={10}
              h={70}
              w={70}
              fit="cover"
              src="https://images.ctfassets.net/50b15ahactsg/Al70qWgtjQvAjRifFlBIq/35448e67bbf63873261153bf268d3764/Paul_Squad.jpg"
            />
          )}
          <SimpleGrid columns={[1, 1, 2, 2]} w="full">
            <Tooltip hasArrow label="Distance">
              <span>
                <Icon
                  h={iconSize}
                  w={iconSize}
                  color="grey"
                  viewBox="0 0 100 100"
                  mr={2}
                  as={GiPathDistance}
                />
                <Box
                  display="inline-block"
                  position="relative"
                  top="-6px"
                  fontSize={fontSize}
                >
                  {session.data.scaled.distance}
                </Box>
              </span>
            </Tooltip>
            <Tooltip hasArrow label="Expected Time">
              <span>
                <Icon
                  h={iconSize}
                  w={iconSize}
                  color="grey"
                  mr={2}
                  as={IoTimerOutline}
                />
                <Box
                  display="inline-block"
                  position="relative"
                  top="-6px"
                  fontSize={fontSize}
                >
                  {session.data.scaled.time}
                </Box>
              </span>
            </Tooltip>
            <Tooltip hasArrow label="Intensity">
              <span>
                <Icon
                  h={iconSize}
                  w={iconSize}
                  color="grey"
                  as={IoFlame}
                  fill={
                    session.data.scaled.intensity > 0
                      ? intensityColorMode
                      : "grey"
                  }
                />
                <Icon
                  h={iconSize}
                  w={iconSize}
                  color="grey"
                  as={IoFlame}
                  fill={
                    session.data.scaled.intensity > 1
                      ? intensityColorMode
                      : "grey"
                  }
                />
                <Icon
                  h={iconSize}
                  w={iconSize}
                  color="grey"
                  as={IoFlame}
                  fill={
                    session.data.scaled.intensity > 2
                      ? intensityColorMode
                      : "grey"
                  }
                />
                <Icon
                  h={iconSize}
                  w={iconSize}
                  color="grey"
                  as={IoFlame}
                  fill={
                    session.data.scaled.intensity > 3
                      ? intensityColorMode
                      : "grey"
                  }
                />
                <Icon
                  h={iconSize}
                  w={iconSize}
                  color="grey"
                  as={IoFlame}
                  fill={
                    session.data.scaled.intensity > 4
                      ? intensityColorMode
                      : "grey"
                  }
                />
              </span>
            </Tooltip>{" "}
            <Tooltip hasArrow label="sTSS">
              <span>
                <Icon
                  h={iconSize}
                  w={iconSize}
                  color="grey"
                  mr={2}
                  as={MdModelTraining}
                />
                <Box
                  display="inline-block"
                  position="relative"
                  top="-6px"
                  fontSize={fontSize}
                >
                  {session.data.scaled.stss
                    ? session.data.scaled.stss.toFixed(2)
                    : 0}
                </Box>
              </span>
            </Tooltip>
          </SimpleGrid>
        </HStack>
      </VStack>
    </HStack>
  );
};

export { SessionListItemSmall };
