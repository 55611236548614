import { theme as base, extendTheme, type ThemeConfig } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";
import { MultiSelectTheme } from "chakra-multiselect";
import { Button } from "./components/Button";
import { CoachBox } from "./components/CoachBox";
import { Footer } from "./components/Footer";
import { Header } from "./components/Header";
import { Heading } from "./components/Heading";
import { Icon } from "./components/Icon";
import { Input } from "./components/Input";
import { NumberInput } from "./components/NumberInput";
import { Select } from "./components/Select";
import { Text } from "./components/Text";

const config: ThemeConfig = {
  initialColorMode: "system",
  useSystemColorMode: true,
};

const swimSmoothTheme = extendTheme(
  {
    colors: {
      ssBackgroundDark: "#0F1215",
      ssBackgroundLight: "#FFFFFF",
      ssBoxBackgroundDark: "#2e3740",
      ssBoxBackgroundLight: "#EBEFF2",
      ssInnerBoxBackgroundDark: "#181c20",
      ssInnerBoxBackgroundLight: "#f3f6f9",
      ssBoxBackgroundMatchDark: "#1D3322",
      ssBoxBackgroundMatchLight: "#C9F2D5",
      ssPrimaryDark: "#002140",
      ssPrimaryLight: "#78DFFF",
      ssNeonPinkDark: "#A419BC",
      ssNeonPinkLight: "#E755FF",
      ssNeonOrangeDark: "#FF7A30",
      ssNeonOrangeLight: "#FF9B63",
      ssNeonGreenDark: "#00B24E",
      ssNeonGreenLight: "#35FC86",
      ssNeonBlueDark: "#0491DF",
      ssNeonBlueLight: "#35B0F4",
      ssGraphTrailDark: "#AAAAAA",
      ssGraphTrailLight: "#666666",
    },
    styles: {
      global: (props: any) => ({
        a: {
          color: mode("ssPrimaryDark", "ssPrimaryLight")(props),
          fontWeight: 600,
        },
        ul: {
          marginTop: "10px",
          marginBottom: "5px",
        },
        ol: {
          marginTop: "10px",
          marginBottom: "5px",
        },
        li: {
          marginLeft: "30px",
        },
        em: {
          fontStyle: "italic",
        },
        body: {
          backgroundColor: mode("ssBackgroundLight", "ssBackgroundDark")(props),
        },
      }),
    },
    fonts: {
      heading: `rubrik-edge-new, ${base.fonts?.heading}`,
      body: `rubrik-edge-new, ${base.fonts?.body}`,
      Button: `rubrik-edge-new, ${base.fonts?.body}`,
    },
    components: {
      MultiSelect: MultiSelectTheme,
      Heading,
      Text,
      Button,
      Input,
      NumberInput,
      Select,
      Icon,
      CoachBox,
      Header,
      Footer,
    },
    semanticTokens: {
      colors: {
        ssError: {
          default: "#CC0000",
          _dark: "#FFF000",
        },
        icons: {
          default: "red.500",
          _dark: "green.500",
        },
      },
    },
  },
  config
);

export default swimSmoothTheme;
