import { Icon, IconProps, MenuItem } from "@chakra-ui/react";
import { ComponentWithAs, useColorModeValue } from "@chakra-ui/system";
import { IconType } from "react-icons";
import { MdLabelOutline } from "react-icons/md";
import { useNavigate } from "react-router-dom";

interface subMenuOptionProps {
  icon?: IconType | ComponentWithAs<"svg", IconProps>;
  target?: string;
  onClick?: any;
  title: string;
}

const SSSubMenuOption: React.FC<subMenuOptionProps> = ({
  icon,
  target,
  onClick,
  title,
}) => {
  const navigate = useNavigate();
  const iconColourLive = useColorModeValue("ssPrimaryDark", "ssPrimaryLight");

  if (typeof icon === "undefined") {
    icon = MdLabelOutline;
  }

  if (!onClick && target) {
    onClick = () => navigate(target, { replace: false });
  }

  if (onClick) {
    return (
      <MenuItem
        cursor="pointer"
        icon={<Icon as={icon} color={iconColourLive} />}
        onClick={onClick}
      >
        {title}
      </MenuItem>
    );
  }
  return (
    <MenuItem cursor="pointer" icon={<Icon as={icon} color={iconColourLive} />}>
      {title}
    </MenuItem>
  );
};

export { SSSubMenuOption };
