import {
  Box,
  BoxProps,
  Button,
  Center,
  HStack,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getCssCurrent, tweakCss } from "../../DataAccess/css";
import { useAppSelector } from "../../app/hooks";
import { selectAuthState } from "../../features/auth/authSlice";
import { selectUserState } from "../../features/user/userSlice";
import { cssEntry } from "../../types/css";
import { user } from "../../types/user";
import { checkRole } from "../../utils/authHelper";
import { formatPace } from "../../utils/dateHelper";
import { NotAuthorised } from "../generic/NotAuthorised";
import { CustomModal } from "../ui/CustomModal";
import Loading from "../ui/Loading";
import { SingleValueDisplay } from "../ui/SingleValueDisplay";
import { SingleValueWithUnitDisplay } from "../ui/SingleValueWithUnitDisplay";

interface CssTweakerProps extends BoxProps {
  small?: boolean;
}

const CssTweaker: React.FC<CssTweakerProps> = ({ small }) => {
  const { access: accessToken } = useAppSelector(selectAuthState);
  const { user } = useAppSelector(selectUserState);
  const [cssEntry, setCssEntry] = useState<cssEntry | null>();
  const [hamburgerMode] = useMediaQuery("(max-width: 768px)");
  const navigate = useNavigate();

  useEffect(() => {
    const getCssCurrentLocal = async () => {
      setCssEntry(await getCssCurrent());
    };
    getCssCurrentLocal();
  }, []);

  interface tweakLevel {
    name: string;
    shortName: string;
    value: number;
    colour: string;
    width: number;
  }

  const improvements: tweakLevel[] = [
    {
      name: "Tentative",
      shortName: "-",
      value: 0.9975,
      colour: "darkseagreen",
      width: 110,
    },
    {
      name: "Bold",
      shortName: "--",
      value: 0.995,
      colour: "lightgreen",
      width: 130,
    },
    {
      name: "Heroic",
      shortName: "---",
      value: 0.9925,
      colour: "lawngreen",
      width: 170,
    },
  ];

  const regressions: tweakLevel[] = [
    {
      name: "Post Hiatus",
      shortName: "+++",
      value: 1.02,
      colour: "red",
      width: 170,
    },
    {
      name: "Bad Patch",
      shortName: "++",
      value: 1.01,
      colour: "orange",
      width: 130,
    },
    {
      name: "Off-Day",
      shortName: "+",
      value: 1.005,
      colour: "gold",
      width: 110,
    },
  ];

  if (!user) {
    return <Loading message="Error Loading User" />;
  }

  if (!checkRole(accessToken, "tweak", "css")) {
    return <NotAuthorised functionText={"Full CSS Tools"} size={"full"} />;
  }

  if (!cssEntry || (cssEntry && cssEntry.type === "defaulted")) {
    return (
      <>
        <Text>
          You need to enter a critical swim speed before you can tweak it!
        </Text>
        <HStack>
          <Button
            onClick={() => navigate("/css/entersingle", { replace: false })}
          >
            Enter Manual CSS
          </Button>
          <Button
            onClick={() => navigate("/css/calcsingle", { replace: false })}
          >
            Calculate CSS from Test
          </Button>
        </HStack>
      </>
    );
  }

  if (hamburgerMode || small) {
    return (
      <Center>
        <HStack>
          {regressions.map((r) => {
            return (
              <CssTweakerModal
                key={r.shortName}
                user={user}
                cssEntry={cssEntry}
                amount={r.value}
                label={r.shortName}
                name={r.name}
                colour={r.colour}
                w={8}
                setCssEntry={setCssEntry}
              />
            );
          })}
          <SingleValueDisplay
            label={" / 100" + user.data.basic.defaultPool.lapUnit}
            value={
              cssEntry
                ? user.data.basic.defaultPool.lapUnit === "y"
                  ? formatPace(cssEntry.y.secondsPer100)
                  : formatPace(cssEntry.m.secondsPer100)
                : "Not Set"
            }
          />
          {improvements.map((r) => {
            return (
              <CssTweakerModal
                key={r.shortName}
                user={user}
                cssEntry={cssEntry}
                amount={r.value}
                label={r.shortName}
                name={r.name}
                colour={r.colour}
                w={8}
                setCssEntry={setCssEntry}
              />
            );
          })}
        </HStack>
      </Center>
    );
  }

  return (
    <Center>
      <HStack mt={2}>
        {regressions.map((r) => {
          return (
            <CssTweakerModal
              key={r.shortName}
              user={user}
              cssEntry={cssEntry}
              amount={r.value}
              label={r.name}
              name={r.name}
              colour={r.colour}
              w={r.width}
              setCssEntry={setCssEntry}
            />
          );
        })}
        <Box px={5}>
          <SingleValueWithUnitDisplay
            label="CSS Pace"
            unit={" / 100" + user.data.basic.defaultPool.lapUnit}
            value={
              cssEntry
                ? user.data.basic.defaultPool.lapUnit === "y"
                  ? formatPace(cssEntry.y.secondsPer100)
                  : formatPace(cssEntry.m.secondsPer100)
                : "Not Set"
            }
          />
        </Box>
        {improvements.map((r) => {
          return (
            <CssTweakerModal
              key={r.shortName}
              user={user}
              cssEntry={cssEntry}
              amount={r.value}
              label={r.name}
              name={r.name}
              colour={r.colour}
              w={r.width}
              setCssEntry={setCssEntry}
            />
          );
        })}
      </HStack>
    </Center>
  );
};

interface CssTweakerModalProps extends BoxProps {
  user: user;
  cssEntry: cssEntry;
  amount: number;
  label: string;
  name: string;
  colour: string;
  setCssEntry: any;
}

const CssTweakerModal: React.FC<CssTweakerModalProps> = ({
  user,
  cssEntry,
  amount,
  label,
  name,
  colour,
  setCssEntry,
  ...rest
}) => {
  const current =
    user.data.basic.defaultPool.lapUnit === "y"
      ? cssEntry.y.secondsPer100
      : cssEntry.m.secondsPer100;

  const tweaked = current * amount;
  return (
    <CustomModal
      showButtonText={label}
      showButtonColour={colour}
      confirmButtonText="Tweak It!"
      cancelButtonText="No, thanks"
      header={"Tweak CSS (" + name + ")"}
      confirmFunction={async () => {
        await tweakCss(
          tweaked,
          user.data.basic.defaultPool.lapLength,
          user.data.basic.defaultPool.lapUnit
        );
        setCssEntry(await getCssCurrent());
      }}
      {...rest}
    >
      Are you sure you want to tweak your critical swim speed (CSS) from{" "}
      {formatPace(current)} / 100
      {user.data.basic.defaultPool.lapUnit} to {formatPace(tweaked)} / 100
      {user.data.basic.defaultPool.lapUnit}
    </CustomModal>
  );
};

export { CssTweaker };
