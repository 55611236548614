import {
  Avatar,
  Button,
  Heading,
  HStack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useAppSelector } from "../../../app/hooks";
import { selectUserState } from "../../../features/user/userSlice";
import { getGravatarUrl } from "../../../utils/gravatarHelper";
import Loading from "../../ui/Loading";

const AccountSettingsAvatar = () => {
  const { user } = useAppSelector(selectUserState);

  if (!user) {
    return <Loading message="Error Loading User" />;
  }

  return (
    <VStack w="full" alignItems="flex-start" pb={5}>
      <Heading as="h3" size="md">
        Avatar
      </Heading>
      <HStack>
        <Avatar
          size="2xl"
          name={user.data.basic.fullName}
          src={getGravatarUrl(user.data.basic.emailAddress, 200)}
          mr={10}
        />
        <VStack w="full" alignItems="flex-start">
          <Text>
            Your avatar is a{" "}
            <Button
              variant="link"
              onClick={() => {
                window.open("https://gravatar.com/")?.focus();
              }}
            >
              Gravatar
            </Button>
            .
          </Text>
          <Text>
            If you don't have one you can set one up for your email address{" "}
            <Button
              variant="link"
              onClick={() => {
                window.open("https://gravatar.com/")?.focus();
              }}
            >
              here
            </Button>
            , it's completely free and can be used on many or your favourite
            sites.
          </Text>
        </VStack>
      </HStack>
    </VStack>
  );
};
export { AccountSettingsAvatar };
