import { BoxProps, Button, Heading, Text, VStack } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getCoachByShortCode } from "../../DataAccess/coaches";
import { useAppSelector } from "../../app/hooks";
import { selectAuthState } from "../../features/auth/authSlice";
import { selectUserState } from "../../features/user/userSlice";
import { breadcrumbLink } from "../../types/breadcrumb";
import { coach } from "../../types/coach";
import { checkRole } from "../../utils/authHelper";
import {
  BreadcrumbContext,
  breadcrumbContextType,
} from "../context/BreadcrumbContext";
import { MessageDisplay } from "../generic/MessageDisplay";
import Loading from "../ui/Loading";

interface CoachesEditProps extends BoxProps {
  coachShortCode: string;
}

const CoachEditSessions: React.FC<CoachesEditProps> = ({ coachShortCode }) => {
  const { access: accessToken } = useAppSelector(selectAuthState);
  const { user } = useAppSelector(selectUserState);
  const [loadingCoach, setLoadingCoach] = useState<boolean>(true);
  const [selectedCoach, setSelectedCoach] = useState<coach>();
  const navigate = useNavigate();
  const { setBreadcrumbLinks } =
    useContext<breadcrumbContextType>(BreadcrumbContext);

  useEffect(() => {
    const getCoachLocal = async () => {
      const coachResponse = await getCoachByShortCode(coachShortCode);
      setSelectedCoach(coachResponse);
      setLoadingCoach(false);
    };
    getCoachLocal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const breadcrumbLinks: breadcrumbLink[] = [
      { href: "/coaches", title: "Coaches" },
    ];
    if (selectedCoach) {
      if (selectedCoach.data.displayName) {
        breadcrumbLinks.push({
          href: "/coaches/" + selectedCoach.data.shortCode,
          title: selectedCoach.data.displayName,
        });
      } else {
        breadcrumbLinks.push({
          href: "/coaches/" + selectedCoach.data.shortCode,
          title:
            selectedCoach.data.firstName + " " + selectedCoach.data.lastName,
        });
      }
      breadcrumbLinks.push({
        href: "/coaches/" + selectedCoach.data.shortCode + "/edit/sessions",
        title: "Edit Sessions",
      });
    }
    setBreadcrumbLinks(breadcrumbLinks);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCoach]);

  if (loadingCoach) {
    return <Loading message="Loading Coach" />;
  }

  if (!user) {
    return <Loading message="Error Loading User" />;
  }

  if (
    !checkRole(accessToken, "*ALL", "superadmin") &&
    user._id !== selectedCoach?.data.user
  ) {
    return (
      <VStack w="full" alignItems="left">
        <Heading>403: Not Authorised</Heading>
        <Text>
          We're terribly sorry but it would appear that you have tried to do
          something you are not authorised to do. Could be us, could be you ...
          if you are sure you should be able to do what you are trying to do
          then get in touch using the help button at the bottom left of the
          screen.
        </Text>
      </VStack>
    );
  }

  if (
    selectedCoach &&
    selectedCoach.data.shortCode &&
    selectedCoach.data.shortCode === coachShortCode
  ) {
    return (
      <VStack w="full" alignItems="left">
        <Heading>{selectedCoach.data.displayName} Sessions</Heading>
      </VStack>
    );
  }

  if (selectedCoach && !selectedCoach.data.shortCode) {
    return (
      <VStack w="full">
        <MessageDisplay status="error" title="Could not find coach!">
          <Text>Could not find coach {coachShortCode}.</Text>
        </MessageDisplay>
        <Button onClick={() => navigate("/coaches", { replace: false })}>
          See All Coaches
        </Button>
      </VStack>
    );
  }

  return <Loading />;
};

export { CoachEditSessions };
