import { Grid, GridItem, VStack } from "@chakra-ui/react";
import { useContext, useEffect } from "react";
import { useAppSelector } from "../../app/hooks";
import { YourCoach } from "../../components/coaches/YourCoach";
import {
  BreadcrumbContext,
  breadcrumbContextType,
} from "../../components/context/BreadcrumbContext";
import Loading from "../../components/ui/Loading";
import { selectAuthState } from "../../features/auth/authSlice";
import { selectUserState } from "../../features/user/userSlice";
import { checkRole } from "../../utils/authHelper";
import { CssBsrSummary } from "../training/CssBsrSummary";
import { FitnessAndFatigue } from "../training/FitnessAndFatigue";
import { TrainingBalance } from "../training/TrainingBalance";
import { TrainingSummary } from "../training/TrainingSummary";
import { WorldsBiggestSwimSquadWidget } from "../wbss/WorldsBiggestSwimSquadWidget";

const Understanding = () => {
  const { access: accessToken } = useAppSelector(selectAuthState);
  const { setBreadcrumbLinks } =
    useContext<breadcrumbContextType>(BreadcrumbContext);
  const { user } = useAppSelector(selectUserState);

  useEffect(() => {
    const breadcrumbLinks = [{ href: "/progress", title: "Progress" }];
    setBreadcrumbLinks(breadcrumbLinks);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!user) {
    return <Loading message="Loading User" />;
  }

  return (
    <Grid
      templateColumns={[
        "repeat(1, 1fr)",
        "repeat(1, 1fr)",
        "repeat(6, 1fr)",
        "repeat(6, 1fr)",
      ]}
      gap={2}
    >
      <GridItem colSpan={[1, 1, 2, 2]}>
        <YourCoach />
      </GridItem>
      <GridItem colSpan={[1, 1, 2, 2]}>
        <WorldsBiggestSwimSquadWidget />
      </GridItem>
      <GridItem colSpan={[1, 1, 2, 2]}>
        <VStack w={"full"} h={"full"}>
          <TrainingBalance />
          <CssBsrSummary />
          <TrainingSummary />
        </VStack>
      </GridItem>
      <GridItem colSpan={[1, 1, 6, 6]}>
        <FitnessAndFatigue
          subscriber={checkRole(accessToken, "read", "fitnessAndFatigue")}
        />
      </GridItem>
    </Grid>
  );
};

export { Understanding };
