import { Heading, VStack } from "@chakra-ui/react";
import * as contentful from "contentful";
import { useContext, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectAuthState } from "../../features/auth/authSlice";
import {
  selectAreasState,
  updateAreasState,
} from "../../features/library/areasSlice";
import { selectUserState } from "../../features/user/userSlice";
import { checkLevelInArray } from "../../utils/authHelper";
import {
  BreadcrumbContext,
  breadcrumbContextType,
} from "../context/BreadcrumbContext";
import { Contentful } from "../ui/Contentful";
import Loading from "../ui/Loading";

const client = contentful.createClient({
  space: "50b15ahactsg",
  accessToken: "rYtEmKRrWoeFQM4pR8Ot8SZV7uC8OERTel461AQ3kvk",
});

interface LibraryEntityProps {
  areaId: string;
  categoryId: string;
  entityId: string;
}

const LibraryEntity: React.FC<LibraryEntityProps> = ({
  areaId,
  categoryId,
  entityId,
}) => {
  const { setBreadcrumbLinks } =
    useContext<breadcrumbContextType>(BreadcrumbContext);

  const { access: accessToken } = useAppSelector(selectAuthState);
  const { user } = useAppSelector(selectUserState);
  const { areas, lastUpdated } = useAppSelector(selectAreasState);
  const [entity, setEntity] = useState<any>(null);
  const [linkedEntity, setLinkedEntity] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [area, setArea] = useState<any | null>(null);
  const [category, setCategory] = useState<any | null>(null);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (area && category && linkedEntity) {
      const breadcrumbLinks = [
        { href: "/library", title: "Library" },
        { href: "/library/" + areaId, title: area.fields.title },
        {
          href: "/library/" + areaId + "/" + categoryId,
          title: category.fields.title,
        },
        {
          href: "/library/" + areaId + "/" + categoryId + "/" + entityId,
          title: linkedEntity.fields.title,
        },
      ];
      setBreadcrumbLinks(breadcrumbLinks);
    } else {
      const breadcrumbLinks = [
        { href: "/library", title: "Library" },
        { href: "/library/" + areaId, title: "-" },
        { href: "/library/" + areaId + "/" + categoryId, title: "-" },
        {
          href: "/library/" + areaId + "/" + categoryId + "/" + entityId,
          title: "-",
        },
      ];
      setBreadcrumbLinks(breadcrumbLinks);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [area, category, linkedEntity]);

  useEffect(() => {
    const getEntriesLocal = async () => {
      const entries = await client.getEntries({
        content_type: "area",
      });
      dispatch(
        updateAreasState({
          areas: entries.items.sort((a: any, b: any) => {
            return a.fields.order - b.fields.order;
          }),
        })
      );
    };
    if (lastUpdated) {
      const test = new Date(lastUpdated).getTime();
      const anHourAgo = new Date().getTime() - 1000 * 60 * 60;
      if (!areas || areas.length === 0 || anHourAgo > test) {
        getEntriesLocal();
      }
    } else {
      getEntriesLocal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getEntityLocal = async () => {
      const selectedArea = areas.filter((a: any) => a.sys.id === areaId);
      if (selectedArea.length === 1) {
        setArea(selectedArea[0]);
        const selectedCategory = selectedArea[0].fields.categories.filter(
          (c: any) => c.sys.id === categoryId
        );
        if (selectedCategory.length === 1) {
          setCategory(selectedCategory[0]);
          const selectedEntity = selectedCategory[0].fields.articles.filter(
            (e: any) => e.sys.id === entityId
          );
          if (selectedEntity.length === 1) {
            const linkedResult = await client.getEntry(
              selectedEntity[0].sys.id
            );
            setEntity(selectedEntity[0]);
            setLinkedEntity(linkedResult);
            setLoading(false);
          }
        }
      }
    };
    getEntityLocal();
  }, [areaId, categoryId, entityId, areas]);

  if (loading) {
    return <Loading />;
  }

  if (!user || !area || !category || !entity || !linkedEntity) {
    return <Navigate to={{ pathname: "/library" }} />;
  }

  if (!checkLevelInArray(accessToken, linkedEntity.fields.levels)) {
    return <Navigate to={{ pathname: "/library" }} />;
  }

  return (
    <VStack w="full">
      <Heading as="h2" size="xl">
        {linkedEntity.fields.title}
      </Heading>
      <Contentful entity={linkedEntity} />
    </VStack>
  );
};

export { LibraryEntity };
