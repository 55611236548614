import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { session } from "../../types/session";

// Define the state of the slice as an object
export interface SessionState {
  session: session | null;
  targetDistance: number | null;
  requestedDistance: number | null;
}

// Define an initial state
const initialState: SessionState = {
  session: null,
  targetDistance: null,
  requestedDistance: null,
};

// Create a slice containing the configuration of the state
// and the reducers functions
const sessionSlice = createSlice({
  name: "sessionState",
  initialState,
  reducers: {
    updateSessionState(state, action: PayloadAction<SessionState | null>) {
      if (action.payload) {
        state.session = action.payload.session;
        state.targetDistance = action.payload.targetDistance;
        state.requestedDistance = action.payload.requestedDistance;
      } else {
        state.session = initialState.session;
        state.targetDistance = initialState.targetDistance;
        state.requestedDistance = initialState.requestedDistance;
      }
    },
  },
});

// Export each reducers function defined in createSlice
export const { updateSessionState } = sessionSlice.actions;

export const selectSessionState = (state: RootState): SessionState => {
  return state.sessionState;
};

// Export default the slice reducer
export default sessionSlice.reducer;
