import {
  Box,
  BoxProps,
  Button,
  Heading,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { playSessionRoulette } from "../../DataAccess/sessions";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectAuthState } from "../../features/auth/authSlice";
import {
  selectSessionRouletteState,
  updateSessionRouletteState,
  updateSessionRouletteTakenState,
} from "../../features/session/sessionRouletteSlice";
import { selectUserState } from "../../features/user/userSlice";
import { session } from "../../types/session";
import { checkRole } from "../../utils/authHelper";
import { BoxWrapper } from "../ui/BoxWrapper";
import Loading from "../ui/Loading";
import { Roulette } from "../ui/Roulette";
import { SessionListItemSmall } from "./SessionListItemSmall";

interface SessionRouletteProps extends BoxProps {}

const data = [
  { option: "Virtual Squad", style: { backgroundColor: "#016d29" } }, //0
  { option: "Red Mist" }, //1
  { option: "Pure Technique" }, //2
  { option: "Pink Mist" }, //3
  { option: "Technique & Endurance" }, //4
  { option: "CSS" }, //5
  { option: "Sprint" }, //6
  { option: "Taper" }, //7
  { option: "Open Water" }, //8
  { option: "Stoic" }, //9
  { option: "Long Aerobic" }, //10
  { option: "Quick 'n' Easy" }, //11
];
const winnerLookup = [
  { name: "puretechnique", index: 2 },
  { name: "techniqueendurance", index: 4 },
  { name: "redmistendurance", index: 1 },
  { name: "saturdaystoic", index: 9 },
  { name: "sprint", index: 6 },
  { name: "openwaterpool", index: 8 },
];
const buttonText = [
  "Let's get started, spin me",
  "Still not feeling it, try again",
  "Last chance, are you feeling lucky?",
];

const SessionRoulette: React.FC<SessionRouletteProps> = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const [prizeNumber, setPrizeNumber] = useState(0);
  const { date, spins, sessions, taken } = useAppSelector(
    selectSessionRouletteState
  );
  const { access: accessToken } = useAppSelector(selectAuthState);
  const subscriber = checkRole(accessToken, "roulette", "session");
  const epoch = new Date("1970-01-01");
  const dispatch = useAppDispatch();

  useEffect(() => {
    const today = new Date();
    today.setHours(0);
    today.setMinutes(0);
    today.setSeconds(0);
    today.setMilliseconds(0);
    const last = new Date(date);
    if (last > epoch && last < today) {
      dispatch(updateSessionRouletteState(null));
    }
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [epoch, date]);

  useEffect(() => {
    const getSessionLocal = async () => {
      if (sessions.length === 0) {
        const rouletteSession = await playSessionRoulette();
        const newSessions = [...sessions, rouletteSession];
        dispatch(
          updateSessionRouletteState({
            spins: spins,
            sessions: newSessions,
            taken: false,
          })
        );
        const winnerType = newSessions[newSessions.length - 1].data.type;
        const winner = winnerLookup.filter(
          (l: any) => l.name === winnerType
        )[0];
        setPrizeNumber(
          winner && winner.index > 0 && winner.index < data.length - 1
            ? winner.index
            : 0
        );
      } else {
        const winnerType = sessions[sessions.length - 1].data.type;
        const winner = winnerLookup.filter(
          (l: any) => l.name === winnerType
        )[0];
        setPrizeNumber(
          winner && winner.index > 0 && winner.index < data.length - 1
            ? winner.index
            : 0
        );
      }
    };
    getSessionLocal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const nextStage = async (): Promise<void> => {
    if (sessions.length < 3) {
      const exclude = sessions[sessions.length - 1]
        ? sessions[sessions.length - 1].data.type
        : "";
      const rouletteSession = await playSessionRoulette(exclude);
      const newSessions = [...sessions, rouletteSession];
      dispatch(
        updateSessionRouletteState({
          spins: spins + 1,
          sessions: newSessions,
          taken: false,
        })
      );
      const winnerType = newSessions[newSessions.length - 1].data.type;
      const winner = winnerLookup.filter((l: any) => l.name === winnerType)[0];
      setPrizeNumber(
        winner && winner.index > 0 && winner.index < data.length - 1
          ? winner.index
          : 0
      );
    } else {
      dispatch(
        updateSessionRouletteState({
          spins: spins + 1,
          sessions: sessions,
          taken: false,
        })
      );
    }
  };
  if (loading) {
    return (
      <BoxWrapper>
        <Heading mb={5} size="md">
          Session Roulette
        </Heading>
        <Loading message="Loading Session Roulette" />
      </BoxWrapper>
    );
  }

  if (!subscriber) {
    return (
      <BoxWrapper>
        <Heading mb={5} size="md">
          Session Roulette
        </Heading>
        <Text>
          <Button
            variant="link"
            onClick={() =>
              navigate("/account/subscription/subscribe", { replace: false })
            }
          >
            Subscribe
          </Button>{" "}
          to get access to the session roulette.
        </Text>
      </BoxWrapper>
    );
  }

  if (spins > 0 && spins < 3) {
    const previousWinnerType = sessions[spins - 1].data.type;
    const previousWinner = winnerLookup.filter(
      (l: any) => l.name === previousWinnerType
    )[0];
    const startingOption =
      previousWinner &&
      previousWinner.index > 0 &&
      previousWinner.index < data.length - 1
        ? previousWinner.index
        : 0;
    return (
      <BoxWrapper>
        <Debug date={date} sessions={sessions} />
        <Heading mb={5} size="md">
          Session Roulette (spin {spins} of 3)
        </Heading>
        <Text mb={3}>
          The guru has picked a nice{" "}
          {sessions[spins - 1].data.typeName
            .toLowerCase()
            .replace("sessions", "session")}{" "}
          for you.
          {!taken
            ? " Not your cup of tea? You can spin " +
              (3 - spins) +
              " more time" +
              (spins === 3 ? null : "s") +
              "."
            : null}
        </Text>
        <RouletteSession session={sessions[spins - 1]} />
        {!taken && (
          <Roulette
            data={data}
            prizeNumber={prizeNumber}
            onStopSpinningCallback={() => {
              nextStage();
            }}
            buttonText={buttonText[spins] ? buttonText[spins] : "Spin Me!"}
            startingOption={startingOption}
            hideButton={false}
          />
        )}
        {taken && (
          <Text mt={5}>
            Do, or do not ... their is no try! Looks like you settled on this
            session. Either swim the session or checkout our{" "}
            <Button
              variant="link"
              onClick={() => navigate("/sessions", { replace: false })}
            >
              library
            </Button>{" "}
            of hundreds of sessions that might float your boat a bit better.
            Session roulette will reset tomorrow.
          </Text>
        )}
      </BoxWrapper>
    );
  } else if (spins >= 3) {
    const previousWinnerType = sessions[spins - 1].data.type;
    const previousWinner = winnerLookup.filter(
      (l: any) => l.name === previousWinnerType
    )[0];
    const startingOption =
      previousWinner &&
      previousWinner.index > 0 &&
      previousWinner.index < data.length - 1
        ? previousWinner.index
        : 0;
    return (
      <BoxWrapper>
        <Debug date={date} sessions={sessions} />
        <Heading mb={5} size="md">
          Session Roulette
        </Heading>
        <Text mb={3}>
          The guru has picked a nice{" "}
          {sessions[sessions.length - 1].data.typeName
            .toLowerCase()
            .replace("sessions", "session")}{" "}
          for you.
        </Text>
        <RouletteSession session={sessions[spins - 1]} />
        {!taken && (
          <Roulette
            data={data}
            prizeNumber={prizeNumber}
            onStopSpinningCallback={() => {
              nextStage();
            }}
            buttonText={buttonText[spins] ? buttonText[spins] : "Spin Me!"}
            startingOption={startingOption}
            hideButton={true}
          />
        )}
        {taken && (
          <Text mt={5}>
            Do, or do not ... their is no try! Looks like you settled on this
            session. Either swim the session or checkout our{" "}
            <Button
              variant="link"
              onClick={() => navigate("/sessions", { replace: false })}
            >
              library
            </Button>{" "}
            of hundreds of sessions that might float your boat a bit better.
            Session roulette will reset tomorrow.
          </Text>
        )}
        {!taken && (
          <Text mt={5}>
            Choose wisely, you must! You're out of 'spins' for today. Either
            swim the session or checkout our{" "}
            <Button
              variant="link"
              onClick={() => navigate("/sessions", { replace: false })}
            >
              library
            </Button>{" "}
            of hundreds of sessions that might float your boat a bit better.
            Session roulette will reset tomorrow and give you three more 'spins'
            ... choose wisely .
          </Text>
        )}
        <Button
          w={"full"}
          mt={5}
          onClick={() => navigate("/sessions", { replace: false })}
        >
          Go to the Session Library
        </Button>
      </BoxWrapper>
    );
  } else if (spins === 0) {
    return (
      <BoxWrapper>
        <Debug date={date} sessions={sessions} />
        <Heading mb={5} size="md">
          Session Roulette
        </Heading>
        <Text>
          The future has many paths, choose wisely. The concept is simple. Stop
          procrastinating and swim whatever session the Guru selects for you. It
          could be a CSS session, maybe some pure technique or will you get a
          red mist session ... only time will tell, click to the button to find
          out!
        </Text>
        <Roulette
          data={data}
          prizeNumber={prizeNumber}
          onStopSpinningCallback={() => {
            nextStage();
          }}
          buttonText={buttonText[spins] ? buttonText[0] : "Spin Me!"}
          startingOption={0}
          hideButton={false}
        />
        <Text>
          The Guru is generous to a fault so will let you 'spin the wheel' three
          times. If you don't like the look of the session you can spin again{" "}
          <em>but</em> beware, if you peak at the session it's locked in and
          once you've spun three times that is it for today!
        </Text>
      </BoxWrapper>
    );
  }

  return null;
};

const Debug = (props: any) => {
  return null;
  // return (
  //   <pre>
  //     <code>
  //       {formatDateTime(props.date)}
  //       <br />
  //       {JSON.stringify(
  //         props.sessions.map((s: session) => {
  //           return { type: s.data.type, name: s.data.name };
  //         }),
  //         null,
  //         2
  //       )}
  //     </code>
  //   </pre>
  // );
};
interface RouletteSessionProps {
  session: session;
}

const RouletteSession: React.FC<RouletteSessionProps> = ({ session }) => {
  const innerBoxColorMode = useColorModeValue(
    "ssInnerBoxBackgroundLight",
    "ssInnerBoxBackgroundDark"
  );
  const borderColorMode = useColorModeValue("ssPrimaryDark", "ssPrimaryLight");
  const { user } = useAppSelector(selectUserState);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  if (!user) {
    return null;
  }

  return (
    <Box
      cursor="pointer"
      borderWidth={2}
      pt={1}
      pb={3}
      borderRadius={7}
      borderColor={borderColorMode}
      bg={innerBoxColorMode}
      onClick={() => {
        dispatch(updateSessionRouletteTakenState(true));
        navigate("/sessions/id/" + session.id, { replace: false });
      }}
    >
      <SessionListItemSmall user={user} session={session} />
    </Box>
  );
};

export { SessionRoulette };
