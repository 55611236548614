import { BoxProps, Button, VStack } from "@chakra-ui/react";
import { useState } from "react";
import { Wheel } from "react-custom-roulette";

interface RouletteProps extends BoxProps {
  data: any[];
  prizeNumber: number;
  onStopSpinningCallback: any;
  buttonText: string;
  startingOption: number;
  hideButton: boolean;
}

const Roulette: React.FC<RouletteProps> = ({
  data,
  prizeNumber,
  onStopSpinningCallback,
  buttonText,
  startingOption,
  hideButton,
}) => {
  const [mustSpin, setMustSpin] = useState(false);

  const handleSpinClick = () => {
    setMustSpin(true);
  };

  const onStopSpinning = async () => {
    setMustSpin(false);
    onStopSpinningCallback();
  };
  // For Roulette
  const backgroundColors = ["#e0080b", "#000000"];
  const textColors = ["#ffffff"];
  const outerBorderColor = "#3c1912";
  const outerBorderWidth = 10;
  const innerBorderColor = "#3c1912";
  const innerBorderWidth = 5;
  const innerRadius = 10;
  const radiusLineColor = "#f3c620";
  const radiusLineWidth = 2;
  const fontFamily = "Roboto";
  const fontWeight = "bold";
  const fontSize = 14;
  const fontStyle = "normal";
  const textDistance = 55;
  const spinDuration = 0.25;

  return (
    <VStack w={"full"} py={5} px={0}>
      <Wheel
        mustStartSpinning={mustSpin}
        data={data}
        prizeNumber={prizeNumber}
        backgroundColors={backgroundColors}
        textColors={textColors}
        fontFamily={fontFamily}
        fontSize={fontSize}
        fontWeight={fontWeight}
        fontStyle={fontStyle}
        outerBorderColor={outerBorderColor}
        outerBorderWidth={outerBorderWidth}
        innerRadius={innerRadius}
        innerBorderColor={innerBorderColor}
        innerBorderWidth={innerBorderWidth}
        radiusLineColor={radiusLineColor}
        radiusLineWidth={radiusLineWidth}
        spinDuration={spinDuration}
        startingOptionIndex={startingOption}
        // perpendicularText
        textDistance={textDistance}
        onStopSpinning={onStopSpinning}
      />
      {!hideButton && (
        <Button w={"full"} disabled={mustSpin} onClick={handleSpinClick}>
          {buttonText}
        </Button>
      )}
    </VStack>
  );
};

export { Roulette };
