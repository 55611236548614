import {
  Box,
  BoxProps,
  HStack,
  Heading,
  Text,
  VStack,
  useColorModeValue,
  useMediaQuery,
} from "@chakra-ui/react";
import { vimeoVideo } from "../../types/activity";
import Vimeo from "./Vimeo";

interface VimeoContainerProps extends BoxProps {
  video: vimeoVideo;
  title: string;
  description: string;
  videoWidth: number;
  videoHeight?: number;
}

const VimeoContainer: React.FC<VimeoContainerProps> = ({
  video,
  title,
  description,
  videoHeight,
  videoWidth,
}) => {
  const boxColorMode = useColorModeValue(
    "ssBoxBackgroundLight",
    "ssBoxBackgroundDark"
  );
  const [hamburgerMode] = useMediaQuery("(max-width: 768px)");

  if (hamburgerMode) {
    return (
      <Box bg={boxColorMode} w="full" p={3} borderRadius={10}>
        <VStack w="full" alignItems="flex-start">
          <Heading as="h4" size="sm">
            Watch: {title}
          </Heading>
          <Text>{description}</Text>
          <Vimeo video={video} height={videoHeight} width={videoWidth} />
        </VStack>
      </Box>
    );
  }

  return (
    <Box bg={boxColorMode} w="full" p={2} borderRadius={10}>
      <HStack w="full" alignItems="flex-start">
        <Box minWidth={videoWidth}>
          <Vimeo video={video} height={videoHeight} width={videoWidth} />
        </Box>
        <VStack alignItems="flex-start" pl={2}>
          <Heading as="h4" size="sm">
            Watch: {title}
          </Heading>
          <Text>{description}</Text>
        </VStack>
      </HStack>
    </Box>
  );
};

export default VimeoContainer;
