import { Box, BoxProps, Tooltip, useColorModeValue } from "@chakra-ui/react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useAppSelector } from "../../app/hooks";
import { selectAuthState } from "../../features/auth/authSlice";
import { checkRole } from "../../utils/authHelper";
import { NotAuthorised } from "../generic/NotAuthorised";

interface ExecutionScoreGraphProps extends BoxProps {
  score: number;
  size: number;
  upgrade?: boolean;
}

const ExecutionScoreGraph: React.FC<ExecutionScoreGraphProps> = ({
  score,
  size,
  upgrade,
  ...rest
}) => {
  const { access: accessToken } = useAppSelector(selectAuthState);
  const pathColour = useColorModeValue("#A419BC", "#E755FF");
  const textColour = useColorModeValue("#000000", "#FFFFFF");
  const trailColour = useColorModeValue("#AAAAAA", "#666666");

  if (!checkRole(accessToken, "executionScore", "activity")) {
    if (upgrade) {
      return (
        <NotAuthorised functionText={"Session Execution"} size={"small"} />
      );
    }
    return null;
  }

  return (
    <Tooltip hasArrow label="Execution Score">
      <Box style={{ width: size, height: size }} {...rest}>
        {score > -1 && (
          <CircularProgressbar
            value={score}
            text={`${score}`}
            strokeWidth={10}
            styles={buildStyles({
              textSize: "25px",
              strokeLinecap: "butt",
              trailColor: trailColour,
              pathColor: pathColour,
              textColor: textColour,
            })}
          />
        )}
        {score === -1 && (
          <CircularProgressbar
            value={0}
            text={`-`}
            strokeWidth={10}
            styles={buildStyles({
              textSize: "25px",
              strokeLinecap: "butt",
              trailColor: trailColour,
              pathColor: pathColour,
              textColor: textColour,
            })}
          />
        )}
      </Box>
    </Tooltip>
  );
};

export { ExecutionScoreGraph };
