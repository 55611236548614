import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { wbssWeek } from "../../types/session";

export const loadSize = 5;
// Define the state of the slice as an object
export interface WbssWeeksState {
  lastUpdated: Date;
  lastLoad: number;
  lastWeek: string;
  weeks: wbssWeek[];
}

export interface WbssWeeksStateUpdate {
  lastWeek: string;
  lastLoad: number;
  weeks: wbssWeek[];
}

// Define an initial state
const initialState: WbssWeeksState = {
  lastUpdated: new Date("1970-01-01T00:00:00.000"),
  lastLoad: loadSize,
  lastWeek: "",
  weeks: [],
};

// Create a slice containing the configuration of the state
// and the reducers functions
const wbssWeeksSlice = createSlice({
  name: "wbssWeeksState",
  initialState,
  reducers: {
    updateWbssWeeksState(
      state,
      action: PayloadAction<WbssWeeksStateUpdate | null>
    ) {
      if (action.payload) {
        state.lastUpdated = new Date();
        state.lastWeek = action.payload.lastWeek;
        state.lastLoad = action.payload.lastLoad;
        state.weeks = action.payload.weeks;
      } else {
        state.lastUpdated = initialState.lastUpdated;
        state.lastWeek = initialState.lastWeek;
        state.lastLoad = initialState.lastLoad;
        state.weeks = initialState.weeks;
      }
    },
  },
});

// Export each reducers function defined in createSlice
export const updateWbssWeeksState = wbssWeeksSlice.actions.updateWbssWeeksState;

export const selectWbssWeeksState = (state: RootState): WbssWeeksState => {
  return state.wbssWeeksState;
};

// Export default the slice reducer
export default wbssWeeksSlice.reducer;
