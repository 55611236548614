import { Box, BoxProps, Tooltip, useColorModeValue } from '@chakra-ui/react';
import { GarminLogo } from '../svg/GarminLogo';
import { SwimSmoothLogo } from '../svg/SwimSmoothLogo';

interface ActivityProviderProps extends BoxProps {
  size: number;
  provider: string;
}

const ActivityProvider: React.FC<ActivityProviderProps> = ({ provider, size }) => {
  const logoColour = useColorModeValue('#002140', '#78DFFF');
  if (provider === 'SWIMSMOOTH') {
    return (
      <Tooltip hasArrow label="Source: Swim Smooth App">
        <span>
          <SwimSmoothLogo height={size} width={size} colour={logoColour} />
        </span>
      </Tooltip>
    );
  } else if (provider === 'GARMIN') {
    return (
      <Tooltip hasArrow label="Source: Garmin Connect">
        <span>
          <GarminLogo height={size} width={size * 1.5} colour={logoColour} />
        </span>
      </Tooltip>
    );
  }
  return (
    <Tooltip hasArrow label="Source: Manual">
      <span>
        <Box>&nbsp;</Box>
      </span>
    </Tooltip>
  );
};

export { ActivityProvider };
