import { BoxProps, useColorModeValue } from "@chakra-ui/react";
import {
  Bar,
  BarChart,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useAppSelector } from "../../app/hooks";
import { selectAuthState } from "../../features/auth/authSlice";
import { activityAnalysisSwimtelligenceSwimType } from "../../types/activity";
import { checkRole } from "../../utils/authHelper";
import { ucFirst } from "../../utils/coreHelper";
import { NotAuthorised } from "../generic/NotAuthorised";
import { CustomTooltipPercent } from "../ui/CustomTooltipPercent";

interface SwimTypesGraphProps extends BoxProps {
  data: activityAnalysisSwimtelligenceSwimType[];
  upgrade?: boolean;
}

const SwimTypesGraph: React.FC<SwimTypesGraphProps> = ({ data, upgrade }) => {
  const { access: accessToken } = useAppSelector(selectAuthState);
  const labelColorMode = useColorModeValue("#000000", "#FFFFFF");
  const dataColorMode = useColorModeValue("#A419BC", "#E755FF");

  if (!checkRole(accessToken, "swimTypes", "activity")) {
    if (upgrade) {
      return (
        <NotAuthorised
          functionText={"Swim Type Analysis, a part of Stroke Insights"}
          size={"small"}
        />
      );
    }
    return null;
  }

  return (
    <>
      <ResponsiveContainer width="100%" height={250}>
        <BarChart
          data={data}
          margin={{
            top: 0,
            right: 20,
            left: 0,
            bottom: 0,
          }}
        >
          <XAxis
            dataKey="name"
            interval={0}
            stroke={labelColorMode}
            fontSize="8pt"
            tickFormatter={(value: any) => {
              return ucFirst(value);
            }}
          />
          <YAxis
            dataKey="value"
            label={{
              value: "Likelihood",
              angle: -90,
              position: "outsideLeft",
              fill: labelColorMode,
              dx: -10,
            }}
            stroke={labelColorMode}
            fontSize="8pt"
          />
          <Tooltip content={<CustomTooltipPercent />} />
          <Legend layout="horizontal" verticalAlign="top" align="center" />
          <Bar
            type="basis"
            dataKey="value"
            fill={dataColorMode}
            name="Likelihood"
          />
        </BarChart>
      </ResponsiveContainer>
    </>
  );
};
export { SwimTypesGraph };
