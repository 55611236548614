import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { session } from "../../types/session";

// Define the state of the slice as an object
export interface VirtualSquadState {
  lastUpdated: Date;
  sessions: session[];
}

// Define an initial state
const initialState: VirtualSquadState = {
  lastUpdated: new Date("1970-01-01T00:00:00.000"),
  sessions: [],
};

// Create a slice containing the configuration of the state
// and the reducers functions
const virtualSquadSlice = createSlice({
  name: "virtualSquadState",
  initialState,
  reducers: {
    updateVirtualSquadState(state, action: PayloadAction<session[] | null>) {
      if (action.payload) {
        state.lastUpdated = new Date();
        state.sessions = action.payload;
      } else {
        state.lastUpdated = initialState.lastUpdated;
        state.sessions = initialState.sessions;
      }
    },
    addSessionToVirtualSquadState(state, action: PayloadAction<session>) {
      state.lastUpdated = new Date();
      const checkSession = state.sessions.filter(
        (s: session) => s.id === action.payload.id
      );
      if (checkSession.length === 0) {
        state.sessions.push(action.payload);
      }
    },
  },
});

// Export each reducers function defined in createSlice
export const updateVirtualSquadState =
  virtualSquadSlice.actions.updateVirtualSquadState;
export const addSessionToVirtualSquadState =
  virtualSquadSlice.actions.addSessionToVirtualSquadState;

export const selectVirtualSquadState = (
  state: RootState
): VirtualSquadState => {
  return state.virtualSquadState;
};

// Export default the slice reducer
export default virtualSquadSlice.reducer;
