import { Box, Heading, Text } from "@chakra-ui/react";
import { useColorModeValue } from "@chakra-ui/system";
import { TooltipProps } from "recharts";
import { NameType, ValueType } from "recharts/types/component/DefaultTooltipContent";
import { formatPace } from "../../utils/dateHelper";

const CustomTooltipDate: React.FC<TooltipProps<ValueType, NameType>> = ({ active, payload, label }) => {
  const tooltipColorMode = useColorModeValue("ssBoxBackgroundLight", "ssBoxBackgroundDark");
  const tooltipBorderColorMode = useColorModeValue("ssPrimaryDark", "ssPrimaryLight");

  if (active && payload && payload.length) {
    return (
      <Box bg={tooltipColorMode} p={5} borderWidth={3} borderColor={tooltipBorderColorMode} borderRadius={5}>
        <Heading as="h5" size="small">
          {new Intl.DateTimeFormat("en-GB", {
            year: "numeric",
            month: "long",
            day: "numeric",
          }).format(new Date(label))}
        </Heading>
        {payload.map((payload: any, index: number) => {
          if (payload.dataKey === "m.secondsPer100" || payload.dataKey === "y.secondsPer100") {
            return (
              <Text key={index}>
                {payload.name}: {formatPace(parseFloat(payload.value as string))}
              </Text>
            );
          }
          return (
            <Text key={index}>
              {payload.name}: {payload.value}
            </Text>
          );
        })}
      </Box>
    );
  }

  return null;
};

export { CustomTooltipDate };
