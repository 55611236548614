import { Heading, Text, VStack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Loading from "../../components/ui/Loading";
import { forceRefreshToken } from "../../utils/authHelper";

const AccountSubscriptionConfirmedPage = () => {
  const { checkoutSessionId } = useParams<{
    checkoutSessionId: string;
  }>();
  const [loading, setLoading] = useState<Boolean>(true);
  const dispatch = useDispatch();

  // Refresh User Object
  useEffect(() => {
    const forceRefreshLocal = async () => {
      await forceRefreshToken(dispatch);
      setLoading(false);
    };
    forceRefreshLocal();
  });

  if (loading) {
    return <Loading message={"Saving subscription details"} />;
  }

  console.log(checkoutSessionId);

  return (
    <VStack w={"full"}>
      <Heading>Thank you for subscribing</Heading>
      <Text>
        We're a small team passionate about improving your swimming, your
        coaching, your triathlon and you so thanks for putting your trust in us.
        You'll receive a series of emails over the coming week introducing you
        to all the features of the Guru but for now we'd suggesting heading to
        'Library' or 'Sessions' from the header menu and have a look around.
      </Text>
    </VStack>
  );
};

export { AccountSubscriptionConfirmedPage };
