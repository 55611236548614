import axios from "axios";
import { processSwimSmoothApiError } from "../components/generic/ErrorProcessing";
import { cssEntry } from "../types/css";

const tweakCss = async (
  value: number,
  lapLength: number,
  lapUnit: string
): Promise<any> => {
  try {
    let url = process.env.REACT_APP_API_ROOT_URL + "settings/css";
    const result = await axios.post(url, {
      type: "tweak",
      date: new Date(),
      value: value,
      lapLength: lapLength,
      lapUnit: lapUnit,
    });
    if (result.data.success) {
      return result.data.payload;
    }
  } catch (error: any) {
    throw new Error(processSwimSmoothApiError(error.response.data));
  }
  return null;
};

const createCssManual = async (
  value: number,
  lapLength: number,
  lapUnit: string
): Promise<any> => {
  try {
    let url = process.env.REACT_APP_API_ROOT_URL + "settings/css";
    const result = await axios.post(url, {
      type: "manual",
      date: new Date(),
      value: value,
      lapLength: lapLength,
      lapUnit: lapUnit,
    });
    if (result.data.success) {
      return result.data.payload;
    }
  } catch (error: any) {
    throw new Error(processSwimSmoothApiError(error.response.data));
  }
  return null;
};

const createCssTest = async (
  tt400: number,
  tt200: number,
  tt100: number,
  lapLength: number,
  lapUnit: string
): Promise<any> => {
  try {
    let url = process.env.REACT_APP_API_ROOT_URL + "settings/css";
    const result = await axios.post(url, {
      type: "test",
      date: new Date(),
      lapLength: lapLength,
      lapUnit: lapUnit,
      test: {
        tt400: tt400,
        tt200: tt200,
        tt100: tt100,
      },
    });
    if (result.data.success) {
      return result.data.payload;
    }
  } catch (error: any) {
    throw new Error(processSwimSmoothApiError(error.response.data));
  }
  return null;
};

const getCssCurrent = async (): Promise<cssEntry> => {
  try {
    let url = process.env.REACT_APP_API_ROOT_URL + "settings/css/current";
    const result = await axios.get(url);
    return result.data.payload;
  } catch (error: any) {
    throw new Error(processSwimSmoothApiError(error.response.data));
  }
};

const getCssLastTest = async (): Promise<cssEntry> => {
  try {
    let url = process.env.REACT_APP_API_ROOT_URL + "settings/css/lasttest";
    const result = await axios.get(url);
    return result.data.payload;
  } catch (error: any) {
    throw new Error(processSwimSmoothApiError(error.response.data));
  }
};

const getCssHistory = async (): Promise<cssEntry[]> => {
  try {
    let url = process.env.REACT_APP_API_ROOT_URL + "settings/css/";
    const result = await axios.get(url);
    if (result.data.success) {
      return result.data.payload;
    }
  } catch (error: any) {
    throw new Error(processSwimSmoothApiError(error.response.data));
  }
  return [];
};

const getCssEntryById = async (cssEntryId: string): Promise<cssEntry> => {
  try {
    let url = process.env.REACT_APP_API_ROOT_URL + "settings/css/" + cssEntryId;
    const result = await axios.get(url);
    return result.data.payload;
  } catch (error: any) {
    throw new Error(processSwimSmoothApiError(error.response.data));
  }
};

const deleteCss = async (cssId: string): Promise<void> => {
  try {
    let url = process.env.REACT_APP_API_ROOT_URL + "settings/css/" + cssId;
    await axios.delete(url);
  } catch (error: any) {
    throw new Error(processSwimSmoothApiError(error.response.data));
  }
};

export {
  createCssManual,
  createCssTest,
  deleteCss,
  getCssCurrent,
  getCssEntryById,
  getCssHistory,
  getCssLastTest,
  tweakCss,
};
