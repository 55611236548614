import {
  Box,
  Button,
  HStack,
  Heading,
  Icon,
  Image,
  ListItem,
  SimpleGrid,
  Text,
  UnorderedList,
  VStack,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import * as contentful from "contentful";
import { useEffect, useState } from "react";
import { FaLock } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { selectAuthState } from "../../features/auth/authSlice";
import { user } from "../../types/user";
import { checkLevelInArray, getLevelInSentence } from "../../utils/authHelper";
import { ucFirst } from "../../utils/coreHelper";
import { createToast } from "../../utils/toastHelper";
import { CustomToast } from "../ui/CustomToast";
import Loading from "../ui/Loading";

const client = contentful.createClient({
  space: "50b15ahactsg",
  accessToken: "rYtEmKRrWoeFQM4pR8Ot8SZV7uC8OERTel461AQ3kvk",
});

interface LibraryCategoryItemProps {
  user: user;
  entityId: string;
}

const LibraryCategoryItem: React.FC<LibraryCategoryItemProps> = ({
  user,
  entityId,
}) => {
  const { access: accessToken } = useAppSelector(selectAuthState);
  const [linked, setLinked] = useState<any | null>(null);
  const boxColorMode = useColorModeValue(
    "ssBoxBackgroundLight",
    "ssBoxBackgroundDark"
  );
  const intensityColorMode = useColorModeValue(
    "ssNeonOrangeDark",
    "ssNeonOrangeLight"
  );
  const toast = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    const getEntityLocal = async () => {
      const linkedResult = await client.getEntry(entityId);
      setLinked(linkedResult);
    };
    getEntityLocal();
  }, [entityId]);

  if (!linked) {
    return <Loading />;
  }

  return (
    <Box
      cursor="pointer"
      bg={boxColorMode}
      borderRadius={10}
      opacity={!checkLevelInArray(accessToken, linked.fields.levels) ? 0.5 : 1}
      onClick={() => {
        if (checkLevelInArray(accessToken, linked.fields.levels)) {
          navigate(window.location.pathname + "/" + linked.sys.id, {
            replace: false,
          });
        } else {
          createToast(toast, (props: any) => {
            return (
              <CustomToast
                title={"Upgrade Required"}
                status={"Warning"}
                toast={toast}
                toastId={props.id}
              >
                {linked.fields.levels && (
                  <>
                    <Text>
                      '{linked.fields.title}' requires one of the following
                      subscriptions,
                    </Text>
                    <UnorderedList>
                      {linked.fields.levels.map((l: string) => {
                        return <ListItem>{l}</ListItem>;
                      })}
                    </UnorderedList>
                    <Text>
                      You currently have {getLevelInSentence(accessToken)}.
                    </Text>
                  </>
                )}
                <Button
                  w={"full"}
                  mt={2}
                  onClick={() => {
                    navigate("/account/subscription", { replace: false });
                    toast.closeAll();
                  }}
                >
                  Upgrade Now
                </Button>
              </CustomToast>
            );
          });
        }
      }}
    >
      <HStack alignItems="flex-start">
        <Image
          src={
            linked.fields.image &&
            linked.fields.image.fields &&
            linked.fields.image.fields.file.url
              ? linked.fields.image.fields.file.url
              : linked.fields.imageUrl
          }
          borderLeftRadius={10}
          h={200}
          w={200}
          fit="cover"
          fallbackSrc="https://images.ctfassets.net/50b15ahactsg/Al70qWgtjQvAjRifFlBIq/35448e67bbf63873261153bf268d3764/Paul_Squad.jpg"
        />
        <VStack p={2} alignItems="flex-start" w="full">
          <Text fontSize="xs">
            <b>{ucFirst(linked.sys.contentType.sys.id)}</b>
          </Text>
          <Heading noOfLines={2} size="md" as="h3">
            {linked.fields.title}
          </Heading>
          {checkLevelInArray(accessToken, linked.fields.levels) && (
            <Text noOfLines={2}>{linked.fields.shortDescription}</Text>
          )}
          {!linked.fields.levels && (
            <>
              <Text fontSize={"small"}>
                <Icon
                  h={15}
                  w={15}
                  mr={2}
                  as={FaLock}
                  color={intensityColorMode}
                />
                This content requires a higher subscription level
              </Text>
              <UnorderedList mt={-2} fontSize={"small"}>
                <SimpleGrid columns={2}>
                  {linked.fields.levels?.map((s: string) => {
                    return <ListItem key={s.toLowerCase()}>{s}</ListItem>;
                  })}
                </SimpleGrid>
              </UnorderedList>
            </>
          )}
          {!checkLevelInArray(accessToken, linked.fields.levels) && (
            <>
              <Text fontSize={"small"}>
                <Icon
                  h={15}
                  w={15}
                  mr={2}
                  as={FaLock}
                  color={intensityColorMode}
                />
                This content requires:
              </Text>
              <UnorderedList mt={-2} fontSize={"small"}>
                <SimpleGrid columns={2}>
                  {linked.fields.levels?.map((s: string) => {
                    return <ListItem key={s.toLowerCase()}>{s}</ListItem>;
                  })}
                </SimpleGrid>
              </UnorderedList>
            </>
          )}
        </VStack>
      </HStack>
    </Box>
  );
};

export { LibraryCategoryItem };
