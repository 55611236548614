import {
  Button,
  Heading,
  HStack,
  Icon,
  Image,
  ListItem,
  SimpleGrid,
  Text,
  UnorderedList,
  useColorModeValue,
  useToast,
  VStack,
} from "@chakra-ui/react";
import * as contentful from "contentful";
import { useContext, useEffect, useState } from "react";
import { FaLock } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectAuthState } from "../../features/auth/authSlice";
import {
  selectAreasState,
  updateAreasState,
} from "../../features/library/areasSlice";
import { selectUserState } from "../../features/user/userSlice";
import { checkLevelInArray, getLevelInSentence } from "../../utils/authHelper";
import { createToast } from "../../utils/toastHelper";
import {
  BreadcrumbContext,
  breadcrumbContextType,
} from "../context/BreadcrumbContext";
import { CustomToast } from "../ui/CustomToast";
import Loading from "../ui/Loading";

const client = contentful.createClient({
  space: "50b15ahactsg",
  accessToken: "rYtEmKRrWoeFQM4pR8Ot8SZV7uC8OERTel461AQ3kvk",
});

const LibraryAreas = () => {
  const { setBreadcrumbLinks } =
    useContext<breadcrumbContextType>(BreadcrumbContext);
  const { access: accessToken } = useAppSelector(selectAuthState);
  const { user } = useAppSelector(selectUserState);
  const { areas, lastUpdated } = useAppSelector(selectAreasState);
  const [loading, setLoading] = useState<boolean>(true);
  const intensityColorMode = useColorModeValue(
    "ssNeonOrangeDark",
    "ssNeonOrangeLight"
  );
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const toast = useToast();

  useEffect(() => {
    const breadcrumbLinks = [{ href: "/library", title: "Library" }];
    setBreadcrumbLinks(breadcrumbLinks);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getEntriesLocal = async () => {
      const entries = await client.getEntries({
        content_type: "area",
      });
      dispatch(
        updateAreasState({
          areas: entries.items.sort((a: any, b: any) => {
            return a.fields.order - b.fields.order;
          }),
        })
      );
      setLoading(false);
    };
    if (lastUpdated) {
      const test = new Date(lastUpdated).getTime();
      const anHourAgo = new Date().getTime() - 1000 * 60 * 60;
      if (!areas || areas.length === 0 || anHourAgo > test) {
        getEntriesLocal();
      } else {
        setLoading(false);
      }
    } else {
      getEntriesLocal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <Loading />;
  }

  if (!user) {
    return null;
  }

  return (
    <SimpleGrid columns={[1, 1, 2, 2]} gap={2} w="full">
      {areas.map((a: any) => {
        if (!checkLevelInArray(accessToken, a.fields.levels)) {
          return (
            <HStack key={a.sys.id} pb={10} alignItems="flex-start">
              <Image mr={5} h={10} src={a.fields.icon.fields.file.url} />
              <VStack alignItems="flex-start" w="full">
                <Heading size="small">{a.fields.title}</Heading>
                <Text size="xs" color="grey">
                  {a.fields.shortDescription}
                </Text>
                {a.fields.categories.map((c: any) => {
                  return (
                    <Button
                      minWidth={0}
                      key={c.sys.id}
                      onClick={() => {
                        createToast(toast, (props: any) => {
                          return (
                            <CustomToast
                              title={"Upgrade Required"}
                              status={"Warning"}
                              toast={toast}
                              toastId={props.id}
                            >
                              {c.fields.levels && (
                                <>
                                  <Text>
                                    '{c.fields.title}' requires one of the
                                    following subscriptions,
                                  </Text>
                                  <UnorderedList>
                                    {c.fields.levels.map(
                                      (l: string, index: number) => {
                                        return (
                                          <ListItem key={index}>{l}</ListItem>
                                        );
                                      }
                                    )}
                                  </UnorderedList>
                                  <Text>
                                    You currently have{" "}
                                    {getLevelInSentence(accessToken)}.
                                  </Text>
                                </>
                              )}
                              <Button
                                w={"full"}
                                mt={2}
                                onClick={() => {
                                  navigate("/account/subscription", {
                                    replace: false,
                                  });
                                  toast.closeAll();
                                }}
                              >
                                Upgrade Now
                              </Button>
                            </CustomToast>
                          );
                        });
                      }}
                      variant="link"
                    >
                      <Icon
                        h={15}
                        w={15}
                        mr={2}
                        as={FaLock}
                        color={intensityColorMode}
                      />
                      {c.fields.title}
                    </Button>
                  );
                })}
              </VStack>
            </HStack>
          );
        }
        return (
          <HStack key={a.sys.id} pb={10} alignItems="flex-start">
            <Image mr={5} h={10} src={a.fields.icon.fields.file.url} />
            <VStack alignItems="flex-start" w="full">
              <Heading
                size="small"
                onClick={() => {
                  navigate("/library/" + a.sys.id, {
                    replace: false,
                  });
                }}
                cursor={"pointer"}
              >
                {a.fields.title}
              </Heading>
              <Text size="xs" color="grey">
                {a.fields.shortDescription}
              </Text>
              {a.fields.categories.map((c: any) => {
                if (!checkLevelInArray(accessToken, c.fields.levels)) {
                  return (
                    <Button
                      minWidth={0}
                      key={c.sys.id}
                      onClick={() => {
                        createToast(toast, (props: any) => {
                          return (
                            <CustomToast
                              title={"Upgrade Required"}
                              status={"Warning"}
                              toast={toast}
                              toastId={props.id}
                            >
                              {c.fields.levels && (
                                <>
                                  <Text>
                                    '{c.fields.title}' requires one of the
                                    following subscriptions,
                                  </Text>
                                  <UnorderedList>
                                    {c.fields.levels.map(
                                      (l: string, index: number) => {
                                        return (
                                          <ListItem key={index}>{l}</ListItem>
                                        );
                                      }
                                    )}
                                  </UnorderedList>
                                  <Text>
                                    You currently have{" "}
                                    {getLevelInSentence(accessToken)}.
                                  </Text>
                                </>
                              )}
                              <Button
                                w={"full"}
                                mt={2}
                                onClick={() => {
                                  navigate("/account/subscription", {
                                    replace: false,
                                  });
                                  toast.closeAll();
                                }}
                              >
                                Upgrade Now
                              </Button>
                            </CustomToast>
                          );
                        });
                      }}
                      variant="link"
                    >
                      <Icon
                        h={15}
                        w={15}
                        mr={2}
                        as={FaLock}
                        color={intensityColorMode}
                      />
                      {c.fields.title}
                    </Button>
                  );
                }
                return (
                  <Button
                    minWidth={0}
                    key={c.sys.id}
                    onClick={() => {
                      navigate("/library/" + a.sys.id + "/" + c.sys.id, {
                        replace: false,
                      });
                    }}
                    variant="link"
                  >
                    {c.fields.title}
                  </Button>
                );
              })}
            </VStack>
          </HStack>
        );
      })}
    </SimpleGrid>
  );
};

export { LibraryAreas };
