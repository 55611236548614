import { Box, LightMode } from '@chakra-ui/react';
import { Helmet } from 'react-helmet';

const EmbedLayout = (props: any) => {
  return (
    <LightMode>
      <Helmet>
        <style>{'body { background-color: white; }'}</style>
      </Helmet>
      <Box p={10}>{props.children}</Box>
    </LightMode>
  );
};

export { EmbedLayout };
