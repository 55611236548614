import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clearState } from "../../utils/authHelper";
import Loading from "../ui/Loading";

const Logout: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    // Redux State
    clearState(dispatch, true);
    // Go back to landing page
    navigate("/", { replace: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Loading message="Logging Out!" />;
};

export { Logout };
