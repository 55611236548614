import { Box, BoxProps, Center, VStack } from "@chakra-ui/react";
import { useColorModeValue } from "@chakra-ui/system";

interface SetRepeatProps extends BoxProps {
  repetitions: any;
}

const SetRepeat: React.FC<SetRepeatProps> = ({ repetitions, ...props }) => {
  const repeatColorMode = useColorModeValue("ssNeonPinkDark", "ssNeonPinkDark");
  if (repetitions > 1) {
    return (
      <VStack w="full" border="2px" borderColor={repeatColorMode} borderRadius={10}>
        <Box bg={repeatColorMode} w="full" color="white" borderTopRadius={7}>
          <Center>
            <b>Repeat x {repetitions}</b>
          </Center>
        </Box>
        {props.children}
      </VStack>
    );
  }
  return (
    <VStack w="full" border="0px">
      {props.children}
    </VStack>
  );
};

export { SetRepeat };
