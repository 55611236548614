import {
  Box,
  Button,
  Center,
  Heading,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { Navigate, useSearchParams } from "react-router-dom";
import { getSessionsByType } from "../../DataAccess/sessions";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectActivityState } from "../../features/activity/activitySlice";
import { selectAuthState } from "../../features/auth/authSlice";
import { updateSessionState } from "../../features/session/sessionSlice";
import {
  loadSize,
  selectSessionsState,
  updateSessionsState,
} from "../../features/session/sessionsSlice";
import { selectUserState } from "../../features/user/userSlice";
import { session, sessionType } from "../../types/session";
import { getLevel } from "../../utils/authHelper";
import {
  BreadcrumbContext,
  breadcrumbContextType,
} from "../context/BreadcrumbContext";
import Pricing from "../pricing/Pricing";
import Loading from "../ui/Loading";
import { SessionListItem } from "./SessionListItem";

interface SessionListProps {
  sessionType: sessionType;
}

const SessionList: React.FC<SessionListProps> = ({ sessionType }) => {
  const [search] = useSearchParams();
  const linkActivityId = search.get("linkActivityId");
  const {
    sessions,
    sessionType: cachedSessionType,
    lastUpdated,
    lastSession,
    lastLoad,
  } = useAppSelector(selectSessionsState);
  const [loadingMore, setLoadingMore] = useState<boolean>(false);
  const { setBreadcrumbLinks } =
    useContext<breadcrumbContextType>(BreadcrumbContext);

  const { user } = useAppSelector(selectUserState);
  const { access: accessToken } = useAppSelector(selectAuthState);
  const level = getLevel(accessToken);
  const { activity } = useAppSelector(selectActivityState);
  const [loading, setLoading] = useState<boolean>(true);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (sessionType) {
      const breadcrumbLinks = [
        { href: "/sessions", title: "Sessions" },
        { href: "/sessions/" + sessionType, title: sessionType.title },
      ];
      setBreadcrumbLinks(breadcrumbLinks);
    } else {
      const breadcrumbLinks = [
        { href: "/sessions", title: "Sessions" },
        { href: "/sessions/" + sessionType, title: " - " },
      ];
      setBreadcrumbLinks(breadcrumbLinks);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionType]);

  useEffect(() => {
    const getSessionsLocal = async () => {
      const { sessions, last } = await getSessionsByType(sessionType.code);
      dispatch(
        updateSessionsState({
          sessionType: sessionType.code,
          sessions: sessions,
          lastLoad: sessions.length,
          lastSession: last,
        })
      );
      setLoading(false);
    };
    if (lastUpdated) {
      const test = new Date(lastUpdated).getTime();
      const aDayAgo = new Date().getTime() - 1000 * 60 * 60 * 24;
      if (
        sessionType.code !== cachedSessionType ||
        !sessions ||
        sessions.length === 0 ||
        aDayAgo > test
      ) {
        getSessionsLocal();
      } else {
        setLoading(false);
      }
    } else {
      getSessionsLocal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const nextPage = () => {
    setLoadingMore(true);
    dispatch(updateSessionState(null));
    const getSessionsLocal = async () => {
      const { sessions: newSessions, last } = await getSessionsByType(
        sessionType.code,
        lastSession
      );
      const combinedSessions = [...sessions, ...newSessions];
      dispatch(
        updateSessionsState({
          sessionType: sessionType.code,
          lastSession: last,
          lastLoad: newSessions.length,
          sessions: combinedSessions,
        })
      );
      setLoading(false);
      setLoadingMore(false);
    };
    getSessionsLocal();
  };

  if (loading) {
    return <Loading />;
  }

  if (!user) {
    return <Navigate to={{ pathname: "/sessions" }} />;
  }

  if (!sessionType) {
    return <Navigate to={{ pathname: "/sessions" }} />;
  }

  return (
    <>
      {linkActivityId && activity && linkActivityId === activity.id && (
        <Box
          bg="seagreen"
          w="full"
          px={5}
          py={2}
          alignItems="center"
          borderRadius={5}
          mb={4}
        >
          <Text>Linking to &#8220;{activity.data.name}&#8221;</Text>
        </Box>
      )}
      {sessions.length > 0 && (
        <VStack w={"full"} alignItems={"flex-start"}>
          <SimpleGrid columns={[1, 1, 2, 2]} gap={[2, 2, 4, 4]} w="full">
            {sessions.map((s: session) => {
              return (
                <SessionListItem
                  key={s.id}
                  user={user}
                  sessionType={sessionType}
                  session={s}
                  linkActivityId={linkActivityId}
                />
              );
            })}
          </SimpleGrid>
        </VStack>
      )}
      {sessions.length === 0 && (
        <>
          <Heading size={"md"}>No Sessions Available</Heading>
          <Text>
            Some of our core sessions are available at our super-affordable
            Understanding level for less than the price of a post-swim coffee
            per month. Want every session in our vast library of over 1,000
            sessions and the ability to randomly select one each day with the
            cool Session Roulette wheel? You’ll need our Routine level - yours
            for just two post-swim coffees per month. Swimming Smooth has never
            been so affordable and fun!
          </Text>
        </>
      )}
      {sessions.length > 0 &&
        (level === "Guidance" || level === "Understanding") && (
          <>
            <Heading size={"md"} mt={5}>
              Upgrade for more sessions
            </Heading>
            <Text>
              Some of our core sessions are available at our super-affordable
              Understanding level for less than the price of a post-swim coffee
              per month. Want every session in our vast library of over 1,000
              sessions and the ability to randomly select one each day with the
              cool Session Roulette wheel? You’ll need our Routine level - yours
              for just two post-swim coffees per month. Swimming Smooth has
              never been so affordable and fun!
            </Text>
          </>
        )}
      {(sessions.length === 0 ||
        level === "Guidance" ||
        level === "Understanding") && <Pricing />}
      {lastLoad === loadSize && (
        <Center>
          <Button isLoading={loadingMore} onClick={nextPage}>
            Load More
          </Button>
        </Center>
      )}
    </>
  );
};

export { SessionList };
