import { Text, VStack } from "@chakra-ui/react";
import { IconType } from "react-icons";
import { MdLabelOutline } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { SSMenuIcon } from "./SSMenuIcon";

interface SSMenuOptionProps {
  icon?: IconType;
  target?: string;
  onClick?: any;
  title: string;
}

const SSMenuOption: React.FC<SSMenuOptionProps> = ({
  icon,
  target,
  onClick,
  title,
}) => {
  const navigate = useNavigate();

  if (typeof icon === "undefined") {
    icon = MdLabelOutline;
  }

  if (!onClick && target) {
    onClick = () => navigate(target, { replace: false });
  }

  if (onClick) {
    return (
      <VStack
        cursor="pointer"
        display="flex"
        minWidth={0}
        alignItems="center"
        onClick={onClick}
        mt={10}
        mx={4}
      >
        <SSMenuIcon icon={icon} />
        <Text pl={1}>{title}</Text>
      </VStack>
    );
  }
  return (
    <VStack display="flex" minWidth={0} alignItems="center" mt={10} mx={3}>
      <SSMenuIcon icon={icon} />
      <Text pl={1}>{title}</Text>
    </VStack>
  );
};

export { SSMenuOption };
