import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { coach } from "../../types/coach";

export const loadSize = 10;
// Define the state of the slice as an object
export interface CoachesState {
  lastUpdated: Date;
  coaches: coach[];
  viewMode: string;
  region: string;
}

export interface CoachesStateUpdate {
  coaches: coach[];
  viewMode: string;
  region: string;
}

// Define an initial state
const initialState: CoachesState = {
  lastUpdated: new Date("1970-01-01T00:00:00.000"),
  coaches: [],
  viewMode: "list",
  region: "",
};

// Create a slice containing the configuration of the state
// and the reducers functions
const coachesSlice = createSlice({
  name: "coachesState",
  initialState,
  reducers: {
    updateCoachesState(state, action: PayloadAction<CoachesStateUpdate | null>) {
      if (action.payload) {
        state.lastUpdated = new Date();
        state.coaches = action.payload.coaches;
        state.viewMode = action.payload.viewMode;
        state.region = action.payload.region;
      } else {
        state.lastUpdated = initialState.lastUpdated;
        state.coaches = initialState.coaches;
        state.viewMode = initialState.viewMode;
        state.region = initialState.region;
      }
    },
    updateCoachesRegionState(state, action: PayloadAction<string | null>) {
      if (action.payload !== null) {
        state.lastUpdated = initialState.lastUpdated;
        state.region = action.payload;
      } else {
        state.lastUpdated = initialState.lastUpdated;
        state.region = initialState.region;
      }
    },
    updateCoachesViewModeState(state, action: PayloadAction<string | null>) {
      if (action.payload) {
        state.viewMode = action.payload;
      } else {
        state.viewMode = initialState.viewMode;
      }
    },
  },
});

// Export each reducers function defined in createSlice
export const updateCoachesState = coachesSlice.actions.updateCoachesState;
export const updateCoachesRegionState = coachesSlice.actions.updateCoachesRegionState;
export const updateCoachesViewModeState = coachesSlice.actions.updateCoachesViewModeState;

export const selectCoachesState = (state: RootState): CoachesState => {
  return state.coachesState;
};

// Export default the slice reducer
export default coachesSlice.reducer;
