import { EmailIcon, LinkIcon, PhoneIcon } from "@chakra-ui/icons";
import {
  Badge,
  Box,
  BoxProps,
  Button,
  Grid,
  HStack,
  Heading,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";
import parse from "html-react-parser";
import { useContext, useEffect, useState } from "react";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import { useNavigate } from "react-router-dom";
import { SocialIcon } from "react-social-icons";
import { getCoachByShortCode } from "../../DataAccess/coaches";
import { useAppSelector } from "../../app/hooks";
import { selectAuthState } from "../../features/auth/authSlice";
import { selectLocationState } from "../../features/location/locationSlice";
import { selectUserState } from "../../features/user/userSlice";
import { breadcrumbLink } from "../../types/breadcrumb";
import { coach, coachLocation, coachUrl } from "../../types/coach";
import { checkRole } from "../../utils/authHelper";
import {
  BreadcrumbContext,
  breadcrumbContextType,
} from "../context/BreadcrumbContext";
import { MessageDisplay } from "../generic/MessageDisplay";
import {
  endlessPool,
  office,
  openWater,
  other,
  pool,
} from "../leaflet/MapIcons";
import { SetBounds } from "../leaflet/SetBounds";
import ImageSlider from "../ui/ImageSlider";
import Loading from "../ui/Loading";

interface CoachesListProps extends BoxProps {
  coachShortCode: string;
}

const CoachDetail: React.FC<CoachesListProps> = ({ coachShortCode }) => {
  const { access: accessToken } = useAppSelector(selectAuthState);
  const [selectedCoach, setSelectedCoach] = useState<coach>();
  const navigate = useNavigate();
  const { setBreadcrumbLinks } =
    useContext<breadcrumbContextType>(BreadcrumbContext);

  const { user } = useAppSelector(selectUserState);

  const { latitude, longitude } = useAppSelector(selectLocationState);

  useEffect(() => {
    const getCoachLocal = async () => {
      const coachResponse = await getCoachByShortCode(coachShortCode);
      setSelectedCoach(coachResponse);
    };
    getCoachLocal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const breadcrumbLinks: breadcrumbLink[] = [
      { href: "/coaches", title: "Coaches" },
    ];
    if (selectedCoach) {
      if (selectedCoach.data.displayName) {
        breadcrumbLinks.push({
          href: "/coaches/" + selectedCoach.data.shortCode,
          title: selectedCoach.data.displayName,
        });
      } else {
        breadcrumbLinks.push({
          href: "/coaches/" + selectedCoach.data.shortCode,
          title:
            selectedCoach.data.firstName + " " + selectedCoach.data.lastName,
        });
      }
    }
    setBreadcrumbLinks(breadcrumbLinks);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCoach]);

  if (
    selectedCoach &&
    selectedCoach.data.shortCode &&
    selectedCoach.data.shortCode === coachShortCode
  ) {
    return (
      <VStack w="full" alignItems="left">
        {((user && user._id === selectedCoach.data.user) ||
          (user && checkRole(accessToken, "update", "admin"))) && (
          <Box>
            <Button
              onClick={() =>
                navigate(
                  "/coaches/" + selectedCoach.data.shortCode + "/edit/profile",
                  { replace: false }
                )
              }
            >
              Edit Profile
            </Button>
            <Button
              ml={2}
              onClick={() =>
                navigate(
                  "/coaches/" +
                    selectedCoach.data.shortCode +
                    "/edit/locations",
                  { replace: false }
                )
              }
            >
              Edit Locations
            </Button>
            {/* <Button ml={2} onClick={() => navigate("/coaches/" + selectedCoach.data.shortCode + "/edit/sessions", { replace: false })}>
              Edit Sessions
            </Button> */}
          </Box>
        )}
        <Grid
          gridTemplateColumns={["1fr", "1fr", "420px 1fr", "420px 1fr"]}
          w="full"
          columnGap={5}
          rowGap={2}
        >
          <CoachColumn coach={selectedCoach} />
          <Box w="full">
            <Box className="itemDescription" mb={2}>
              {parse(selectedCoach.data.description)}
            </Box>
            {selectedCoach.data.locations &&
              selectedCoach.data.locations.length > 0 && (
                <>
                  <Heading size="md" mb={2}>
                    Locations
                  </Heading>
                  <MapContainer
                    center={selectedCoach.data.distanceLocation}
                    zoom={12}
                    scrollWheelZoom={false}
                    style={{ height: "400px" }}
                  >
                    <TileLayer
                      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    {latitude && longitude && (
                      <Marker
                        key={"markerX"}
                        icon={other}
                        position={[latitude, longitude]}
                      >
                        <Popup>
                          <Text color="ssPrimaryDark">
                            <b>It's You!</b>
                          </Text>
                        </Popup>
                      </Marker>
                    )}
                    {selectedCoach.data.locations.map(
                      (location: coachLocation, index: number) => {
                        let icon;
                        switch (location.type) {
                          case "pool":
                            icon = pool;
                            break;
                          case "endlesspool":
                            icon = endlessPool;
                            break;
                          case "openwater":
                            icon = openWater;
                            break;
                          case "office":
                            icon = office;
                            break;
                          case "other":
                            icon = other;
                            break;
                        }
                        return (
                          <Marker
                            key={"marker" + index}
                            icon={icon}
                            position={location.ll}
                          >
                            <Popup>
                              <Text color="ssPrimaryDark">
                                <b>{location.name}</b>
                                <br />
                                <a
                                  href={"https://w3w.co/" + location.w3w}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {location.w3w}
                                </a>
                                <br />
                                {location.description}
                              </Text>
                            </Popup>
                          </Marker>
                        );
                      }
                    )}
                    <SetBounds locations={selectedCoach.data.locations} />
                  </MapContainer>
                  <HStack w="full" alignItems="flex-start" pt={3}>
                    <IconExplainer
                      src="https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-blue.png"
                      text="Pool"
                    />
                    <IconExplainer
                      src="https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png"
                      text="Endless Pool"
                    />
                    <IconExplainer
                      src="https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png"
                      text="Open Water"
                    />
                    <IconExplainer
                      src="https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-yellow.png"
                      text="Office"
                    />
                    <IconExplainer
                      src="https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-orange.png"
                      text="Other"
                    />
                  </HStack>
                </>
              )}
          </Box>
        </Grid>
      </VStack>
    );
  }

  if (selectedCoach && !selectedCoach.data.shortCode) {
    return (
      <VStack w="full">
        <MessageDisplay status="error" title="Could not find coach!">
          <Text>Could not find coach {coachShortCode}.</Text>
        </MessageDisplay>
        <Button onClick={() => navigate("/coaches", { replace: false })}>
          See All Coaches
        </Button>
      </VStack>
    );
  }

  return <Loading />;
};

const IconExplainer = (props: any) => {
  return (
    <VStack alignItems="center" w={100}>
      <Image src={props.src} h={8} />
      <Text>{props.text}</Text>
    </VStack>
  );
};
interface CoachColumnProps {
  coach: coach;
}

const CoachColumn: React.FC<CoachColumnProps> = ({ coach: selectedCoach }) => {
  return (
    <Box alignItems="flex-start" mr={10}>
      <HStack mb={5} alignItems="flex-start">
        <VStack alignItems="left" w="full">
          <Heading as="h2" size="xl">
            {selectedCoach.data.displayName ||
              selectedCoach.data.firstName + " " + selectedCoach.data.lastName}
          </Heading>
          <Text>
            {selectedCoach.data.brandName}, {selectedCoach.data.brandLocation}
          </Text>
        </VStack>
      </HStack>
      {selectedCoach.data.images.length > 0 && (
        <ImageSlider
          slides={selectedCoach.data.images}
          width={400}
          height={500}
        />
      )}
      {selectedCoach.data.images.length === 0 && (
        <ImageSlider
          slides={[
            {
              src: "https://images.ctfassets.net/50b15ahactsg/2bjEpuoUucy7OzxXezpZkt/2f92a378f22c69fbba09f6803b313ffd/460x0w.png",
            },
          ]}
          width={400}
          height={500}
        />
      )}
      <VStack alignItems="center" mt={5}>
        {selectedCoach.data.tags.includes("Head Coach") && (
          <Badge colorScheme="blue">Head Coach</Badge>
        )}
        {selectedCoach.data.tags.includes("Mentor") && (
          <Badge colorScheme="blue">Coach Educator / Mentor</Badge>
        )}
        {selectedCoach.data.tags.includes("Certified Coach") && (
          <Badge colorScheme="blue">Certified Coach</Badge>
        )}
        {selectedCoach.data.tags.includes("Assistant Coach") && (
          <Badge colorScheme="pink">Assistant Coach</Badge>
        )}
        {selectedCoach.data.tags.includes("Trainee Coach") && (
          <Badge colorScheme="pink">Trainee Coach</Badge>
        )}
        <HStack>
          {selectedCoach.data.myCoach && (
            <Badge colorScheme="green">My Coach</Badge>
          )}
        </HStack>
      </VStack>
      {selectedCoach.data.services &&
        selectedCoach.data.services.length > 0 && (
          <>
            <Heading size="md" mt={5} mb={2}>
              Services
            </Heading>
            {selectedCoach.data.services.map(
              (service: string, index: number) => {
                return <Text key={"service" + index}>{service}</Text>;
              }
            )}
          </>
        )}
      {(selectedCoach.data.phone ||
        selectedCoach.data.email ||
        selectedCoach.data.mainWebsite) && (
        <>
          <Heading size="md" mt={5} mb={2}>
            Get In Touch
          </Heading>
          <PhoneNumberDisplay phone={selectedCoach.data.phone} />
          <EmailDisplay email={selectedCoach.data.email} />
          <LinkDisplay link={selectedCoach.data.mainWebsite} />
          <LinkDisplay link={selectedCoach.data.bookingWebsite} />
        </>
      )}
      <Box mt={2}>
        {selectedCoach.data.facebook && (
          <SocialIcon
            style={{ margin: "5px 5px 5px 0" }}
            url={selectedCoach.data.facebook}
          />
        )}
        {selectedCoach.data.instagram && (
          <SocialIcon
            style={{ margin: "5px 5px 5px 0" }}
            url={selectedCoach.data.instagram}
          />
        )}
        {selectedCoach.data.twitter && (
          <SocialIcon
            style={{ margin: "5px 5px 5px 0" }}
            url={selectedCoach.data.twitter}
          />
        )}
        {selectedCoach.data.tiktok && (
          <SocialIcon
            style={{ margin: "5px 5px 5px 0" }}
            url={selectedCoach.data.tiktok}
          />
        )}
        {selectedCoach.data.linkedin && (
          <SocialIcon
            style={{ margin: "5px 5px 5px 0" }}
            url={selectedCoach.data.linkedin}
          />
        )}
      </Box>
    </Box>
  );
};

interface PhoneDisplayProps {
  phone: string | undefined;
}

const PhoneNumberDisplay: React.FC<PhoneDisplayProps> = ({ phone }) => {
  if (phone) {
    return (
      <Text>
        <PhoneIcon m={2} ml={0} />
        {phone}
      </Text>
    );
  }
  return null;
};

interface EmailDisplayProps {
  email: string | undefined;
}

const EmailDisplay: React.FC<EmailDisplayProps> = ({ email }) => {
  if (email) {
    return (
      <Text>
        <EmailIcon m={2} ml={0} />
        <Button
          variant="link"
          onClick={() => {
            window.location.href = "mailto:" + email;
          }}
        >
          {email}
        </Button>
      </Text>
    );
  }
  return null;
};

interface LinksDisplayProps {
  link: coachUrl | undefined;
}

const LinkDisplay: React.FC<LinksDisplayProps> = ({ link }) => {
  if (link && link.display && link.url) {
    let prefixedLink = link.url;
    if (
      prefixedLink.substring(0, 8) !== "https://" &&
      prefixedLink.substring(0, 7) !== "http://"
    ) {
      prefixedLink = "https://" + prefixedLink;
    }
    return (
      <Text>
        <LinkIcon m={2} ml={0} />
        <Button
          variant="link"
          onClick={() => {
            window.open(prefixedLink, "_blank", "noopener,noreferrer");
          }}
        >
          {link.display}
        </Button>
      </Text>
    );
  }
  return null;
};

export { CoachDetail };
