import { ComponentStyleConfig } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

export const Header: ComponentStyleConfig = {
  parts: ['main'],
  baseStyle: (props: any) => ({
    main: {
      backgroundColor: mode('rgba(255,255,255,0.8)', 'rgba(0,0,0,0.8)')(props),
    },
  }),
  sizes: {},
  variants: {},
  defaultProps: {},
};
