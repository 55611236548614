import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { trainingTimeline } from "../../types/trainingTimeline";

// Define the state of the slice as an object
export interface TrainingTimelineState {
  trainingTimeline: trainingTimeline | null;
  lastUpdated: Date;
}

export interface TrainingTimelineStateUpdate {
  trainingTimeline: trainingTimeline | null;
  lastUpdated: Date;
}

// Define an initial state
const initialState: TrainingTimelineState = {
  trainingTimeline: null,
  lastUpdated: new Date("1970-01-01T00:00:00.000"),
};

// Create a slice containing the configuration of the state
// and the reducers functions
const trainingTimelineSlice = createSlice({
  name: "trainingTimelineState",
  initialState,
  reducers: {
    updateTrainingTimelineState(
      state,
      action: PayloadAction<trainingTimeline | null>
    ) {
      if (action.payload) {
        state.trainingTimeline = action.payload;
        state.lastUpdated = new Date();
      } else {
        state.trainingTimeline = initialState.trainingTimeline;
        state.lastUpdated = initialState.lastUpdated;
      }
    },
  },
});

// Export each reducers function defined in createSlice
export const { updateTrainingTimelineState } = trainingTimelineSlice.actions;

export const selectTrainingTimelineState = (
  state: RootState
): TrainingTimelineState => {
  return state.trainingTimelineState;
};

// Export default the slice reducer
export default trainingTimelineSlice.reducer;
