import { InfoIcon } from "@chakra-ui/icons";
import {
  BoxProps,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Heading,
  Icon,
  SimpleGrid,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Table,
  Tabs,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
  useColorModeValue,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useAppSelector } from "../../app/hooks";
import { selectAuthState } from "../../features/auth/authSlice";
import {
  cssEntry,
  cssEntryPerformancePredictionEntry,
  cssEntryRedMistUnitEntry,
  cssEntryTenWeekChallengeWeeks,
} from "../../types/css";
import { checkRole } from "../../utils/authHelper";
import { dp } from "../../utils/coreHelper";
import { formatDurationLong, formatPace } from "../../utils/dateHelper";
import { NotAuthorised } from "../generic/NotAuthorised";
import { PaceBandsSVG } from "../svg/PaceBandsSVG";
import { CssDisplayButtons } from "./CssDisplayButtons";

interface CssDisplayProps extends BoxProps {
  cssResult: cssEntry;
  resetCalculation: any;
  mode: "VIEW" | "CREATE";
}

const CssDisplay: React.FC<CssDisplayProps> = ({
  cssResult,
  resetCalculation,
  mode,
}) => {
  const [cssUnit, setCssUnit] = useState(cssResult.pool.lapUnit);

  const { access: accessToken } = useAppSelector(selectAuthState);

  const resultColourMode = useColorModeValue(
    "ssNeonOrangeDark",
    "ssNeonOrangeLight"
  );
  const [hamburgerMode] = useMediaQuery("(max-width: 768px)");
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <VStack align="left" width="full">
      <Tabs width="full">
        {!hamburgerMode && (
          <TabList>
            <Tab>CSS Result</Tab>
            <Tab>Pacing</Tab>
            <Tab>Ten Week Challenge</Tab>
            <Tab>Performance Predictions</Tab>
            <Tab>Red Mist Paces</Tab>
            <Tab>Pace Bands</Tab>
          </TabList>
        )}
        {hamburgerMode && (
          <TabList>
            <Tab fontSize="xs">Result</Tab>
            <Tab fontSize="xs">Pacing</Tab>
            <Tab fontSize="xs">10 Weeks</Tab>
            <Tab fontSize="xs">Predictions</Tab>
            <Tab fontSize="xs">RM</Tab>
            <Tab fontSize="xs">Pace Bands</Tab>
          </TabList>
        )}

        <TabPanels width="full">
          <TabPanel width="full">
            <SimpleGrid columns={[1, 1, 2, 2]} gap={5} w="full" pt={2}>
              {cssResult.test && (
                <VStack w="full" align="flex-start">
                  <Heading as="h4" size="md" mb={2}>
                    Time Trial Results
                  </Heading>
                  <Text>
                    This is Your CSS pace based on your time trial in a{" "}
                    {cssResult.pool.lapLength}
                    {cssResult.pool.lapUnit} pool.
                  </Text>
                  <Table variant="striped" size="sm">
                    <Thead>
                      <Tr>
                        <Th>Distance</Th>
                        <Th>Time</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      <Tr>
                        <Td>400{cssResult.pool.lapUnit}</Td>
                        <Td>{formatPace(dp(cssResult.test.tt400, 2))}</Td>
                      </Tr>
                      <Tr>
                        <Td>
                          First 100{cssResult.pool.lapUnit} of 400
                          {cssResult.pool.lapUnit}
                        </Td>
                        <Td>
                          {cssResult.test.tt100
                            ? formatPace(dp(cssResult.test.tt100, 2))
                            : "-"}
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>200{cssResult.pool.lapUnit}</Td>
                        <Td>{formatPace(dp(cssResult.test.tt200, 2))}</Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </VStack>
              )}
              {cssUnit === "m" && (
                <VStack align="left">
                  <Heading as="h4" size="md" mb={2}>
                    Metres
                  </Heading>
                  <Table variant="striped" size="sm">
                    <Tbody>
                      <Tr>
                        <Td>Seconds / 100m</Td>
                        <Td isNumeric>
                          {formatPace(dp(cssResult.m.secondsPer100, 2))}
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>Seconds / 50m</Td>
                        <Td isNumeric>
                          {formatPace(dp(cssResult.m.secondsPer50, 2))}
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>Seconds / 25m</Td>
                        <Td isNumeric>
                          {formatPace(dp(cssResult.m.secondsPer25, 2))}
                        </Td>
                      </Tr>
                    </Tbody>
                  </Table>
                  <Heading as="h4" size="md" pt={5} mb={2}>
                    Normalised
                    <>
                      <Icon
                        cursor="pointer"
                        ml={2}
                        mt={-4}
                        as={InfoIcon}
                        onClick={onOpen}
                      />
                      <Drawer
                        isOpen={isOpen}
                        placement="left"
                        size="md"
                        onClose={onClose}
                      >
                        <DrawerOverlay />
                        <DrawerContent>
                          <DrawerCloseButton />
                          <DrawerHeader>
                            What is Normalised CSS Pace
                          </DrawerHeader>
                          <DrawerBody>
                            <Text>
                              Your normalised CSS pace is standardised against a
                              50m pool and allows you to compare with others who
                              swim in different length pools or even the open
                              water and endless pools.
                            </Text>
                          </DrawerBody>
                        </DrawerContent>
                      </Drawer>
                    </>
                  </Heading>
                  <Table variant="striped" size="sm">
                    <Tbody>
                      <Tr>
                        <Td>Seconds / 100m</Td>
                        <Td isNumeric>
                          {formatPace(dp(cssResult.n.secondsPer100, 2))}
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>Seconds / 50m</Td>
                        <Td isNumeric>
                          {formatPace(dp(cssResult.n.secondsPer50, 2))}
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>Seconds / 25m</Td>
                        <Td isNumeric>
                          {formatPace(dp(cssResult.n.secondsPer25, 2))}
                        </Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </VStack>
              )}
              {cssUnit === "y" && (
                <VStack align="left">
                  <Heading as="h4" size="md" mb={2}>
                    Yards
                  </Heading>
                  <Table variant="striped" size="sm">
                    <Tbody>
                      <Tr>
                        <Td>Seconds / 100y</Td>
                        <Td isNumeric>
                          {formatPace(dp(cssResult.y.secondsPer100, 2))}
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>Seconds / 50y</Td>
                        <Td isNumeric>
                          {formatPace(dp(cssResult.y.secondsPer50, 2))}
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>Seconds / 25y</Td>
                        <Td isNumeric>
                          {formatPace(dp(cssResult.y.secondsPer25, 2))}
                        </Td>
                      </Tr>
                    </Tbody>
                  </Table>
                  <Heading as="h4" size="md" pt={5} mb={2}>
                    Normalised
                    <>
                      <Icon
                        cursor="pointer"
                        ml={2}
                        mt={-4}
                        as={InfoIcon}
                        onClick={onOpen}
                      />
                      <Drawer
                        isOpen={isOpen}
                        placement="left"
                        size="md"
                        onClose={onClose}
                      >
                        <DrawerOverlay />
                        <DrawerContent>
                          <DrawerCloseButton />
                          <DrawerHeader>
                            What is Normalised CSS Pace
                          </DrawerHeader>
                          <DrawerBody>
                            <Text>
                              Your normalised CSS pace is standardised against a
                              50m pool and allows you to compare with others who
                              swim in different length pools or even the open
                              water and endless pools.
                            </Text>
                          </DrawerBody>
                        </DrawerContent>
                      </Drawer>
                    </>
                  </Heading>
                  <Table variant="striped" size="sm">
                    <Tbody>
                      <Tr>
                        <Td>Seconds / 100m</Td>
                        <Td isNumeric>
                          {formatPace(dp(cssResult.n.secondsPer100, 2))}
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>Seconds / 50m</Td>
                        <Td isNumeric>
                          {formatPace(dp(cssResult.n.secondsPer50, 2))}
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>Seconds / 25m</Td>
                        <Td isNumeric>
                          {formatPace(dp(cssResult.n.secondsPer25, 2))}
                        </Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </VStack>
              )}
            </SimpleGrid>
          </TabPanel>

          {!checkRole(accessToken, "result", "css") && (
            <TabPanel width="full">
              <NotAuthorised
                size={"full"}
                functionText={"Enhanced CSS Calculator"}
              />
            </TabPanel>
          )}

          {cssResult.test &&
            cssResult.test.pacing &&
            cssResult.test.engine &&
            checkRole(accessToken, "pacing", "css") && (
              <TabPanel width="full">
                <VStack align="left" width="100%" mb={5}>
                  <SimpleGrid columns={[1, 1, 2, 2]} gap={5} w="full">
                    <VStack align="left" pr={5}>
                      <Heading as="h4" size="md" mb={2}>
                        Petrol / Diesel
                      </Heading>
                      <Text>
                        By comparing your 400{cssResult.pool.lapUnit} and 200
                        {cssResult.pool.lapUnit} times, we can get an indication
                        of how well trained you are for distance swimming.
                      </Text>
                      <Text>
                        The drop-off between your 200{cssResult.pool.lapUnit}{" "}
                        and 400{cssResult.pool.lapUnit} speed is{" "}
                        {dp(cssResult.test.engine.calc.dropOffPercent, 1)}%
                      </Text>
                      <Text color={resultColourMode}>
                        <em>
                          Result: {cssResult.test.engine.result} engine.{" "}
                          {cssResult.test.engine.result === "Diesel"
                            ? "Well done, keep doing what you're doing"
                            : "Sprint Machine - You need some more CSS / Endurance training to get better over longer distances"}
                          .
                        </em>
                      </Text>
                    </VStack>
                    {cssResult.test.tt100 && (
                      <VStack align="left">
                        <Heading as="h4" size="md" mb={2}>
                          Pacing
                        </Heading>
                        <Text>
                          By taking your 100{cssResult.pool.lapUnit} split
                          during the 400{cssResult.pool.lapUnit} we can get a
                          good indication of your ability to pace yourself out
                          in the water.
                        </Text>
                        <Text>
                          Your speed over the first 100{cssResult.pool.lapUnit}{" "}
                          was{" "}
                          {formatPace(
                            dp(cssResult.test.pacing.calc.avgPace100, 2)
                          )}{" "}
                          and you swam{" "}
                          {formatPace(
                            dp(cssResult.test.pacing.calc.avgPace300, 2)
                          )}{" "}
                          / 100{cssResult.pool.lapUnit} for the remaining 300
                          {cssResult.pool.lapUnit}. That means you would have
                          been {dp(cssResult.test.pacing.calc.faster, 2)}{" "}
                          seconds faster if you sustained that initial speed. Or
                          put another way, you would have been{" "}
                          {dp(cssResult.test.pacing.calc.further, 2)}
                          {cssResult.pool.lapUnit} further ahead come the end of
                          the swim.
                        </Text>

                        <Text color={resultColourMode}>
                          <em>Result: {cssResult.test.pacing.result}</em>
                        </Text>
                      </VStack>
                    )}
                  </SimpleGrid>
                </VStack>
              </TabPanel>
            )}
          {!(
            cssResult.test &&
            cssResult.test.pacing &&
            cssResult.test.engine
          ) && (
            <TabPanel width="full">
              <NotAvailable />
            </TabPanel>
          )}
          {!checkRole(accessToken, "pacing", "css") && (
            <TabPanel width="full">
              <NotAuthorised
                size={"full"}
                functionText={"Enhanced CSS Calculator"}
              />
            </TabPanel>
          )}
          {cssResult.tenWeekChallenge &&
            checkRole(accessToken, "tenWeekChallenge", "css") && (
              <TabPanel width="full">
                <Heading as="h4" size="md">
                  Ten Week Challenge
                </Heading>
                <Text mb={5}>
                  Why not have a go taking on the 10 Week Challenge. The idea is
                  simple: each week the beeper gets very slightly quicker (it's
                  barely perceptible) but this adds up to a significant
                  improvement in fitness over 10 weeks. Just simply follow a
                  plan and make the CSS targets a smidgen quicker each week
                  based on these targets.
                </Text>
                <SimpleGrid columns={[1, 1, 2, 2]} gap={5} pt={2}>
                  <VStack align="left">
                    {cssUnit === "m" && (
                      <VStack align="left">
                        <Heading as="h4" size="md" pt={3}>
                          Overall CSS Change
                        </Heading>
                        <Table variant="striped" size="sm">
                          <Tbody>
                            <Tr>
                              <Td>Starting CSS</Td>
                              <Td isNumeric>
                                {formatPace(
                                  dp(
                                    cssResult.tenWeekChallenge.m.summary.start,
                                    2
                                  )
                                )}
                              </Td>
                            </Tr>
                            <Tr>
                              <Td>Finishing CSS</Td>
                              <Td isNumeric>
                                {formatPace(
                                  dp(
                                    cssResult.tenWeekChallenge.m.summary.end,
                                    2
                                  )
                                )}
                              </Td>
                            </Tr>
                          </Tbody>
                        </Table>
                        <Heading as="h4" size="md" pt={3}>
                          Predicted Improvements
                        </Heading>
                        <Table variant="striped" size="sm">
                          <Tbody>
                            <Tr>
                              <Td>1500m</Td>
                              <Td isNumeric>
                                {formatPace(
                                  dp(
                                    cssResult.tenWeekChallenge.m.summary
                                      .improvement1500,
                                    2
                                  )
                                )}
                              </Td>
                            </Tr>
                            <Tr>
                              <Td>1900m</Td>
                              <Td isNumeric>
                                {formatPace(
                                  dp(
                                    cssResult.tenWeekChallenge.m.summary
                                      .improvement1900,
                                    2
                                  )
                                )}
                              </Td>
                            </Tr>
                            <Tr>
                              <Td>3800m</Td>
                              <Td isNumeric>
                                {formatPace(
                                  dp(
                                    cssResult.tenWeekChallenge.m.summary
                                      .improvement3800,
                                    2
                                  )
                                )}
                              </Td>
                            </Tr>
                            <Tr>
                              <Td>5km</Td>
                              <Td isNumeric>
                                {formatPace(
                                  dp(
                                    cssResult.tenWeekChallenge.m.summary
                                      .improvement5000,
                                    2
                                  )
                                )}
                              </Td>
                            </Tr>
                            <Tr>
                              <Td>10km</Td>
                              <Td isNumeric>
                                {formatPace(
                                  dp(
                                    cssResult.tenWeekChallenge.m.summary
                                      .improvement10000,
                                    2
                                  )
                                )}
                              </Td>
                            </Tr>
                          </Tbody>
                        </Table>
                      </VStack>
                    )}
                    {cssUnit === "y" && (
                      <VStack align="left">
                        <Heading as="h4" size="md" pt={3}>
                          Overall CSS Change
                        </Heading>
                        <Table variant="striped" size="sm">
                          <Tbody>
                            <Tr>
                              <Td>Starting CSS</Td>
                              <Td isNumeric>
                                {formatPace(
                                  dp(
                                    cssResult.tenWeekChallenge.y.summary.start,
                                    2
                                  )
                                )}
                              </Td>
                            </Tr>
                            <Tr>
                              <Td>Finishing CSS</Td>
                              <Td isNumeric>
                                {formatPace(
                                  dp(
                                    cssResult.tenWeekChallenge.y.summary.end,
                                    2
                                  )
                                )}
                              </Td>
                            </Tr>
                          </Tbody>
                        </Table>
                        <Heading as="h4" size="md" pt={3}>
                          Predicted Improvements
                        </Heading>
                        <Table variant="striped" size="sm">
                          <Tbody>
                            <Tr>
                              <Td>1500y</Td>
                              <Td isNumeric>
                                {formatPace(
                                  dp(
                                    cssResult.tenWeekChallenge.y.summary
                                      .improvement1500,
                                    2
                                  )
                                )}
                              </Td>
                            </Tr>
                            <Tr>
                              <Td>1900y</Td>
                              <Td isNumeric>
                                {formatPace(
                                  dp(
                                    cssResult.tenWeekChallenge.y.summary
                                      .improvement1900,
                                    2
                                  )
                                )}
                              </Td>
                            </Tr>
                            <Tr>
                              <Td>3800y</Td>
                              <Td isNumeric>
                                {formatPace(
                                  dp(
                                    cssResult.tenWeekChallenge.y.summary
                                      .improvement3800,
                                    2
                                  )
                                )}
                              </Td>
                            </Tr>
                            <Tr>
                              <Td>5ky</Td>
                              <Td isNumeric>
                                {formatPace(
                                  dp(
                                    cssResult.tenWeekChallenge.y.summary
                                      .improvement5000,
                                    2
                                  )
                                )}
                              </Td>
                            </Tr>
                            <Tr>
                              <Td>10ky</Td>
                              <Td isNumeric>
                                {formatPace(
                                  dp(
                                    cssResult.tenWeekChallenge.y.summary
                                      .improvement10000,
                                    2
                                  )
                                )}
                              </Td>
                            </Tr>
                          </Tbody>
                        </Table>
                      </VStack>
                    )}
                  </VStack>
                  {hamburgerMode && cssUnit === "m" && (
                    <Table variant="striped" size="sm">
                      <Thead>
                        <Tr>
                          <Th>&nbsp;</Th>
                          <Th>Beeper</Th>
                          <Th isNumeric>/100m</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {cssResult.tenWeekChallenge.m.weeks.map(
                          (week: cssEntryTenWeekChallengeWeeks) => (
                            <Tr key={week.week}>
                              <Td>Week {week.week}</Td>
                              <Td>
                                {week.beeper.settings.setting} /{" "}
                                {week.beeper.settings.interval}
                                {cssUnit} /M
                                {week.beeper.settings.mode}
                              </Td>
                              <Td isNumeric>
                                {formatPace(dp(week.secondsPer100, 2))}
                              </Td>
                            </Tr>
                          )
                        )}
                      </Tbody>
                    </Table>
                  )}
                  {hamburgerMode && cssUnit === "y" && (
                    <Table variant="striped" size="sm">
                      <Thead>
                        <Tr>
                          <Th>&nbsp;</Th>
                          <Th>Beeper</Th>
                          <Th isNumeric>/100y</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {cssResult.tenWeekChallenge.y.weeks.map(
                          (week: cssEntryTenWeekChallengeWeeks) => (
                            <Tr key={week.week}>
                              <Td>Week {week.week}</Td>
                              <Td>
                                {week.beeper.settings.setting} /{" "}
                                {week.beeper.settings.interval}
                                {cssUnit} /M
                                {week.beeper.settings.mode}
                              </Td>
                              <Td isNumeric>
                                {formatPace(dp(week.secondsPer100, 2))}
                              </Td>
                            </Tr>
                          )
                        )}
                      </Tbody>
                    </Table>
                  )}
                  {!hamburgerMode && cssUnit === "m" && (
                    <Table variant="striped" size="sm">
                      <Thead>
                        <Tr>
                          <Th>&nbsp;</Th>
                          <Th>Beeper</Th>
                          <Th isNumeric>/100m</Th>
                          <Th isNumeric>/50m</Th>
                          <Th isNumeric>/25m</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {cssResult.tenWeekChallenge.m.weeks.map(
                          (week: cssEntryTenWeekChallengeWeeks) => (
                            <Tr key={week.week}>
                              <Td>Week {week.week}</Td>
                              <Td>
                                {week.beeper.settings.setting} /{" "}
                                {week.beeper.settings.interval}
                                {cssUnit} /M
                                {week.beeper.settings.mode}
                              </Td>
                              <Td isNumeric>
                                {formatPace(dp(week.secondsPer100, 2))}
                              </Td>
                              <Td isNumeric>
                                {formatPace(dp(week.secondsPer50, 2))}
                              </Td>
                              <Td isNumeric>
                                {formatPace(dp(week.secondsPer25, 2))}
                              </Td>
                            </Tr>
                          )
                        )}
                      </Tbody>
                    </Table>
                  )}
                  {!hamburgerMode && cssUnit === "y" && (
                    <Table variant="striped" size="sm">
                      <Thead>
                        <Tr>
                          <Th>&nbsp;</Th>
                          <Th>Beeper</Th>
                          <Th isNumeric>/100y</Th>
                          <Th isNumeric>/50y</Th>
                          <Th isNumeric>/25y</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {cssResult.tenWeekChallenge.y.weeks.map(
                          (week: cssEntryTenWeekChallengeWeeks) => (
                            <Tr key={week.week}>
                              <Td>Week {week.week}</Td>
                              <Td>
                                {week.beeper.settings.setting} /{" "}
                                {week.beeper.settings.interval}
                                {cssUnit} /M
                                {week.beeper.settings.mode}
                              </Td>
                              <Td isNumeric>
                                {formatPace(dp(week.secondsPer100, 2))}
                              </Td>
                              <Td isNumeric>
                                {formatPace(dp(week.secondsPer50, 2))}
                              </Td>
                              <Td isNumeric>
                                {formatPace(dp(week.secondsPer25, 2))}
                              </Td>
                            </Tr>
                          )
                        )}
                      </Tbody>
                    </Table>
                  )}
                </SimpleGrid>
              </TabPanel>
            )}
          {!cssResult.tenWeekChallenge && (
            <TabPanel width="full">
              <NotAvailable />
            </TabPanel>
          )}
          {!checkRole(accessToken, "tenWeekChallenge", "css") && (
            <TabPanel width="full">
              <NotAuthorised
                size={"full"}
                functionText={"Enhanced CSS Calculator"}
              />
            </TabPanel>
          )}
          {cssResult.performancePredictions &&
            checkRole(accessToken, "performancePrediction", "css") && (
              <TabPanel width="full">
                <Heading as="h4" size="md">
                  Performance Predictions
                </Heading>
                <Text>
                  From your CSS pace we predict the following performances over
                  different distances.
                </Text>
                <Text mb={5}>
                  CSS equates to the pace that theorectically you should be able
                  to swim a 1500{cssUnit} time trial in a rested state. For
                  distances greater than 1500{cssUnit}, we expect to see a decay
                  in the pace that you can sustain, as noted in the pace per 100
                  {cssUnit} drop-off. Equally, for distance less than 1500
                  {cssUnit}, you should be able to swim these faster than CSS
                  pace.
                </Text>
                {hamburgerMode && cssUnit === "m" && (
                  <Table variant="striped" size="sm">
                    <Thead>
                      <Tr>
                        <Th>Distance</Th>
                        <Th isNumeric>Prediction</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {cssResult.performancePredictions.m.map(
                        (
                          pp: cssEntryPerformancePredictionEntry,
                          ppCount: number
                        ) => (
                          <Tr key={ppCount}>
                            <Td>{pp.title}</Td>
                            <Td isNumeric>
                              {formatDurationLong(dp(pp.prediction, 2))}
                            </Td>
                          </Tr>
                        )
                      )}
                    </Tbody>
                  </Table>
                )}
                {hamburgerMode && cssUnit === "y" && (
                  <Table variant="striped" size="sm">
                    <Thead>
                      <Tr>
                        <Th>Distance</Th>
                        <Th isNumeric>Prediction</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {cssResult.performancePredictions.y.map(
                        (
                          pp: cssEntryPerformancePredictionEntry,
                          ppCount: number
                        ) => (
                          <Tr key={ppCount}>
                            <Td>{pp.title}</Td>
                            <Td isNumeric>
                              {formatDurationLong(dp(pp.prediction, 2))}
                            </Td>
                          </Tr>
                        )
                      )}
                    </Tbody>
                  </Table>
                )}
                {!hamburgerMode && cssUnit === "m" && (
                  <Table variant="striped" size="sm">
                    <Thead>
                      <Tr>
                        <Th>Distance</Th>
                        <Th>Beeper</Th>
                        <Th isNumeric>Prediction</Th>
                        <Th isNumeric>/100m</Th>
                        <Th isNumeric>/50m</Th>
                        <Th isNumeric>/25m</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {cssResult.performancePredictions.m.map(
                        (
                          pp: cssEntryPerformancePredictionEntry,
                          ppCount: number
                        ) => (
                          <Tr key={ppCount}>
                            <Td>{pp.title}</Td>
                            <Td>
                              {pp.beeper.settings.setting} /{" "}
                              {pp.beeper.settings.interval}
                              {cssUnit} /M
                              {pp.beeper.settings.mode}
                            </Td>
                            <Td isNumeric>
                              {formatDurationLong(dp(pp.prediction, 2))}
                            </Td>
                            <Td isNumeric>
                              {formatPace(dp(pp.secondsPer100, 2))}
                            </Td>
                            <Td isNumeric>
                              {formatPace(dp(pp.secondsPer50, 2))}
                            </Td>
                            <Td isNumeric>
                              {formatPace(dp(pp.secondsPer25, 2))}
                            </Td>
                          </Tr>
                        )
                      )}
                    </Tbody>
                  </Table>
                )}
                {!hamburgerMode && cssUnit === "y" && (
                  <Table variant="striped" size="sm">
                    <Thead>
                      <Tr>
                        <Th>Distance</Th>
                        <Th>Beeper</Th>
                        <Th isNumeric>Prediction</Th>
                        <Th isNumeric>/100y</Th>
                        <Th isNumeric>/50y</Th>
                        <Th isNumeric>/25y</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {cssResult.performancePredictions.y.map(
                        (
                          pp: cssEntryPerformancePredictionEntry,
                          ppCount: number
                        ) => (
                          <Tr key={ppCount}>
                            <Td>{pp.title}</Td>
                            <Td>
                              {pp.beeper.settings.setting} /{" "}
                              {pp.beeper.settings.interval}
                              {cssUnit} /M
                              {pp.beeper.settings.mode}
                            </Td>
                            <Td isNumeric>
                              {formatDurationLong(dp(pp.prediction, 2))}
                            </Td>
                            <Td isNumeric>
                              {formatPace(dp(pp.secondsPer100, 2))}
                            </Td>
                            <Td isNumeric>
                              {formatPace(dp(pp.secondsPer50, 2))}
                            </Td>
                            <Td isNumeric>
                              {formatPace(dp(pp.secondsPer25, 2))}
                            </Td>
                          </Tr>
                        )
                      )}
                    </Tbody>
                  </Table>
                )}
              </TabPanel>
            )}
          {!cssResult.performancePredictions && (
            <TabPanel width="full">
              <NotAvailable />
            </TabPanel>
          )}
          {!checkRole(accessToken, "performancePrediction", "css") && (
            <TabPanel width="full">
              <NotAuthorised
                size={"full"}
                functionText={"Enhanced CSS Calculator"}
              />
            </TabPanel>
          )}
          {cssResult.redMistPaces &&
            checkRole(accessToken, "rmPaces", "css") && (
              <TabPanel width="full">
                <Heading as="h4" size="md">
                  Red Mist Cycle Pace
                </Heading>
                <Text>
                  For RM cycles, you have to beat the beeper to get your rest in
                  much the same way as you’d beat the old pace clock’s cycle
                  time.
                </Text>
                <Text>
                  The lower the RM value, the less rest you will get for each
                  interval. Half of your 100{cssUnit} CSS pace rounded up to the
                  nearest second is RM0 (very hard to beat obviously!). RM5
                  would imply that if you were swimming at CSS pace, you’d gain
                  5 seconds rest for every 50{cssUnit} you complete. Over 200
                  {cssUnit}, this would give you 20 seconds rest for example.
                </Text>
                <Text mb={5}>
                  By working with RM cycles relative to your CSS pace we can
                  ensure the consistency of scalability for a session across a
                  wide range of ability levels.
                </Text>
                {cssUnit === "m" && (
                  <Table variant="striped" size="sm">
                    <Thead>
                      <Tr>
                        <Th>&nbsp;</Th>
                        <Th isNumeric>Beeper</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {cssResult.redMistPaces.m.map(
                        (rm: cssEntryRedMistUnitEntry) => (
                          <Tr key={rm.rm}>
                            <Td>RM Cycle {rm.rm}</Td>
                            <Td isNumeric>
                              {rm.beeper.settings.setting} /{" "}
                              {rm.beeper.settings.interval}
                              {cssUnit} /M
                              {rm.beeper.settings.mode}
                            </Td>
                          </Tr>
                        )
                      )}
                    </Tbody>
                  </Table>
                )}
                {cssUnit === "y" && (
                  <Table variant="striped" size="sm">
                    <Thead>
                      <Tr>
                        <Th>&nbsp;</Th>
                        <Th isNumeric>Beeper</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {cssResult.redMistPaces.y.map(
                        (rm: cssEntryRedMistUnitEntry) => (
                          <Tr key={rm.rm}>
                            <Td>RM Cycle {rm.rm}</Td>
                            <Td isNumeric>
                              {rm.beeper.settings.setting} /{" "}
                              {rm.beeper.settings.interval}
                              {cssUnit} /M
                              {rm.beeper.settings.mode}
                            </Td>
                          </Tr>
                        )
                      )}
                    </Tbody>
                  </Table>
                )}
              </TabPanel>
            )}
          {!cssResult.redMistPaces && (
            <TabPanel width="full">
              <NotAvailable />
            </TabPanel>
          )}
          {!checkRole(accessToken, "rmPaces", "css") && (
            <TabPanel width="full">
              <NotAuthorised
                size={"full"}
                functionText={"Enhanced CSS Calculator"}
              />
            </TabPanel>
          )}
          {cssResult.paceBands &&
            checkRole(accessToken, "paceBands", "css") && (
              <TabPanel width="full">
                <VStack align="left" pt={2}>
                  <Heading as="h4" size="md" mb={2}>
                    Pace Bands
                  </Heading>
                  <Text>
                    Pace bands give you an idea of how fast you should be
                    swimming when asked to swim Easy, Steady, Red Mist, CSS,
                    VO2Max or Sprint. Pace bands are also used to calculate a
                    more accurate sTSS for each of your activities.
                  </Text>
                  <PaceBandsSVG unit={cssUnit} bands={cssResult.paceBands} />
                </VStack>
              </TabPanel>
            )}
          {!cssResult.paceBands && (
            <TabPanel width="full">
              <NotAvailable />
            </TabPanel>
          )}
          {!checkRole(accessToken, "paceBands", "css") && (
            <TabPanel width="full">
              <NotAuthorised
                size={"full"}
                functionText={"Enhanced CSS Calculator"}
              />
            </TabPanel>
          )}
        </TabPanels>
      </Tabs>
      <CssDisplayButtons
        cssResult={cssResult}
        resetCalculation={resetCalculation}
        cssUnit={cssUnit}
        setCssUnit={setCssUnit}
        mode={mode}
      />
    </VStack>
  );
};

const NotAvailable = () => {
  return (
    <VStack width="100%" my={100} align="center">
      <Heading>Not Available</Heading>
      <Text>This data is not available for the inputs given.</Text>
    </VStack>
  );
};

export { CssDisplay };
