import {
  Box,
  Button,
  Heading,
  HStack,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useAppSelector } from "../../app/hooks";
import { getCssCurrent } from "../../DataAccess/css";
import { selectAuthState } from "../../features/auth/authSlice";
import { selectUserState } from "../../features/user/userSlice";
import { cssEntry } from "../../types/css";
import { checkRole } from "../../utils/authHelper";
import {
  convertCSS,
  getPaceBands,
  getPerformancePredictions,
  getRedMistPaces,
  getTenWeekChallenge,
} from "../../utils/cssHelper";
import { splitSeconds } from "../../utils/dateHelper";
import { createToast } from "../../utils/toastHelper";
import { NotAuthorised } from "../generic/NotAuthorised";
import { CreateSupportTicket } from "../ui/CreateSupportTicket";
import { CustomToast } from "../ui/CustomToast";
import Loading from "../ui/Loading";
import { CssDisplay } from "./CssDisplay";

const wrPacePer100 = 44.84;

const defaultCSSState: cssEntry = {
  _id: "",
  calculated: false,
  defaulted: false,
  date: "",
  type: "test",
  test: {
    tt400: 0,
    tt100: 0,
    tt200: 0,
    engine: {
      result: "",
      calc: {
        dropOff: 0,
        dropOffPercent: 0,
      },
    },
    pacing: {
      result: "",
      calc: {
        dropOff: 0,
        faster: 0,
        further: 0,
        avgPace100: 0,
        avgPace300: 0,
      },
    },
  },
  m: {
    secondsPer100: 0,
    secondsPer50: 0,
    secondsPer25: 0,
    secondsPerUnit: 0,
    unitsPerSecond: 0,
  },
  y: {
    secondsPer100: 0,
    secondsPer50: 0,
    secondsPer25: 0,
    secondsPerUnit: 0,
    unitsPerSecond: 0,
  },
  n: {
    secondsPer100: 0,
    secondsPer50: 0,
    secondsPer25: 0,
    secondsPerUnit: 0,
    unitsPerSecond: 0,
  },
  pool: {
    lapLength: 50,
    lapUnit: "m",
  },
};

const CssManualCapture = (props: any) => {
  const [cssResult, setCSSResult] = useState(defaultCSSState);

  const { access: accessToken } = useAppSelector(selectAuthState);
  const { user } = useAppSelector(selectUserState);
  const [cssEntry, setCssEntry] = useState<cssEntry | null>();
  const toast = useToast();

  useEffect(() => {
    const getCssCurrentLocal = async () => {
      setCssEntry(await getCssCurrent());
    };
    getCssCurrentLocal();
  }, []);

  const {
    control,
    setValue,
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      lapLength: 50,
      lapUnit: "m",
      valueMin: 0,
      valueSec: 0,
      valueMil: 0,
    },
  });

  useEffect(() => {
    if (user) {
      setValue("lapLength", user.data.basic.defaultPool.lapLength);
      setValue("lapUnit", user.data.basic.defaultPool.lapUnit);
      if (cssEntry) {
        if (user.data.basic.defaultPool.lapUnit === "y") {
          const {
            min: min400,
            sec: sec400,
            mil: mil400,
          } = splitSeconds(cssEntry.y.secondsPer100);
          setValue("valueMin", min400);
          setValue("valueSec", sec400);
          setValue("valueMil", mil400);
        } else {
          const {
            min: min400,
            sec: sec400,
            mil: mil400,
          } = splitSeconds(cssEntry.m.secondsPer100);
          setValue("valueMin", min400);
          setValue("valueSec", sec400);
          setValue("valueMil", mil400);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const calculateCSS = (data: any): void => {
    toast.closeAll();
    const value =
      parseInt(data.valueMin, 10) * 60 +
      parseInt(data.valueSec, 10) +
      parseInt(data.valueMil, 10) / 100;
    const lapLength = parseInt(data.lapLength, 10);
    const lapUnit = data.lapUnit;
    const errors: string[] = [];

    if (value < wrPacePer100) {
      errors.push(
        "Your CSS pace is faster than world record pace ... its unlikley this is correct!"
      );
    }

    if (errors.length > 0) {
      const errorChildren = errors.map((error, count) => {
        return React.createElement(
          "li",
          { key: "error" + count, id: "error" + count },
          error
        );
      });
      const reactElementErrors = React.createElement("ul", {}, errorChildren);
      createToast(toast, (props: any) => {
        return (
          <CustomToast
            title={"Hmmm, something doesn't look right there!"}
            status={"Error"}
            toast={toast}
            toastId={props.id}
          >
            {reactElementErrors}
            <CreateSupportTicket />
          </CustomToast>
        );
      });
    } else {
      // All good, let's calculate
      const css = value;
      const cssFull = convertCSS(css, lapLength, lapUnit);

      setCSSResult({
        _id: "",
        calculated: true,
        defaulted: false,
        date: "",
        type: "manual",
        pool: {
          lapLength: lapLength,
          lapUnit: lapUnit,
        },
        m: cssFull.m,
        y: cssFull.y,
        n: cssFull.n,
        performancePredictions: getPerformancePredictions(cssFull),
        tenWeekChallenge: getTenWeekChallenge(cssFull),
        redMistPaces: getRedMistPaces(cssFull),
        paceBands: getPaceBands(cssFull),
      });
    }
  };

  const resetCalculation = (): void => {
    toast.closeAll();
    setCSSResult(defaultCSSState);
  };

  const resetForm = (): void => {
    toast.closeAll();
    reset();
  };

  if (!user) {
    return <Loading message="Error Loading User" />;
  }

  if (!checkRole(accessToken, "manual", "css")) {
    return <NotAuthorised functionText={"Manual CSS Entry"} size={"full"} />;
  }

  if (!cssResult.calculated) {
    return (
      <VStack
        as="form"
        onSubmit={handleSubmit(calculateCSS)}
        p={0}
        spacing={3}
        align="left"
        width="full"
      >
        <VStack p={0} spacing={0} align="left">
          <Heading as="h3" size="md" pt={5}>
            Pool Details
          </Heading>
          <Text>
            Make sure you tell us the correct length and unit for your pool so
            we can make the right calculations.
          </Text>
          <VStack p={0} spacing={0}>
            <HStack w="full" px={0} py={2} spacing={3} alignItems="flex-start">
              <Controller
                control={control}
                name="lapLength"
                rules={{ required: true }}
                render={({ field: { ref, ...restField } }) => (
                  <Select {...restField}>
                    <option value="50">50</option>
                    <option value="33">33</option>
                    <option value="25">25</option>
                    <option value="20">20</option>
                  </Select>
                )}
              />
              <Controller
                control={control}
                name="lapUnit"
                rules={{ required: true }}
                render={({ field: { ref, ...restField } }) => (
                  <Select {...restField}>
                    <option value="m">Metres</option>
                    <option value="y">Yards</option>
                  </Select>
                )}
              />
            </HStack>
          </VStack>
          <Heading as="h3" size="md" pt={5}>
            Your CSS Pace
          </Heading>
          <Text>
            The CSS pace you have been given or have calculated. Please be as
            accurate as possible.
          </Text>
          <VStack p={0} spacing={0}>
            <HStack w="full" px={0} py={2} spacing={3} alignItems="flex-start">
              <Controller
                control={control}
                name="valueMin"
                rules={{ required: true }}
                render={({ field: { ref, ...restField } }) => (
                  <VStack w="full" alignItems={"flex-start"}>
                    <NumberInput {...restField} min={0} max={20} width="100%">
                      <NumberInputField />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                    <Text mt={-2} fontSize={"smaller"} color={"gray.500"}>
                      Minutes
                    </Text>
                  </VStack>
                )}
              />
              <Controller
                control={control}
                name="valueSec"
                rules={{ required: true }}
                render={({ field: { ref, ...restField } }) => (
                  <VStack w="full" alignItems={"flex-start"}>
                    <NumberInput {...restField} min={0} max={59} width="100%">
                      <NumberInputField />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                    <Text mt={-2} fontSize={"smaller"} color={"gray.500"}>
                      Seconds
                    </Text>
                  </VStack>
                )}
              />
              <Controller
                control={control}
                name="valueMil"
                rules={{ required: true }}
                render={({ field: { ref, ...restField } }) => (
                  <VStack w="full" alignItems={"flex-start"}>
                    <NumberInput {...restField} min={0} max={99} width="100%">
                      <NumberInputField />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                    <Text mt={-2} fontSize={"smaller"} color={"gray.500"}>
                      Milliseconds
                    </Text>
                  </VStack>
                )}
              />
            </HStack>
          </VStack>
        </VStack>
        <HStack width="full" align="flex-start">
          <Button variant="warning" px={10} onClick={resetForm}>
            Reset
          </Button>
          <Box width="full">&nbsp;</Box>
          <Button
            colorScheme={props.colorScheme}
            px={10}
            isLoading={isSubmitting}
            type="submit"
          >
            Calculate CSS &gt;&gt;
          </Button>
        </HStack>
      </VStack>
    );
  }

  return (
    <CssDisplay
      mode={"CREATE"}
      cssResult={cssResult}
      resetCalculation={resetCalculation}
    />
  );
};

export { CssManualCapture };
