interface swimSmoothApiError {
  success: boolean;
  count: number;
  messages: swimSmoothApiErrorMessage[];
}

interface swimSmoothApiErrorMessage {
  severity: string;
  message: swimSmoothApiErrorMessage;
}
interface swimSmoothApiErrorMessage {
  user: string;
  developer: string;
  field: string;
  identifier: string;
}

const processSwimSmoothApiError = (error: swimSmoothApiError): string => {
  let errorString = "Something went wrong";
  if (error.messages) {
    errorString = error.messages.reduce((previous: string, message: swimSmoothApiErrorMessage) => {
      if (message.message.user) {
        return (previous += message.message.user + "\n");
      }
      return previous;
    }, "");
  }
  return errorString ? errorString : "An error has occurred, please contact support.";
};

export { processSwimSmoothApiError };
