import { InfoIcon } from "@chakra-ui/icons";
import {
  Box,
  BoxProps,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Heading,
  Icon,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { useAppSelector } from "../../app/hooks";
import { selectAuthState } from "../../features/auth/authSlice";
import { activity, activityAnalysis } from "../../types/activity";
import { checkRole } from "../../utils/authHelper";
import { NotAuthorised } from "../generic/NotAuthorised";
import { BetaBadge } from "../ui/BetaBadge";
import Loading from "../ui/Loading";
import { SwimTypesGraph } from "./SwimTypesGraph";

interface SwimTypesProps extends BoxProps {
  loading: boolean;
  activity: activity;
  activityAnalysis: activityAnalysis | null;
}

const SwimTypes: React.FC<SwimTypesProps> = ({
  loading,
  activity,
  activityAnalysis,
}) => {
  const { access: accessToken } = useAppSelector(selectAuthState);
  const boxColorMode = useColorModeValue(
    "ssBoxBackgroundLight",
    "ssBoxBackgroundDark"
  );
  const { isOpen, onOpen, onClose } = useDisclosure();

  if (!checkRole(accessToken, "swimTypes", "activity")) {
    return (
      <Box bg={boxColorMode} p={3} borderRadius={10} height="100%">
        <Heading as="h4" size="sm" mb={5}>
          Swim Type Analysis
          <BetaBadge />
        </Heading>
        <NotAuthorised functionText={"Swim Type Analysis"} size={"small"} />
      </Box>
    );
  }

  if (loading) {
    return (
      <Box bg={boxColorMode} p={3} borderRadius={10} height="100%">
        <Heading as="h4" size="sm" mb={5}>
          Swim Type Analysis
          <BetaBadge />
        </Heading>
        <Loading message="" />
      </Box>
    );
  }

  if (!activityAnalysis) {
    return (
      <Box bg={boxColorMode} p={3} borderRadius={10} height="100%">
        <Heading as="h4" size="sm" mb={5}>
          Swim Type Analysis
          <BetaBadge />
        </Heading>
        <Text>Unable to load swim type analysis for this activity.</Text>
      </Box>
    );
  }

  const data = activityAnalysis.swimtelligence.swimTypes.sort(
    (a, b) => b.value - a.value
  );

  return (
    <Box bg={boxColorMode} p={3} borderRadius={10} height="100%">
      <Heading as="h4" size="sm" mb={5}>
        Swim Type Analysis
        <>
          <Icon
            cursor="pointer"
            ml={2}
            mt={-4}
            as={InfoIcon}
            onClick={onOpen}
          />
          <Drawer isOpen={isOpen} placement="left" size="md" onClose={onClose}>
            <DrawerOverlay />
            <DrawerContent>
              <DrawerCloseButton />
              <DrawerHeader>About Swim Types</DrawerHeader>
              <DrawerBody>
                <Text>
                  Swim Types is our way of recognising both individuality and
                  systemic clustering in the way people swim. It is likely that
                  you will display traits of several of our Swim Types to
                  varying degrees, such is the spectrum-like nature of the
                  paradigm. Follow the advice of the most likely Swim Type and
                  if in doubt, have one of our excellent coaches work with you
                  to determine a clear pathway forwards.
                </Text>
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        </>
        <BetaBadge />
      </Heading>
      {activity.source.provider !== "SWIMSMOOTH" && (
        <Text>
          Stroke Insights are only available on activities swim using the Swim
          Smooth app on Apple Watch.
        </Text>
      )}
      {activity.source.provider === "SWIMSMOOTH" && (
        <SwimTypesGraph data={data} />
      )}
    </Box>
  );
};
export { SwimTypes };
